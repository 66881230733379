/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { shape, string } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    header: shape({
      value: string,
    }),
    subtitle: shape({
      value: string,
    }),
  }),
};

/**
 * A simple Page title component.  It is meant to
 * be used as the first component on a page since it
 * utilized an H1 tag
 * @param {Object} props
 * @returns {HTMLElement} Page Title
 */
const PageTitle = (props) => {
  try {
    const { header, subtitle } = props.fields;
    return (
      <section className="page-title">
        <div className="page-title__inner grid--100">
          <Text className="page-title__title" field={header} tag="h1" />
          <Text className="page-title__subtitle" field={subtitle} tag="p" />
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

PageTitle.propTypes = propTypes;

export default PageTitle;
