// inclusive breakpoints
export const bpXLargeMin = 1721;
export const bpLargeMin = 1150;
export const bpMediumMin = 850;
export const bpSmallMin = 610;

// check breakpoints
export const isXLargeBreakpoint = (elWidth) => elWidth >= bpXLargeMin;
export const isLargeBreakpoint = (elWidth) => elWidth >= bpLargeMin;
export const isMediumBreakpoint = (elWidth) => elWidth >= bpMediumMin;
export const isSmallBreakpoint = (elWidth) => elWidth >= bpSmallMin;

// viewports in range
export const isXLargeRange = isXLargeBreakpoint;
export const isLargeRange = (elWidth) =>
  isLargeBreakpoint(elWidth) && elWidth < bpXLargeMin;
export const isMediumRange = (elWidth) =>
  isMediumBreakpoint(elWidth) && elWidth < bpLargeMin;
export const isSmallRange = (elWidth) =>
  isSmallBreakpoint(elWidth) && elWidth < bpMediumMin;
export const isMobileRange = (elWidth) => elWidth < bpSmallMin;
