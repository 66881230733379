/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import { Text, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { array, object, shape, string } from "prop-types";
import CardCarousel from "../CardCarousel/CardCarousel";
import FAQCard from "../FAQCard/FAQCard";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import { useTranslation } from "react-i18next";

const propTypes = {
  rendering: object,
  fields: shape({
    title: shape({
      value: string,
    }),
    link: shape({
      value: object,
    }),
    cards: array,
  }),
};

const FAQCardCarousel = (props) => {
  const { t } = useTranslation(),
    [faqTitle] = useState(t("FAQCarouselTitle"));
  try {
    const { title, link, cards } = props.fields;

    /**
     * Scrolls the whole item in view when it has focus
     * @param {Event} event triggering event
     */
    const handleItemFocus = (event) => {
      if (event && event.target) {
        event.target.scrollIntoView({ block: "nearest", inline: "center" });
      }
    };

    const renderFAQCards = () => {
      const linkValue = getFieldValue(props.fields, "link");
      return cards.map((card, index) => {
        const href = linkValue?.href;
        const basePath = href === "/" ? "" : `${href}/`;
        const cardUrl = card?.id ? `${basePath}#FAQ-${card?.id}` : href;
        return (
          <a
            href={cardUrl}
            key={index}
            onFocus={handleItemFocus}
            className="faq-carousel__item"
          >
            <FAQCard {...card} hasDivider={true} hasLimit={true} />
          </a>
        );
      });
    };

    return (
      <section className="faq-carousel">
        <div className="faq-carousel__header grid--100">
          <Text className="faq-carousel__title" field={title} tag="h2" />
          <div className="faq-carousel__cta">
            <Link className="btn btn--tertiary" field={link} />
          </div>
        </div>
        <div className="faq-carousel__carousel">
          <CardCarousel
            params={{ isDark: "false" }}
            cards={renderFAQCards()}
            fields={{ title: { value: faqTitle } }}
          />
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FAQCardCarousel.propTypes = propTypes;

export default FAQCardCarousel;
