/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect, useId } from "react";
/* eslint-enable */
import {
  withSitecoreContext,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import FocusLock from "react-focus-lock";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import qs from "qs";

const propTypes = {
  sitecoreContext: object,
  rendering: object,
};

/**
 * A modal that contains an Acceptiva iframe.  Acceptiva
 * is used to collect and submit payment information.
 * @param {Object} props
 * @returns {HTMLElement} Donate Payment Modal - US
 */
const DonateUSPaymentModal = (props) => {
  const id = useId(),
    { t } = useTranslation(),
    [addedNoScroll, setAddedNoScroll] = useState(false),
    [displayThanks, setDisplayThanks] = useState(false);

  useEffect(() => {
    try {
      /**
       * Handles iframe events
       * @param {Event} e
       * @returns {VoidFunction}
       */
      const handleFrameTasks = (e) => {
        if (e.origin !== process.env.REACT_APP_ACC_DOMAIN) {
          return;
        }

        const data = typeof e.data === "object" ? e.data : JSON.parse(e.data);

        if (typeof data.response !== "undefined") {
          // console.log("error");
          // console.log(JSON.parse(data.response));
          return;
        }

        if (data.status === "success") {
          setDisplayThanks(true);
        }
      };

      if (props?.isOpen) {
        document.querySelector("body").classList.add("no-scroll");
        setAddedNoScroll(true);
      } else if (addedNoScroll) {
        document.querySelector("body").classList.remove("no-scroll");
        setAddedNoScroll(false);
      }

      window.addEventListener("message", handleFrameTasks);
      return () => {
        window.removeEventListener("message", handleFrameTasks);
      };
    } catch (error) {
      console.error(error);
    }
    // adding addedNoScroll to [] would cause infinite loop
    // eslint-disable-next-line
  },[]);

  try {
    const { sitecoreContext, isOpen, onClose, data, rendering } = props,
      isEditing = sitecoreContext?.pageEditing,
      { headerLogo } = sitecoreContext?.globalConfiguration,
      cssClasses = classNames("donate-modal", {
        "is-open": isOpen,
        "grid--100": isEditing,
        "is-thanks": displayThanks,
      });

    /**
     * Closes the banner and emits a close event to parent
     * @param {Event} event click event
     */
    const closeModal = (event) => {
      event.preventDefault();
      if (!isEditing) {
        if (displayThanks) {
          window.location.href = "/";
        } else {
          onClose();
        }
      }
    };

    // Taken from Pilot
    const acceptivaCssUrl =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_ACC_CSS_URL
        : `${window.location.origin}/${process.env.REACT_APP_ACC_CSS_URL}`;

    const qParams = {
      api_key: process.env.REACT_APP_ACC_API_KEY,
      css_url: acceptivaCssUrl,
      currency: 840,
      payment_type: 1,
      payment_types: 1,
      show_address: true,
      show_cvv: true,
      merch_acct_id_str: { 1: `${process.env.REACT_APP_ACC_MERCH_ID}` },
      receipt_id_str: process.env.REACT_APP_ACC_RECEIPT_ID,
      recur_receipt_id_str: process.env.REACT_APP_ACC_RECURR_RECEIPT_ID,
      recur_failed_receipt_id_str: process.env.REACT_APP_ACC_FAIL_RECEIPT_ID,
    };

    const isRecurring = data?.frequency === "monthly";
    const recurFreq = isRecurring ? 3 : 0;
    const recurEndCriteria = isRecurring ? 0 : null;
    // const recurEndValue = isRecurring ? data?.recur_length : 0;

    const amountSelected = data?.amount_selected;
    const amountFees = amountSelected * 0.03;

    // const dataPayerState = data?.payer_state_required ? data?.payer_state : "";
    const dataPayerState = data?.payer_state;

    let gppFundID = process.env.REACT_APP_FUND_IMPACT_ID;
    let gppFundStr = "Must-Solve Problems";

    switch (data?.gpp_fund) {
      case "empowerment":
        gppFundID = process.env.REACT_APP_FUND_EMPOWERMENT_ID;
        gppFundStr = "Empowerment & Opportunity";
        break;
      case "covid19":
        gppFundID = process.env.REACT_APP_FUND_COVID19_ID;
        gppFundStr = "Combating COVID-19";
        break;
      case "frontiers":
        gppFundID = process.env.REACT_APP_FUND_FRONTIERS_ID;
        gppFundStr = "Frontiers: HIV & Sickle Cell Disease";
        break;
    }

    const transactionItems = [
      {
        id: "gpp-donation",
        desc: "gpp donation",
        amt: amountSelected,
        recur_freq: recurFreq,
        recur_end_criteria: recurEndCriteria,
        // recur_end_value: recurEndValue,
      },
    ];

    if (data?.include_processing_fee) {
      transactionItems.push({
        id: "gpp-donation-fees",
        desc: "gpp donation cover transaction fees",
        amt: amountFees,
        recur_freq: recurFreq,
        recur_end_criteria: recurEndCriteria,
        // recur_end_value: recurEndValue,
      });
    }

    // return query params object
    const transactionProps = {
      ...qParams,
      payer_fname: data?.payer_fname,
      payer_lname: data?.payer_lname,
      payer_email: data?.payer_email,
      payer_address: data?.payer_address,
      payer_zip: data?.payer_zip,
      payer_country: data?.payer_country,
      payer_state: dataPayerState,
      payer_city: data?.payer_city,
      acct_name: `${data?.payer_fname || ""} ${data?.payer_lname || ""}`.trim(),
      items: transactionItems,
      extra_params: {
        DONATION_TYPE: recurFreq,
        DONATION_TYPE_STR: isRecurring ? "Monthly" : "One Time",
        FUND_ALLOCATION_STR: gppFundStr,
        gpp_fund: gppFundID,
        gpp_updates_email: data?.gpp_updates_email,
        gpp_updates_mail: data?.gpp_updates_mail,
        payer_address: data?.payer_address,
        payer_zip: data?.payer_zip,
        payer_country: data?.payer_country,
        payer_state: dataPayerState,
        payer_city: data?.payer_city,
      },
    };

    /**
     * Renders the Acceptiva Iframe
     * @returns {HTMLElement} Acceptiva Iframe
     */
    const renderIframe = () => {
      const queryParams = qs.stringify(transactionProps, {
        encodeValuesOnly: true,
      });
      const acceptivaSrc = `${process.env.REACT_APP_ACC_DOMAIN}/lightbox/?${queryParams}`;

      return (
        <iframe
          title={t("USPaymentTitle")}
          id="accpro-pop-iframe"
          src={acceptivaSrc}
          scrolling="auto"
        />
      );
    };

    return (
      <dialog
        id={id}
        aria-modal="true"
        className={cssClasses}
        aria-labelledby={`${id}_TITLE`}
      >
        <FocusLock disabled={isEditing} className="donate-modal__inner">
          <div className="donate-modal__header">
            <div className="donate-modal__header-inner grid--100">
              <HeaderLogo fields={headerLogo} isDark={false} />
              <button
                onClick={closeModal}
                className="donate-modal__close btn btn--primary btn--icon"
              >
                <svg
                  className="icon"
                  width="18"
                  height="19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{t("Close")}</title>
                  <rect
                    width="2"
                    height="23.4"
                    rx="1"
                    transform="rotate(-45 2 1)"
                  />
                  <rect
                    width="2"
                    height="23.5"
                    rx="1"
                    transform="rotate(45 8 20)"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="donate-modal__copy">
            {!displayThanks && (
              <React.Fragment>
                <div className="grid--100">
                  <h1 className="donate-modal__title-group" id={`${id}_TITLE`}>
                    <span className="donate-modal__step">
                      {t("FinalStep")}
                      <span className="sr-only">: </span>
                    </span>
                    <span className="donate-modal__title">
                      {t("USPaymentTitle")}
                    </span>
                  </h1>
                </div>
                {renderIframe()}
              </React.Fragment>
            )}
            {(displayThanks || isEditing) && (
              <Placeholder name="jss-donate-thank-you" rendering={rendering} />
            )}
          </div>
        </FocusLock>
      </dialog>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateUSPaymentModal.propTypes = propTypes;

export default withSitecoreContext()(DonateUSPaymentModal);
