/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  rendering: object,
};

const SplitContainer5050 = (props) => {
  try {
    return (
      <div className="grid grid--50-50">
        <div className="grid--50">
          <Placeholder
            name="jss-split-50-left-container"
            rendering={props.rendering}
          />
        </div>
        <div className="grid--50">
          <Placeholder
            name="jss-split-50-right-container"
            rendering={props.rendering}
          />
        </div>
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

SplitContainer5050.propTypes = propTypes;

export default SplitContainer5050;
