/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import { object } from "prop-types";

/* eslint-disable */
// TODO: Change IFRAME_SRC to env variable. Couldn't get it to work for some reason
/* eslint-enable */
// const IFRAME_SRC = process.env.REACT_APP_ACC_DONATE_URL;
const IFRAME_SRC = "https://secure.acceptiva.com/?cst=iioJZU&pay_only=yes";

const propTypes = {
  sitecoreContext: object,
  rendering: object,
};

const DonateFormUS = (props) => {
  return (
    <section className="donate-form-us">
      <iframe
        style={{ height: "100vh", width: "100%" }}
        title="global-impact"
        id="global-impact-iframe"
        src={IFRAME_SRC}
      />
    </section>
  );
};

DonateFormUS.propTypes = propTypes;

export default DonateFormUS;
