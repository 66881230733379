/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Image,
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import DividerLine from "../DividerLine/DividerLine.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    header: shape({
      value: string,
    }),
    graphTitle: shape({
      value: string,
    }),
    graphDescription: shape({
      value: string,
    }),
    desktopImage: shape({
      value: object,
    }),
    tabletImage: shape({
      value: object,
    }),
    smallImage: shape({
      value: object,
    }),
    mobileImage: shape({
      value: object,
    }),
    dataPoint1: shape({
      value: string,
    }),
    dataPoint2: shape({
      value: string,
    }),
    dataPoint3: shape({
      value: string,
    }),
    dataPoint4: shape({
      value: string,
    }),
    dataPoint5: shape({
      value: string,
    }),
    dataPoint6: shape({
      value: string,
    }),
    dataPoint7: shape({
      value: string,
    }),
    dataPoint8: shape({
      value: string,
    }),
    dataPoint9: shape({
      value: string,
    }),
    dataPoint10: shape({
      value: string,
    }),
  }),
};

const DataViz = (props) => {
  if (!props.fields) {
    return null;
  }

  try {
    const {
        header,
        graphTitle,
        graphDescription,
        desktopImage,
        tabletImage,
        mobileImage,
        smallImage,
      } = props.fields,
      tabletImageValue = getFieldValue(props.fields, "tabletImage"),
      smallImageValue = getFieldValue(props.fields, "smallImage"),
      mobileImageValue = getFieldValue(props.fields, "mobileImage"),
      isEditing = props?.sitecoreContext && props?.sitecoreContext?.pageEditing;

    const renderDataPoint = (dataPointField, key = "") => {
      if (dataPointField?.value) {
        const dataKey = key
          ? key
          : `dataVizPoint_${Math.floor(Math.random() * 100000)}`;
        return (
          <li className="data-viz__item" key={dataKey}>
            <Text className="data-viz__point" field={dataPointField} tag="p" />
            <DividerLine
              isAriaHidden={true}
              noPadding={true}
              fields={{ isDark: { value: false } }}
            />
          </li>
        );
      }
    };

    /**
     * Render datapoint fields 1-10.  If the data point field is empty,
     * the field will not be rendered
     * @returns {Array.<HTMLElement>} datapoint Array
     */
    const renderItems = () => {
      let numberList = [];
      for (let i = 1; i <= 10; i++) {
        if (getFieldValue(props?.fields, `dataPoint${i}`)) {
          numberList.push(renderDataPoint(props?.fields?.[`dataPoint${i}`]));
        }
      }
      return numberList;
    };

    return (
      <section className="data-viz">
        <div className="grid grid--100">
          <Text className="data-viz__header" field={header} tag="h2" />
        </div>
        <figure className="data-viz__graph">
          {(graphTitle?.value || graphDescription?.value) && (
            <div className="data-viz__title-group grid grid--100">
              <Text className="data-viz__title" field={graphTitle} tag="h3" />
              <Text
                className="data-viz__description"
                field={graphDescription}
                tag="p"
              />
            </div>
          )}
          <picture className="data-viz__image grid--100">
            {mobileImageValue?.src && (
              <source
                srcSet={mobileImageValue?.src}
                media="(max-width: 609px)"
              />
            )}
            {smallImageValue?.src && (
              <source
                srcSet={smallImageValue?.src}
                media="(max-width: 849px)"
              />
            )}
            {tabletImageValue?.src && (
              <source
                srcSet={tabletImageValue?.src}
                media="(max-width: 1149px)"
              />
            )}
            <Image className="img" field={desktopImage} />
            {isEditing && <Image className="img" field={tabletImage} />}
            {isEditing && <Image className="img" field={smallImage} />}
            {isEditing && <Image className="img" field={mobileImage} />}
          </picture>
          <figcaption className="data-viz__data-section grid--100">
            <ol className="data-viz__data-list" type="A">
              {renderItems()}
            </ol>
          </figcaption>
        </figure>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DataViz.propTypes = propTypes;

export default withSitecoreContext()(DataViz);
