/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import SocialShare from "../SocialShare/SocialShare";
import GlobalFooter from "../GlobalFooter/GlobalFooter";
import { useTranslation } from "react-i18next";

const propTypes = {
  sitecoreContext: object,
};

/**
 * A modal that contains an Acceptiva iframe.  Acceptiva
 * is used to collect and submit payment information.
 * @param {Object} props
 * @returns {HTMLElement} Donate Payment Modal - US
 */
const DonateThankYouModal = (props) => {
  const { t } = useTranslation();

  function dangerousT(text) {
    return { __html: t(text) };
  }
  try {
    return (
      <section className="donate-thanks">
        <div className="donate-thanks__header">
          <div className="grid grid--25-50-25">
            <div className="grid--25"></div>
            <h1 className="donate-thanks__title grid--50">
              {t("DonateThanksTitle")}
            </h1>
            <div className="grid--25"></div>
          </div>
        </div>
        <div className="grid grid--25-50-25">
          <div className="grid--25"></div>
          <div className="grid--50">
            <section className="donate-thanks__intro">
              <h2 className="donate-thanks__subtitle">
                {t("DonateThanksSubtitle")}
              </h2>
              <div
                className="donate-thanks__copy"
                dangerouslySetInnerHTML={dangerousT("DonateThanksBody")}
              />
            </section>
            <section className="donate-thanks__footer">
              <SocialShare />
            </section>
          </div>
          <div className="grid--25"></div>
        </div>
        <div className="donate-thanks__bottom">
          <GlobalFooter isSimple={true} />
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateThankYouModal.propTypes = propTypes;

export default withSitecoreContext()(DonateThankYouModal);
