/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { shape, string, object } from "prop-types";
import SectionHeadline from "../SectionHeadline/SectionHeadline";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),

    downloadLink: shape({
      value: object,
    }),
    icon: shape({
      value: object,
    }),
  }),
};

const ImpactReportHeadline = (props) => {
  const { title, description, downloadLink, icon } = props.fields;
  return (
    <section className="impact-report-headline">
      <SectionHeadline fields={{ title, description, downloadLink, icon }} />
    </section>
  );
};

ImpactReportHeadline.propTypes = propTypes;

export default ImpactReportHeadline;
