/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { array, shape } from "prop-types";
import ArticleCard from "../SharedComponents/ArticleCardShared/ArticleCardShared.js";
import DividerLine from "../DividerLine/DividerLine.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames/bind";

const propTypes = {
  fields: shape({
    children: array,
  }),
};

const ArticleCardListing = (props) => {
  try {
    const { children } = props.fields;

    /**
     * Renders an article card item with divider
     * @returns {HTMLElement} Article Cards
     */
    const renderCard = (page, key = "") => {
      const isPlaceholder = !page;
      const cssCard = classNames("article-listing__card", {
        "is-empty": isPlaceholder,
      });
      const cardKey = key
        ? key
        : `ArticleListingItem_${Math.floor(Math.random() * 100000)}`;
      return (
        <div className={cssCard} key={`${cardKey}`} aria-hidden={isPlaceholder}>
          <DividerLine
            noPadding={true}
            isAriaHidden={true}
            fields={{ isDark: { value: false } }}
          />
          {!isPlaceholder && <ArticleCard {...page} />}
        </div>
      );
    };

    /**
     * Renders an array of article cards.  Adds empty placeholders
     * for when the length is not a multiple of 3
     * @returns {HTMLElement} Article Cards
     */
    const renderCardsList = () => {
      const cardArray = children?.map((page, index) => {
        return renderCard(page);
      });

      if (cardArray?.length % 3 > 0) {
        const placeholderCount = 3 - (cardArray?.length % 3);
        for (let i = 0; i < placeholderCount; i++) {
          cardArray?.push(renderCard());
        }
      }
      return cardArray;
    };

    return (
      <section className="article-listing">
        <div className="article-listing__inner grid--100">
          {renderCardsList()}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticleCardListing.propTypes = propTypes;

export default ArticleCardListing;
