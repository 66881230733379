/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { shape, object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    text: object,
  }),
};

const RichTextSection = (props) => {
  try {
    const { text } = props.fields;
    return (
      <section className="rich-text grid--100">
        <RichText className="rich-text__inner grid--small" field={text} />
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

RichTextSection.propTypes = propTypes;

export default RichTextSection;
