/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import { object, shape } from "prop-types";

const propTypes = {
  fields: shape({
    header: object,
    image1: object,
    image2: object,
    image1Title: object,
    image2Title: object,
  }),
};

const TwoUpImage = (props) => {
  const { header, image1, image2, image1Title, image2Title } = props.fields;
  return (
    <section className="two-up-image">
      <div className="grid">
        <Text tag="h2" field={header} className="two-up-image__header" />
      </div>
      <div className="grid">
        <div className="two-up-image__left grid--50">
          <Image className="two-up-image__image" field={image1} />
          <Text field={image1Title} tag="p" className="two-up-image__caption" />
        </div>
        <div className="two-up-image__right grid--50">
          <Image className="two-up-image__image" field={image2} />
          <Text field={image2Title} tag="p" className="two-up-image__caption" />
        </div>
      </div>
    </section>
  );
};

TwoUpImage.propTypes = propTypes;

export default TwoUpImage;
