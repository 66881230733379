/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  getFieldValue,
  Text,
  RichText,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import classNames from "classnames/bind";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    quote: shape({
      value: string,
    }),
    caption: shape({
      value: string,
    }),
  }),
};

const PullQuoteBanner = (props) => {
  const { sitecoreContext: { route: route } = {}, fields } = props;
  const secondaryColorField = route.fields.colorTheme?.fields.secondary?.fields,
    isEditing = route && route.pageEditing,
    isDarkBackground = getFieldValue(secondaryColorField, "lightText"),
    cssBackground = getFieldValue(secondaryColorField, "color"),
    cssDarkBackground = classNames({ "is-dark": isDarkBackground });

  return (
    <section className={`pull-quote ${cssDarkBackground}`}>
      <div
        className="pull-quote__inner"
        style={{ backgroundColor: cssBackground }}
      >
        {isEditing && <p>PullQuoteBanner Component</p>}
        <div className="grid grid--25-50-25 pull-quote__copy-section">
          <figure className="grid--50 pull-quote__copy">
            <RichText
              className="pull-quote__quote"
              field={fields.quote}
              tag="blockquote"
            />
            {fields.caption && (
              <figcaption className="pull-quote__footer">
                <Text
                  className="pull-quote__caption"
                  field={fields.caption}
                  tag="cite"
                />
              </figcaption>
            )}
          </figure>
        </div>
      </div>
    </section>
  );
};

PullQuoteBanner.propTypes = propTypes;

export default withSitecoreContext()(PullQuoteBanner);
