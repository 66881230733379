/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { shape, string, object } from "prop-types";
import SectionHeadline from "../SectionHeadline/SectionHeadline";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),

    downloadLink: shape({
      value: object,
    }),
    icon: shape({
      value: object,
    }),
  }),
  rendering: object,
};

const GranteeSpotlight = (props) => {
  const {
    fields: { title, description, downloadLink, icon },
    rendering,
  } = props;
  return (
    <section className="grantee-spotlight is-dark">
      <SectionHeadline fields={{ title, description, downloadLink, icon }} />
      <Placeholder name="jss-grantee-spotlight-item" rendering={rendering} />
    </section>
  );
};

GranteeSpotlight.propTypes = propTypes;

export default GranteeSpotlight;
