/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Image,
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    image: shape({
      value: object,
    }),
    caption: shape({
      value: string,
    }),
  }),
};

const ArticleImage = (props) => {
  try {
    const { image, caption } = props.fields,
      altText =
        getFieldValue(props?.fields, "image")?.alt ||
        getFieldValue(props?.fields, "caption") ||
        "",
      isUsingCaption = altText === getFieldValue(props?.fields, "caption"),
      isEditing = props?.sitecoreContext?.pageEditing;

    return (
      <figure className="article-image grid--100">
        <picture className="article-image__image grid--small">
          <Image className="img" field={image} alt={altText} />
        </picture>
        {(caption?.value || isEditing) && (
          <figcaption
            className="article-image__caption grid--small"
            aria-hidden={isUsingCaption}
          >
            <Text className="article-image__copy" field={caption} tag="p" />
          </figcaption>
        )}
      </figure>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticleImage.propTypes = propTypes;

export default withSitecoreContext()(ArticleImage);
