/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import {
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";

const propTypes = {
  sitecoreContext: object,
};

/**
 * The Social Share component should be placed on sharable
 * pages.  It reads from page level metadata information.
 * @param {Object} props
 * @returns {HTMLElement} Social Share
 */
const SocialShare = (props) => {
  const { t } = useTranslation(),
    [shareLabel] = useState(t("Share")),
    [newWindowLabel] = useState(t("NewWindow")),
    [facebookLabel] = useState(t("Facebook")),
    [twitterLabel] = useState(t("Twitter")),
    [linkedinLabel] = useState(t("Linkedin"));

  const [shareURL, setShareURL] = useState("");

  const { sitecoreContext } = props;

  const title = encodeURIComponent(
    getFieldValue(sitecoreContext?.route?.fields, "title"),
  );

  const socialShareText = sitecoreContext?.route?.fields.socialShareText?.value;
  const socialShareURL =
    sitecoreContext?.route?.fields.socialShareURL?.value?.href;

  const summary = encodeURIComponent(
    socialShareText && socialShareText !== ""
      ? socialShareText
      : getFieldValue(sitecoreContext?.route?.fields, "summary"),
  );
  const cssButton = classNames("btn btn--primary social-share__btn btn--dark");

  useEffect(() => {
    // sometimes socialShareURL will return href = "", because Sitecore
    setShareURL(
      socialShareURL && socialShareURL !== ""
        ? socialShareURL
        : encodeURIComponent(window.location.href),
    );
  }, [socialShareURL]);

  try {
    /**
     * Renders the Linkedin share link
     * @returns {HTMLElement} LinkedIn share link
     */
    const renderLinkedin = () => {
      return (
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}&title=${title}&summary=${summary}`}
          target="_blank"
          rel="noreferrer"
          className={cssButton}
        >
          <svg
            className="icon"
            role="img"
            aria-label={`${linkedinLabel} (${newWindowLabel})`}
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.56 11.23a2.14 2.14 0 0 1 1.93-1.08c.38 0 .75.03 1.11.16.74.28 1.1.85 1.27 1.59.1.48.13.97.13 1.47v3.48c0 .12-.03.14-.14.14h-1.8c-.1 0-.12-.02-.12-.12v-3.34c0-.25-.03-.49-.08-.74-.12-.53-.41-.79-.96-.82-.63-.04-1.06.27-1.21.87-.06.23-.08.46-.08.7v3.3c0 .12-.03.15-.15.15h-1.8c-.1 0-.12-.03-.12-.12v-6.42c0-.11.03-.14.13-.14h1.72c.1 0 .13.04.13.14v.76l.04.02ZM9.23 13.65v3.2c0 .12-.03.15-.13.15H7.28c-.1 0-.13-.02-.13-.12v-6.43c0-.1.04-.12.13-.12H9.1c.12 0 .13.04.13.14v3.18ZM8.2 9.4A1.2 1.2 0 0 1 7 8.2C7 7.56 7.53 7 8.19 7c.67 0 1.21.53 1.21 1.19a1.22 1.22 0 0 1-1.2 1.2Z"
              fill="#212121"
            />
            <path
              d="M3.75 4.5c0-.41.34-.75.75-.75h15c.41 0 .75.34.75.75v15c0 .41-.34.75-.75.75h-15a.75.75 0 0 1-.75-.75v-15Z"
              stroke="#1E1E1E"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      );
    };

    /**
     * Renders the Twitter share link
     * @returns {HTMLElement} Twitter share link
     */
    const renderTwitter = () => {
      return (
        <a
          href={`https://twitter.com/intent/tweet?text=${summary}&url=${shareURL}`}
          target="_blank"
          rel="noreferrer"
          className={cssButton}
        >
          <svg
            width="16" // Width/height smaller here than other social icons because other icons have extra empty space
            height="16"
            viewBox="0 0 1200 1227"
            role="img"
            aria-label={`${twitterLabel} (${newWindowLabel})`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
              fill="#1E1E1E"
            />
          </svg>
        </a>
      );
    };

    /**
     * Renders the Facebook share link
     * @returns {HTMLElement} Facebook share link
     */
    const renderFacebook = () => {
      return (
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}&t=${title}&quote=${summary}`}
          target="_blank"
          rel="noreferrer"
          className={cssButton}
        >
          <svg
            className="icon"
            role="img"
            aria-label={`${facebookLabel} (${newWindowLabel})`}
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 14v6.5c0 .28.22.5.5.5h1a.5.5 0 0 0 .5-.5V14h1.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H13v-1c0-.76.32-1 1-1h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1c-1.55 0-2.5.96-2.5 2.5v1.45H9.5a.5.5 0 0 0-.5.5v1.05c0 .28.22.5.5.5H11Z"
              fill="#1E1E1E"
            />
            <path
              d="M3.75 4.5c0-.41.34-.75.75-.75h15c.41 0 .75.34.75.75v15c0 .41-.34.75-.75.75h-15a.75.75 0 0 1-.75-.75v-15Z"
              stroke="#1E1E1E"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      );
    };

    return (
      <section className="social-share">
        {shareLabel && <h2 className="social-share__title">{shareLabel}</h2>}
        <div className="social-share__share-btns">
          {renderFacebook()}
          {renderLinkedin()}
          {renderTwitter()}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

SocialShare.propTypes = propTypes;

export default withSitecoreContext()(SocialShare);
