/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";

const propTypes = {
  rendering: object,
};

const FullWidthContainer = (props) => {
  return (
    <section className="grid--100">
      <Placeholder
        name="jss-full-width-container"
        rendering={props.rendering}
      />
    </section>
  );
};

FullWidthContainer.propTypes = propTypes;

export default FullWidthContainer;
