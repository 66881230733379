/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useId } from "react";
import { shape, string } from "prop-types";
/* eslint-enable */
import {
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { setFocus } from "../../utils/helpers";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    placeholder: shape({
      value: string,
    }),
    privacyMessage: shape({
      value: string,
    }),
    successURL: shape({
      value: string,
    }),
    successMessage: shape({
      value: string,
    }),
  }),
};

const NewsletterSignup = (props) => {
  const { title, privacyMessage, successMessage } = props.fields;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState("");
  const id = useId();
  const emailInputEl = useRef(null);

  /**
   * Handles when an error has been thrown
   * @param {Boolean|String} value the value to set
   */
  const handleError = (value) => {
    setHasError(value);
    if (value) {
      setFocus(emailInputEl?.current);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSuccess) {
      return;
    }
    setIsSubmitting(true);

    // This is a relatively simplistic regex test for email addresses
    // It's not foolproof; the email spec is a monster
    // But this will prevent most obviously invalid strings from passing
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      handleError(true);
      setIsSubmitting(false);
    } else {
      // GET request to Pardot form here
      fetch(
        "https://go.hello.gatesphilanthropypartners.org/l/844003/2021-09-24/4s5wrb",
        {
          method: "POST",
          mode: "no-cors", // don't need a response beyond yay/nay
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: new URLSearchParams({
            email: email,
          }),
        },
      )
        .then((response) => response)
        .then((data) => {
          handleError(false);
          setIsSuccess(true);
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          handleError("Error when submitting email, please try again.");
          setIsSubmitting(false);
        });
    }
  };

  const handleOnChange = (event) => {
    handleError(false);
    setEmail(event.target.value);
  };

  return (
    <section
      className={`newsletter-signup grid--50 ${
        hasError ? "newsletter-signup--error" : ""
      }`}
    >
      <Text
        field={title}
        tag="div"
        id={`${id}_TITLE`}
        className="newsletter-signup__title"
      />
      <form
        className="newsletter-signup__form"
        aria-labelledby={`${id}_TITLE`}
        action={getFieldValue(props?.fields, "successURL") || "/"}
        onSubmit={handleSubmit}
      >
        <label className="sr-only" htmlFor="844003_2228pi_844003_2228">
          Email
        </label>
        <input
          className="combo-input__input"
          type="text"
          name="844003_2228pi_844003_2228"
          id="844003_2228pi_844003_2228"
          ref={emailInputEl}
          aria-invalid={hasError}
          aria-describedby={`${id}_PRIVACY ${hasError ? id + "_ERROR" : ""}`}
          placeholder={
            getFieldValue(props?.fields, "placeholder") || "email@address.com"
          }
          onChange={handleOnChange}
        />
        <button
          className="combo-input__button"
          type="submit"
          aria-disabled={isSuccess === true}
        >
          {!isSubmitting && !isSuccess && "Sign Up"}
          {isSubmitting === true && (
            <span className="combo-input__loading-spinner">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Submitting email. Please wait."
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="8"
                  stroke="#5B5B5B"
                  strokeWidth="2"
                />
                <path d="M4 12a8 8 0 018-8" stroke="#fff" strokeWidth="2" />
              </svg>
            </span>
          )}
          {isSuccess && (
            <span className="combo-input__success-icon">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                aria-labelledby={`${id}_SUCCESS`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5.5 14L18.5 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          )}
        </button>
        {hasError !== false && (
          <div className="newsletter-signup__error" id={`${id}_ERROR`}>
            {hasError.length ? hasError : "Not a valid email address"}
          </div>
        )}
        {!hasError && !isSuccess && (
          <RichText
            field={privacyMessage}
            tag="div"
            id={`${id}_PRIVACY`}
            className="newsletter-signup__privacy-message"
          />
        )}
        {isSuccess && (
          <RichText
            field={successMessage}
            tag="div"
            role="alert"
            id={`${id}_SUCCESS`}
            className="newsletter-signup__success-message"
          />
        )}
      </form>
    </section>
  );
};

NewsletterSignup.propTypes = propTypes;

export default NewsletterSignup;
