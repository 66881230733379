import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);

export const debounce = (fn, ms) => {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      // eslint-disable-next-line
      fn.apply(this, arguments);
    }, ms);
  };
};

export const attachResizeListeners = (callback, delay = 500) => {
  const debouncedHandleResize = debounce(callback, delay);
  window.addEventListener("resize", debouncedHandleResize);
  return (_) => {
    window.removeEventListener("resize", debouncedHandleResize);
  };
};

/**
 * Sets the focus on the given element (if it can receive focus)
 * after a given delay. Useful for ensuring that screen readers
 * have enough processing time to register and annouce a focus change.
 * @param {HTMLElement} elToFocus element to put focus on
 * @param {Number} delay delay time in milliseconds
 */
export const setFocus = (elToFocus, delay = 50) => {
  if (elToFocus) {
    try {
      setTimeout(() => {
        elToFocus.focus();
      }, delay);
    } catch (error) {
      return;
    }
  }
};

/**
 * Determines if given element is within the scrollable area of the
 * given parent.  The part of the element that needs to be in bounds
 * can be specified as well, but defaults to all
 * @param {HTMLElement} elToCheck element to check
 * @param {HTMLElement} parentViewport scrollable parent viewport
 * @param {String} bounds one of all | top | left | center
 * @returns {Boolean} if the element is in view
 */
export const isInParentViewport = (
  elToCheck,
  parentViewport,
  bounds = "all",
) => {
  if (elToCheck && parentViewport) {
    const el = elToCheck.getBoundingClientRect(),
      parentEl = parentViewport.getBoundingClientRect(),
      hasTopInView = el.top >= parentEl.top && el.top <= parentEl.bottom,
      hasLeftInView = el.left >= parentEl.left && el.left <= parentEl.right,
      hasBottomInView =
        el.bottom >= parentEl.bottom && el.bottom <= parentEl.top,
      hasRightInView = el.right >= parentEl.right && el.right <= parentEl.left,
      hasXCenterInView =
        el.left + el.width / 2 >= parentEl.left &&
        el.left + el.width / 2 <= parentEl.right,
      hasYCenterInView =
        el.top + el.height / 2 >= parentEl.top &&
        el.top + el.height / 2 <= parentEl.bottom;

    switch (bounds) {
      case "top":
        return hasTopInView;
      case "left":
        return hasLeftInView;
      case "center":
        return hasXCenterInView && hasYCenterInView;
      case "all":
      default:
        return (
          hasTopInView && hasBottomInView && hasLeftInView && hasRightInView
        );
    }
  }
  return false;
};

/**
 * Determines if given element is within the scrollable area of the
 * viewport.  The part of the element that needs to be in bounds
 * can be specified as well, but defaults to all
 * @param {HTMLElement} elToCheck element to check
 * @param {String} bounds one of all | top | height | left | center
 * @returns {Boolean} if the element is in view
 */
export const isInViewport = (elToCheck, bounds = "all") => {
  if (elToCheck && typeof window !== "undefined") {
    const el = elToCheck.getBoundingClientRect(),
      hasTopInView = el.top >= 0 && el.top <= window.innerHeight,
      hasLeftInView = el.left >= 0 && el.left <= window.innerWidth,
      hasBottomInView = el.bottom >= 0 && el.bottom <= window.innerHeight,
      hasRightInView = el.right >= 0 && el.right <= window.innerWidth,
      hasXCenterInView =
        el.left + el.width / 2 >= 0 &&
        el.left + el.width / 2 <= window.innerWidth,
      hasYCenterInView =
        el.top + el.height / 2 >= 0 &&
        el.top + el.height / 2 <= window.innerHeight;

    switch (bounds) {
      case "top":
        return hasTopInView;
      case "height":
        return hasTopInView || hasBottomInView;
      case "left":
        return hasLeftInView;
      case "center":
        return hasXCenterInView && hasYCenterInView;
      case "all":
      default:
        return (
          hasTopInView && hasBottomInView && hasLeftInView && hasRightInView
        );
    }
  }
  return false;
};

/**
 * Determines if user has a preference for reduced motion
 * (i.e. prefers no animations play)
 * @returns {Boolean} if motion should be reduced
 */
export const prefersReducedMotion = () => {
  if (typeof window !== "undefined") {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    return mediaQuery?.matches;
  }
  return true;
};

/**
 * Truncates the given text
 * @param {String} text text to truncate
 * @param {Number} maxCharCount max allowed characters; default 250
 * @param {String} endAppendage text to append to the end of the truncation; default "..."
 * @return {String} truncated text
 */
export const truncateText = (
  text,
  maxCharCount = 250,
  endAppendage = "...",
) => {
  if (text && text.length + endAppendage.length > maxCharCount) {
    const endIndex = maxCharCount - endAppendage.length - 1;
    const nearestSpace = text.lastIndexOf(" ", endIndex);
    return `${text.substring(0, nearestSpace)}${endAppendage}`;
  }
  return text;
};

/**
 * Scrolls to the given element, taking the global header
 * height into account
 * @param {HTMLElement} el element to scroll to
 * @returns {VoidFunction}
 */
export const scrollToElement = (el, setFocus = true) => {
  if (el && document && window) {
    try {
      const elRect = el.getBoundingClientRect();
      let scrollPosition = elRect.top + window.scrollY;
      if (elRect.y < 0) {
        // scroll up - Header displays
        const globalHeader = document.querySelector(".global-header");
        const globalHeaderHeight = globalHeader.getBoundingClientRect().height;
        scrollPosition = scrollPosition - globalHeaderHeight;
      }

      window.scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: "smooth",
      });

      if (setFocus) {
        setTimeout(() => {
          el.focus({
            preventScroll: true.valueOf,
          });
        }, 50);
      }
    } catch (error) {
      return;
    }
  }
};

const sitecoreNullDate = dayjs("1000-12-31");

/**
 * Determines if the given date is a valid date, or
 * is beyond the Sitecore null date
 * @param {string} dateString date to check
 * @returns {boolean} if date is valid
 */
const isValidDate = (dateString) => {
  const date = dayjs(dateString);
  return date.isValid() && date.isAfter(sitecoreNullDate);
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

/**
 * Gets the date field, or returns null if none
 * of the dates are valid
 * @param {object} - displayDate Sitecore field
 * @param {object} - originalDate Sitecore field
 * @returns {{ dateField: object, isUpdated: boolean }}
 */
export const getDateField = (displayDateField, originalDateField) => {
  const displayDateString = displayDateField?.value;
  const originalDateString = originalDateField?.value;

  if (isValidDate(displayDateString)) {
    return {
      dateField: displayDateField,
      dateString: displayDateString,
      isUpdated: true,
    };
  }

  if (isValidDate(originalDateString)) {
    return {
      dateField: originalDateField,
      dateString: originalDateString,
      isUpdated: false,
    };
  }

  return { dateField: null, dateString: null, isUpdated: false };
};

/**
 * If date string has time and time zone in it, remove it. Otherwise, return the date as is
 * @param {string} date
 * @returns {string}
 */
const removeTimeFromDateString = (date) => {
  if (date.includes("T")) {
    return date.split("T")[0];
  }

  return date;
};

/**
 *
 * @param {string | null} dateString
 * @returns {string}
 */
export const formatDate = (dateString) => {
  if (typeof dateString !== "string") {
    console.warn("formatDate received non-string argument: ", dateString);
    return "";
  }

  const dateWithoutTimezone = dateString.includes("T")
    ? removeTimeFromDateString(dateString)
    : dateString;
  const dayJsDate = dayjs(dateWithoutTimezone);
  const monthNum = dayJsDate.month();
  const monthName = dayjs.months()[monthNum];
  const day = dayJsDate.date();
  const daySuffix = getDaySuffix(day);
  const year = dayJsDate.year();

  return `${day}${daySuffix} ${monthName}, ${year}`;
};
