/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useId } from "react";
/* eslint-enable */
import Carousel from "../SharedComponents/Carousel/Carousel.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import ArticleImage from "../ArticleImage/ArticleImage";
import { FieldEditFrame } from "../../lib/FieldEditFrameWrapper.js";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

/**
 * The Article Image Carousel is a wrapper of the shared
 * Carousel component.  It passes the children to the carousel
 * and shares all the same functionality, with some slight
 * design differences.  It is meant to be placed within a
 * Split 60 Container, as it is only meant for the article page.
 * @param {Object} props
 * @returns {HTMLElement} Article Image Carousel/Slideshow
 */
const ArticleImageCarousel = (props) => {
  const id = useId();
  try {
    const { title } = props.fields.items[0].fields;
    const children = props.fields.items.slice(1);
    const isEditing =
      props.sitecoreContext && props.sitecoreContext.pageEditing;

    /**
     * Creates a list of Article Image elements
     * @returns {Array<HTMLElement>} Article Image Array
     */
    const createImagesArray = () => {
      return children.map((item, index) => (
        <ArticleImage key={`${id}_${index}`} {...item} />
      ));
    };

    const createImagesArrayEE = () => {
      return children.map((item, index) => (
        <FieldEditFrame
          key={index}
          itemId={item.id}
          showChildCommands={true}
          children={<ArticleImage key={`${id}_${index}`} {...item} />}
        />
      ));
    };

    if (isEditing) {
      return (
        <section className="article-image-carousel">
          {createImagesArrayEE()}
        </section>
      );
    }
    return (
      <section className="article-image-carousel">
        <Carousel items={createImagesArray()} title={title?.value} />
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

export default withSitecoreContext()(ArticleImageCarousel);
