/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useId } from "react";
/* eslint-enable */
import { Text, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, bool } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import IconButton from "../IconButton/IconButton.js";
import DividerLine from "../DividerLine/DividerLine";
import { useTranslation } from "react-i18next";

import { prefersReducedMotion } from "../../utils/helpers.js";

const propTypes = {
  fields: shape({
    title: object,
    subtitle: object,
    link: object,
    image: object,
    video: object,
    isDark: bool,
    opacity: object,
  }),
};

const HomepageHero = (props) => {
  const [isPlaying, setIsPlaying] = useState(!prefersReducedMotion());
  const [canUseMotion] = useState(!prefersReducedMotion());
  const id = useId();
  const videoEl = useRef(null),
    { t } = useTranslation(),
    [playLabel] = useState(t("Play")),
    [pauseLabel] = useState(t("Pause")),
    [videoLabel] = useState(t("Video")),
    [featuredLabel] = useState(t("Featured"));

  try {
    const { title, subtitle, link, image, opacity } = props.fields;

    const videoField = getFieldValue(props.fields, "video");
    const srcUrl = videoField?.src;
    const altText =
      getFieldValue(props.fields, "image")?.alt ||
      getFieldValue(props.fields, "caption") ||
      "";

    /**
     * Pauses or plays the video element based on
     * its current state
     * @param {HTMLMediaElement} videoEl video element
     */
    const controlVideo = (videoEl) => {
      if (videoEl) {
        videoEl.paused ? videoEl.play() : videoEl.pause();
      }
    };

    return (
      <section className="homepage-hero">
        <div className="homepage-hero__image-block">
          {image && !videoField && (
            <Image className="homepage-hero__backdrop" field={image} />
          )}
          {videoField && (
            <>
              <video
                className="homepage-hero__video"
                muted
                id={`${id}_VIDEO`}
                aria-hidden="true"
                aria-label={videoField?.title || featuredLabel}
                ref={videoEl}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                controlsList="nodownload nofullscreen noremoteplayback"
                disablePictureInPicture
                autoPlay={canUseMotion ? true : null}
                loop
              >
                <source src={srcUrl} type="video/mp4" />
                <Image className="img" field={image} alt={altText} />
              </video>
              <button
                aria-live="assertive"
                id={`${id}_VIDEO_CONTROL_BTN`}
                aria-controls={`${id}_VIDEO`}
                className="feature-circle__control-btn tab-only"
                onClick={() => controlVideo(videoEl?.current)}
              >
                {isPlaying ? (
                  <svg className="icon" viewBox="0 0 32 32">
                    <title>
                      {pauseLabel} {videoField?.title || featuredLabel}{" "}
                      {videoLabel}
                    </title>
                    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM10 10h4v12h-4zM18 10h4v12h-4z"></path>
                  </svg>
                ) : (
                  <svg className="icon" viewBox="0 0 32 32">
                    <title>
                      {playLabel} {videoField?.title || featuredLabel}{" "}
                      {videoLabel}
                    </title>
                    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z"></path>
                  </svg>
                )}
              </button>
            </>
          )}
          <div
            className="homepage-hero__overlay"
            style={{
              opacity: getFieldValue(opacity, "opacity"),
            }}
          ></div>
        </div>

        <div className="homepage-hero__content-block">
          <div className="homepage-hero__content grid grid--100">
            <Text className="homepage-hero__title" field={title} tag="h1" />
            <Text
              className="homepage-hero__subtitle"
              field={subtitle}
              tag="span"
            />
          </div>
          {link && (
            <div className="homepage-hero__cta">
              <DividerLine isAriaHidden={true} fields={{ isDark: true }} />
              <a href={link.value.href} className="grid--100">
                {
                  // Given the reusability requirements of the IconButton,
                  // no simple way to switch styling based on breakpoint.
                  // Using two different instances, will show/hide as required
                  // by viewport width in CSS
                }
                <IconButton />
                <IconButton isDark={true} />
                <span className="homepage-hero__cta-label">
                  {link.value.text}
                </span>
              </a>
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

HomepageHero.propTypes = propTypes;

export default HomepageHero;
