/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect, useId } from "react";
/* eslint-enable */
import { shape, bool, array, string } from "prop-types";
import { attachResizeListeners, debounce } from "../../../utils/helpers.js";
import classNames from "classnames";
import SkipLink from "../SkipLink/SkipLink.js";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar.js";

const propTypes = {
  fields: shape({
    isDark: shape({
      value: bool,
    }),
  }),
  items: array,
  title: string,
};

debounce();

/**
 * The Carousel component is generic and meant to be used in more
 * specific sections/components. "items" should be passed via props,
 * and they will be rendered as the items a user will be able to
 * rotate through.  The carousel component inherits the styles of
 * its wrapper for theming.
 * @param {Object} props
 * @returns {HTMLElement} Carousel component
 */
const Carousel = (props) => {
  const { items, title } = props,
    [activeSlide, setActiveSlide] = useState(0),
    id = useId(),
    thisEl = useRef(null),
    slideshowEl = useRef(null),
    slideEls = useRef([]);

  /**
   * Pads the given number/string with zeros
   * @param {Number|String} numToPad number to pad
   * @param {Number} zeros how many zeros to add; defaults to 2
   * @returns {String} string with padded zeros
   */
  const padWithZeros = (numToPad, zeros = 2) => {
    return `${numToPad}`.padStart(zeros, "0");
  };

  /**
   * Scrolls to the soon-to-be active slide
   * @param {HTMLElement} slideEl soon-to-be active slide element
   */
  const scrollToSlide = (slideEl) => {
    const offset =
      typeof window === "undefined"
        ? 0
        : window.getComputedStyle(slideshowEl?.current).paddingLeft;
    slideshowEl.current.scrollTo({
      top: 0,
      left: slideEl.offsetLeft - parseInt(offset),
      behavior: "smooth",
    });
  };

  /**
   * Gets the slide of the given index
   * @param {Number} slideIndex index of slide to get
   * @returns {HTMLElement} slide element
   */
  const getSlide = (slideIndex) => {
    return slideEls?.current[slideIndex];
  };

  /**
   * Updates the state with dimensions of the viewport
   */
  const handleResize = () => {
    scrollToSlide(getSlide(activeSlide));
  };

  useEffect(() => attachResizeListeners(handleResize, 100));

  /**
   * Updates the state specifically to the next slide if there is one
   */
  const goToNext = () => {
    const newSlide = activeSlide + 1,
      newSlideEl = getSlide(newSlide);
    if (newSlide < items.length && newSlideEl) {
      scrollToSlide(newSlideEl);
    }
  };

  /**
   * Updates the state specifically to the previous slide if there is one
   */
  const goToPrevious = () => {
    const newSlide = activeSlide - 1,
      newSlideEl = getSlide(newSlide);
    if (newSlide >= 0 && newSlideEl) {
      scrollToSlide(newSlideEl);
    }
  };

  /**
   * Gets the x position of the given element's center
   * @param {HTMLElement} el element to check
   * @returns {Number} x position of center
   */
  const getCenter = (el) => {
    if (el) {
      const elRect = el.getBoundingClientRect();
      return elRect.x + elRect.width / 2;
    }
    return null;
  };

  /**
   * Checks and handles when the next or previous slide
   * is scrolled to.  If in view, sets it to the active slide
   * @param {Event} event triggering event
   */
  const handleSlideScrolling = (event) => {
    const parentEl = slideshowEl?.current,
      parentCenter =
        parentEl.getBoundingClientRect().x +
        parentEl.getBoundingClientRect().width / 2;

    /**
     * Determines which center position of the previous, current, and next slide
     * is closest to the center position of the parent.
     * @returns {Number} closest center position
     */
    const closestToCenter = () => {
      // the order of this array is important and indicates later which slide to set...
      // sorryyyy
      const slideCentersArray = [
        getCenter(getSlide(activeSlide - 1)), // prev slide
        getCenter(getSlide(activeSlide)), // current slide
        getCenter(getSlide(activeSlide + 1)), // next slide
      ];

      return slideCentersArray.indexOf(
        slideCentersArray.reduce((prev, curr) => {
          return Math.abs(curr - parentCenter) < Math.abs(prev - parentCenter)
            ? curr
            : prev;
        }),
      );
    };

    // bleh. determined which slide to set based on index of slideCentersArray
    switch (closestToCenter()) {
      case 0:
        if (getSlide(activeSlide - 1)) {
          setActiveSlide(activeSlide - 1);
        }
        break;
      case 2:
        if (getSlide(activeSlide + 1)) {
          setActiveSlide(activeSlide + 1);
        }
        break;
    }
  };

  /**
   * Scrolls the focused element into view
   * @param {Number} elIndex index of el
   */
  const handleFocus = (elIndex) => {
    scrollToSlide(getSlide(elIndex));
  };

  /**
   * Rendering of Carousel Items
   * @returns {HTMLElement} carousel items
   */
  const renderItems = () => {
    const itemId = `${id}_ITEM`;
    return items.map((item, index) => {
      const cssItem = classNames("carousel__item", {
        "is-active": activeSlide === index,
      });
      const storeRef = (element) => slideEls.current.push(element);
      if (item.props && item.props.type === "text/sitecore") return item;
      return (
        <div
          className={cssItem}
          key={`${itemId}-${index}_KEY`}
          id={`${itemId}-${index}_ID`}
          ref={storeRef}
          // eslint-disable-next-line
          tabIndex="0" // make focusable to keyboard users can easily navigate
          role="group"
          aria-roledescription="slide"
          onFocus={() => handleFocus(index)}
          aria-label={`${index + 1} of ${items.length}`}
        >
          {item}
        </div>
      );
    });
  };

  return (
    <section
      className="carousel"
      aria-roledescription="carousel"
      aria-label={title}
      ref={thisEl}
    >
      <SkipLink
        id={`${id}_SKIPTOEND`}
        targetId={`${id}_SKIPTOSTART`}
        customClass={"grid--100"}
        text="Skip to end of carousel"
      />
      <div className="carousel__inner">
        <div
          className="carousel__slides grid--100"
          ref={slideshowEl}
          id={`${id}_SLIDES`}
          onScroll={handleSlideScrolling}
        >
          {renderItems()}
        </div>
        <div className="carousel__footer grid--100">
          <CustomScrollbar
            customClass="grid--100"
            isDark={props?.fields?.isDark}
            scrollContainer={slideshowEl}
            scrollContainerTitle={title}
          />
          <div className="carousel__footer-wrapper">
            <p className="carousel__indicator" aria-live="polite">
              <span className="sr-only">Slide</span>{" "}
              {padWithZeros(activeSlide + 1)}
              <span className="carousel__total">
                <span> / </span>
                {padWithZeros(items.length)}
              </span>
            </p>
            <div className="carousel__controls">
              <button
                className="btn btn--primary carousel__control-btn carousel__control-btn--left"
                aria-disabled={activeSlide === 0 || null}
                onClick={goToPrevious}
              >
                <svg
                  className="icon"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Previous Slide</title>
                  <path d="M22 12L3 12" strokeWidth="2" />
                  <path d="M10 5L2 12L10 19" strokeWidth="2" />
                </svg>
              </button>
              <button
                className="btn btn--primary carousel__control-btn carousel__control-btn--right"
                onClick={goToNext}
                aria-disabled={activeSlide === items.length - 1 || null}
              >
                <svg
                  className="icon"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Next Slide</title>
                  <path d="M1 12L20 12" strokeWidth="2" />
                  <path d="M13 5L21 12L13 19" strokeWidth="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <SkipLink
        id={`${id}_SKIPTOSTART`}
        targetId={`${id}_SKIPTOEND`}
        customClass={"grid--100"}
        text="Skip to start of carousel"
      />
    </section>
  );
};

Carousel.propTypes = propTypes;
export default Carousel;
