/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { bool, object } from "prop-types";
import classNames from "classnames";

const propTypes = {
  fields: object,
  isArticlePage: bool,
  isAriaHidden: bool,
  noPadding: bool,
};

const SharedDivider = (props) => {
  const {
    fields: { isDark: { value: isDark } = {} } = {},
    isArticlePage,
    isAriaHidden,
    noPadding,
  } = props;
  const cssClasses = classNames("divider", {
    "grid--100": !noPadding,
  });
  const cssClassesInner = classNames("divider__line", {
    "divider--dark": isDark,
    "divider--spaced grid--small": isArticlePage,
  });

  return (
    <div className={cssClasses} aria-hidden={isAriaHidden}>
      <hr className={cssClassesInner} />
    </div>
  );
};

SharedDivider.propTypes = propTypes;
export default SharedDivider;
