/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import classNames from "classnames/bind";
import {
  Text,
  DateField,
  Image,
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, string, object } from "prop-types";
import { useTranslation } from "react-i18next";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import { formatDate, getDateField } from "../../utils/helpers.js";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    opacity: shape({
      fields: shape({
        opacity: shape({
          value: string,
        }),
      }),
    }),
  }),
};

/**
 * Main render function for the Article Page Banner.
 * This component is a hero section for use on Article Detail Pages.
 * As of now, it has three types of layouts: Circle, 50/50, and Fullbleed
 * @param {Object} props sitecore component fields
 * @returns {HTMLElement}
 */
const ArticlePageBanner = (props) => {
  const { t } = useTranslation(),
    [updateLabel] = useState(t("Updated"));
  try {
    const { sitecoreContext: { route: route } = {} } = props;

    /** Key-value pair for available banner types */
    const BANNER_TYPE = {
      CIRCLE: "circle",
      SPLIT5050: "50-50",
      FULLBLEED: "full-bleed",
    };

    const bannerType = getFieldValue(
        route.fields?.bannerType?.fields,
        "bannerType",
      ),
      opacity = getFieldValue(props.fields?.opacity?.fields, "opacity"),
      headerMediaCaption = route.fields?.headerMediaCaption,
      isCircleBanner = bannerType === BANNER_TYPE.CIRCLE,
      hasAltText = !!getFieldValue(route?.fields, "headerImage")?.alt,
      cssBanner = classNames({
        "article-banner--circle": bannerType === BANNER_TYPE.CIRCLE,
        "article-banner--50-50": bannerType === BANNER_TYPE.SPLIT5050,
        "article-banner--full-bleed": bannerType === BANNER_TYPE.FULLBLEED,
      });

    /**
     * Determines if the given value is a valid value for the css opacity property.
     * Returns an empty string if invalid.
     * @param {String} opacityValue
     * @returns {String} the opacity value if valid
     */
    const getOpacity = (opacityValue) => {
      const isValidOpacity =
        !isNaN(opacityValue) && opacityValue <= 1 && opacityValue >= 0;
      return isValidOpacity ? opacityValue : "";
    };

    /**
     * Renders the Image section of this component
     * @param {Object} route the page data
     * @param {Object} props other fields to pass
     * @returns {HTMLElement} image section
     */
    const renderImageSection = (route, props) => {
      const headerImage = route?.fields?.headerImage,
        isCircleBanner = props?.bannerType === BANNER_TYPE.CIRCLE,
        isFullBanner = props?.bannerType === BANNER_TYPE.FULLBLEED,
        cssGrid = isCircleBanner ? " grid--100" : "",
        cssBackground = isCircleBanner
          ? getFieldValue(
              route?.fields?.colorTheme?.fields.primary?.fields,
              "color",
            )
          : "initial",
        altText = props?.hasAltText
          ? getFieldValue(route?.fields, "headerImage")?.alt
          : getFieldValue(route?.fields, "headerMediaCaption");

      return (
        <div
          className={`article-banner__image-section${cssGrid}`}
          style={{ backgroundColor: cssBackground }}
        >
          <picture className="article-banner__image">
            <Image className="img" media={headerImage} alt={altText} />
          </picture>
          {isFullBanner && (
            <div
              className="article-banner__screen"
              style={{ opacity: getOpacity(props?.opacity) }}
            ></div>
          )}
        </div>
      );
    };

    /**
     * Renders the copy/text section of the component
     * @param {Object} route the page field data
     * @param {Object} props other fields to pass
     * @returns {HTMLElement} copy section
     */
    const renderCopySection = (route, props) => {
      const primaryColorFields =
          route?.fields?.colorTheme?.fields?.primary?.fields,
        { dateField, dateString, isUpdated } = getDateField(
          route?.fields?.displayDate,
          route?.fields?.originalDate,
        ),
        is5050Banner = props?.bannerType === BANNER_TYPE.SPLIT5050,
        isDarkBackground = getFieldValue(primaryColorFields, "lightText"),
        cssDarkBackground = isDarkBackground && is5050Banner ? " is-dark" : "",
        cssBackground = is5050Banner
          ? getFieldValue(primaryColorFields, "color")
          : "initial";

      return (
        <div
          className="article-banner__copy-section"
          style={{ backgroundColor: cssBackground }}
        >
          <div className={`grid--100 article-banner__copy${cssDarkBackground}`}>
            {(dateField || isUpdated) && (
              <p className="article-banner__date">
                {isUpdated && `${updateLabel}: `}
                <DateField
                  field={dateField}
                  render={() => formatDate(dateString)}
                />
              </p>
            )}
            <h1 className="article-banner__title">
              <Text field={route?.fields?.headerTitle} />
            </h1>
            <p className="article-banner__subtitle">
              <Text field={route?.fields?.subtitle} />
            </p>
          </div>
        </div>
      );
    };

    return (
      <section className={`article-banner ${cssBanner}`}>
        <div className="article-banner__top">
          {isCircleBanner && renderCopySection(route, { bannerType })}
          {renderImageSection(route, { opacity, bannerType, hasAltText })}
          {!isCircleBanner && renderCopySection(route, { bannerType })}
        </div>
        <div
          className="article-banner__bottom grid grid--25-50-25"
          aria-hidden={!hasAltText}
        >
          <p className="grid--50 article-banner__caption">
            <Text field={headerMediaCaption} />
          </p>
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticlePageBanner.propTypes = propTypes;

export default withSitecoreContext()(ArticlePageBanner);
