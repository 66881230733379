/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect, useRef } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object, bool } from "prop-types";
import classNames from "classnames/bind";
import FocusLock from "react-focus-lock";
import Submenu from "./Submenu.js";
import HeaderLogo from "../HeaderLogo/HeaderLogo.js";
import GlobalHeaderCTA from "../GlobalHeaderCTA/GlobalHeaderCTA.js";
import { isLargeBreakpoint } from "../../utils/breakpoints.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import useIsSSR from "../../hooks/isSSR.js";
import {
  debounce,
  attachResizeListeners,
  setFocus,
} from "../../utils/helpers.js";

const propTypes = {
  isMobile: bool,
  isDark: bool,
  sitecoreContext: object,
};

debounce();

/**
 * A Primary navigation component to be used in the global header.
 * Contains a list of links used to navigate the website
 * @param {Object} props
 * @returns {HTMLElement} Primary Navigation
 */
const PrimaryNav = (props) => {
  const isSSR = useIsSSR();
  const { isMobile, isDark, sitecoreContext } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [addedNoScroll, setAddedNoScroll] = useState(false);
  const [hasOpenSubmenu, setHasOpenSubmenu] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: isSSR ? 0 : window.innerHeight,
    width: isSSR ? 0 : window.innerWidth,
  });
  const isEditing = sitecoreContext?.pageEditing;
  const logo = sitecoreContext?.globalConfiguration?.headerLogo;
  const calloutCTA = sitecoreContext?.globalConfiguration?.headerCTA;
  const cssOpen = classNames({ "is-active": isOpen });
  const cssSubmenu = classNames({ "is-inactive": hasOpenSubmenu });
  const cssNav = classNames({
    "is-dark": isDark,
    "is-mobile": isMobile,
    "is-editing": isEditing,
  });
  const isLargeViewportBreakpoint = isLargeBreakpoint(dimensions.width);
  const navCloseBtn = useRef(null);
  const navOpenBtn = useRef(null);

  /**
   * Updates the state with dimensions of the viewport
   */
  const handleResize = () => {
    setDimensions({
      height: isSSR ? 0 : window.innerHeight,
      width: isSSR ? 0 : window.innerWidth,
    });
  };

  // other lifecyle updates
  useEffect(() => {
    // resize event listener
    attachResizeListeners(handleResize, 100);

    let body = document.querySelector("body");
    if (body) {
      if (isOpen && !isLargeViewportBreakpoint) {
        body.classList.add("no-scroll");
        setAddedNoScroll(true);
      } else if (addedNoScroll) {
        body.classList.remove("no-scroll");
        setAddedNoScroll(false);
      }
    }
  }, []); // eslint-disable-line

  try {
    /**
     * Explicitly closes the primary nav menu and
     * sets the focus to the toggle button
     */
    const closeMenu = () => {
      setIsOpen(false);
      setHasOpenSubmenu(false);
      setFocus(navOpenBtn?.current, 100);
    };

    /**
     * Explicitly opens the primary nav menu and
     * sets the focus to the toggle button
     */
    const openMenu = () => {
      setIsOpen(true);
      setHasOpenSubmenu(false);
      setFocus(navCloseBtn?.current, 100);
    };

    /**
     * Maps any key events and handles them accordingly
     * @param {Event} event triggering event
     * @returns {VoidFunction}
     */
    const handleKeypress = (event) => {
      switch (event.key) {
        case "Escape":
          closeMenu();
          return;
      }
    };

    /**
     * Listens to child submenus to hear if a submenu is open.
     * If so, updates the state
     * @param {Boolean} event object emitted from submenu
     */
    const handleExpanded = (event) => {
      setHasOpenSubmenu(event);
    };

    /**
     * Renders the appropriate icon to display for the
     * main primary nav toggle button
     * @returns {HTMLElement} primary nav menu icons
     */
    const renderToggleIcons = () => {
      if (isOpen) {
        return (
          <svg
            aria-hidden="true"
            className="icon"
            fill="none"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <rect width="2" height="23.4" rx="1" transform="rotate(-45 2 1)" />
            <rect width="2" height="23.5" rx="1" transform="rotate(45 8 20)" />
          </svg>
        );
      } else {
        return (
          <svg
            aria-hidden="true"
            className="icon"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <rect y="1" width="24" height="2" rx="1" />
            <rect y="21" width="24" height="2" rx="1" />
            <rect y="11" width="24" height="2" rx="1" />
          </svg>
        );
      }
    };

    /**
     * Renders the main primary nav menu, as well
     * as any submenus if provided
     * @returns {HTMLElement} primary nav menu
     */
    const renderSubmenu = () => {
      return (
        <ul className={`primary-nav__menu ${cssSubmenu}`}>
          {sitecoreContext.primaryNav?.map((linkProps, index) => {
            const { link, title, children } = linkProps;

            const sublist = children || [];
            return (
              <Submenu
                key={`pNavSubmenu-${index}`}
                link={link}
                title={title}
                children={sublist}
                isMobile={isMobile}
                defaultOpen={hasOpenSubmenu}
                onExpanded={handleExpanded}
              />
            );
          })}
        </ul>
      );
    };

    /**
     * Renders the main toggle button for the primary nav
     * @param {Boolean} isClose if button should close or open menu
     * @returns {HTMLElement} Open/Close menu button
     */
    const renderToggleButton = (isClose = false) => {
      const refName = isClose ? navCloseBtn : navOpenBtn;
      const onClick = isClose ? closeMenu : openMenu;
      const isHidden = isClose ? !isOpen : isOpen;
      const cssButton = classNames({ "is-hidden": isHidden });
      const idButton = isClose
        ? "PRIMARY_NAV_TITLE_CLOSE"
        : "PRIMARY_NAV_TITLE";
      return (
        <button
          className={`primary-nav__mobile-toggle btn ${cssOpen} ${cssButton}`}
          aria-expanded={isOpen}
          onClick={onClick}
          ref={refName}
        >
          <span
            id={`${idButton}_${isMobile ? "MOBILE" : "DESKTOP"}`}
            className="sr-only"
          >
            {isClose ? "Close Menu" : "Menu"}
          </span>
          {renderToggleIcons()}
        </button>
      );
    };

    /**
     * Renders the main primary nav content
     * @returns {HTMLElement} main primary nav content
     */
    const renderMainContent = () => {
      return (
        <div
          className="primary-nav__inner grid--100"
          role="presentation"
          onKeyUp={handleKeypress}
        >
          {isMobile && (
            <div className="primary-nav__header is-dark">
              {logo && isOpen && <HeaderLogo fields={logo} isDark={true} />}
              {renderToggleButton(true)}
            </div>
          )}

          {(isOpen || !isMobile) && renderSubmenu()}

          {calloutCTA && isOpen && (
            <div className="primary-nav__footer is-dark">
              <GlobalHeaderCTA fields={calloutCTA} />
            </div>
          )}
        </div>
      );
    };

    return (
      <nav
        className={`primary-nav ${cssOpen} ${cssNav}`}
        aria-label="Primary"
        key={isMobile ? "PrimaryNavMobileEl" : "PrimaryNavDesktopEl"}
      >
        <FocusLock
          disabled={!isOpen || isLargeViewportBreakpoint}
          className="primary-nav__focus-trap"
        >
          {isMobile && renderToggleButton()}
          {isMobile ? (
            <div
              role="dialog"
              className={`primary-nav__wrapper ${cssOpen}`}
              aria-label="Primary Navigation Menu"
            >
              {renderMainContent()}
            </div>
          ) : (
            renderMainContent()
          )}
        </FocusLock>
      </nav>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

PrimaryNav.propTypes = propTypes;
export default withSitecoreContext()(PrimaryNav);
