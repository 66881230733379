/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, forwardRef, useEffect, useId } from "react";
/* eslint-enable */
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { bool, shape, string } from "prop-types";
import { truncateText } from "../../utils/helpers.js";
import DividerLine from "../DividerLine/DividerLine";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useIsSSR from "../../hooks/isSSR.js";

const propTypes = {
  fields: shape({
    question: shape({
      value: string,
    }),
    answer: shape({
      value: string,
    }),
  }),
  hasDivider: bool,
  hasLimit: bool,
  useH2: bool,
  id: string,
};

const FAQCard = forwardRef((props, ref) => {
  const fallbackId = useId();
  const id = props?.id ? `FAQ-${props.id}` : `FAQ-CARD_${fallbackId}`;
  const { t } = useTranslation();
  const [readMoreLabel] = useState(t("ReadMore"));
  const [shortAnswer, setShortAnswer] = useState("");
  const [shortQuestion, setShortQuestion] = useState("");
  const faqAnswerEl = useRef(null);

  const isSSR = useIsSSR();

  useEffect(() => {
    const { question, answer } = props?.fields;

    const convertToPlainText = (html) => {
      const tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = html;
      return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    setShortAnswer(
      truncateText(
        convertToPlainText(answer?.value),
        350,
        ` ...${readMoreLabel}`,
      ),
    );

    setShortQuestion(
      truncateText(
        convertToPlainText(question?.value),
        150,
        ` ...${readMoreLabel}`,
      ),
    );
  }, [props, readMoreLabel]);

  try {
    const { question, answer } = props?.fields;
    const cssClass = classNames("faq-card", {
      "is-truncated": props?.hasLimit,
    });

    /**
     * Renders the question text in the proper element tag
     * @returns {HTMLElement} Question text
     */
    const renderQuestion = () => {
      if (props?.hasLimit) {
        return <p>{shortQuestion}</p>;
      } else {
        return <RichText field={question} />;
      }
    };

    return isSSR ? (
      <div></div>
    ) : (
      <section
        className={cssClass}
        id={id}
        tabIndex="-1"
        aria-labelledby={`${id}_QUESTION`}
        ref={ref}
      >
        <div className="faq-card__inner">
          {props.useH2 ? (
            <h2 className="faq-card__question" id={`${id}_QUESTION`}>
              {renderQuestion()}
            </h2>
          ) : (
            <div className="faq-card__question" id={`${id}_QUESTION`}>
              {renderQuestion()}
            </div>
          )}
          {props.hasDivider && (
            <DividerLine
              noPadding={true}
              isAriaHidden={true}
              fields={{ isDark: { value: false } }}
            />
          )}
          <div className="faq-card__answer" ref={faqAnswerEl}>
            {props?.hasLimit ? (
              <p>{shortAnswer}</p>
            ) : (
              <RichText field={answer} />
            )}
          </div>
        </div>
      </section>
    );
  } catch (error) {
    <Error {...props} error={error} />;
  }
});

FAQCard.propTypes = propTypes;

export default FAQCard;
