/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import SocialShare from "../SocialShare/SocialShare";
import { useTranslation } from "react-i18next";

const propTypes = {
  sitecoreContext: object,
};

/**
 * Thank you message after successful donation. Copied from
 * DonateThankYouForm component with <dialog> removed
 */
const DonateThankYou = (props) => {
  const { t } = useTranslation();

  function dangerousT(text) {
    return { __html: t(text) };
  }
  return (
    <section className="donate-thanks">
      <div className="donate-thanks__header donate-thanks__header--top-padding">
        <div className="grid grid--25-50-25">
          <div className="grid--25"></div>
          <h1 className="donate-thanks__title grid--50">
            {t("DonateThanksTitle")}
          </h1>
          <div className="grid--25"></div>
        </div>
      </div>
      <div className="grid grid--25-50-25">
        <div className="grid--25"></div>
        <div className="grid--50">
          <section className="donate-thanks__intro">
            <h2 className="donate-thanks__subtitle">
              {t("DonateThanksSubtitle")}
            </h2>
            <div
              className="donate-thanks__copy"
              dangerouslySetInnerHTML={dangerousT("DonateThanksBody")}
            />
          </section>
          <section className="donate-thanks__footer">
            <SocialShare />
          </section>
        </div>
        <div className="grid--25"></div>
      </div>
    </section>
  );
};

DonateThankYou.propTypes = propTypes;

export default withSitecoreContext()(DonateThankYou);
