/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Placeholder, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error";

const propTypes = {
  rendering: object,
  fields: object,
};

const FullBleedContainer = (props) => {
  try {
    return (
      <section
        style={{
          backgroundColor:
            getFieldValue(props?.fields?.backgroundColor?.fields, "color") ??
            "initial",
        }}
      >
        <Placeholder
          name="jss-full-bleed-container"
          rendering={props.rendering}
        />
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FullBleedContainer.propTypes = propTypes;

export default FullBleedContainer;
