/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  RichText,
  Link,
  Image,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, string, object } from "prop-types";
import classNames from "classnames";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),

    downloadLink: shape({
      value: object,
    }),
    icon: shape({
      value: object,
    }),
  }),
  params: shape({
    isDark: string,
  }),
};

/**
 * A component that is used to start a new section of a page.
 * The design contains a title, description, and a decorative dot
 * It should mostly be used before a component that does not
 * already have an H2 of it's own, such as the Card Carousel
 * @param {Object} props
 * @returns {HTMLElement} Section Headline
 */
const SectionHeadline = (props) => {
  const {
    fields: { title, description, downloadLink, icon },
    params,
  } = props;

  const altText = getFieldValue(props.fields, icon)?.alt || "";

  const isDarkBoolean = params?.isDark === "true" || params?.isDark === "1";
  const cssMain = classNames("headline", { "is-dark": isDarkBoolean });
  return (
    <section className={cssMain}>
      <div className="headline__inner grid--100">
        <Text className="headline__title" tag="h2" field={title} />
        <RichText className="headline__description" field={description} />
      </div>
      {!!downloadLink?.value?.href && (
        <div className="headline__link-container">
          <Link
            className="btn btn--tertiary headline__link"
            field={downloadLink}
          />
          <picture className="headline__link-image">
            <Image className="headline__img" field={icon} alt={altText} />
          </picture>
        </div>
      )}
    </section>
  );
};

SectionHeadline.propTypes = propTypes;

export default SectionHeadline;
