/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect } from "react";
/* eslint-enable */
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import { shape, object, arrayOf, string, func, bool } from "prop-types";
import classNames from "classnames/bind";
import { array } from "prop-types";
import { setFocus } from "../../utils/helpers.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  link: shape({
    value: object,
    text: string,
  }),
  title: shape({
    value: string,
  }),
  children: arrayOf(
    shape({
      fields: array,
      name: string,
      template: string,
    }),
  ),
  isMobile: bool,
  onExpanded: func,
  defaultOpen: bool,
};

/**
 * A Submenu component that is designed to be placed within a list element.
 * This component will render as a single link, or will render as a link with
 * a nested Submenu if given children
 * @param {Object} props
 * @returns {HTMLElement} Submenu
 */
const Submenu = (props) => {
  const { link, title, children, onExpanded, defaultOpen, isMobile } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isSelected, setIsSelected] = useState(false);
  const submenuToggleBtn = useRef(null);
  const thisEl = useRef(null);
  useEffect(() => {
    setIsSelected(window?.location?.pathname === link?.value?.href);
  }, [link]);

  try {
    const cssOpen = classNames({ "is-active": isOpen });
    const cssButton = classNames({ "primary-nav__link": !!title });
    const cssLink = classNames({ "is-selected": isSelected });

    /**
     * Open/closes the child submenu and
     * emits an event with the current state
     */
    const toggleSubmenu = () => {
      onExpanded(!isOpen);
      setIsOpen(!isOpen);
    };

    /**
     * Explicitly closes the submenu and puts
     * focus on the triggering submenu toggle button
     */
    const closeMenu = (withFocus = true) => {
      onExpanded(false);
      setIsOpen(false);
      if (withFocus) {
        setFocus(submenuToggleBtn?.current);
      }
    };

    /**
     * Maps the pressed key and handles it accordingly
     * @param {Event} event triggering event
     * @returns {VoidFunction}
     */
    const handleKeypress = (event) => {
      switch (event.key) {
        case "Escape":
          event.stopPropagation();
          event.preventDefault();
          closeMenu();
          return;
      }
    };

    /**
     * Handles when a click outside the submenu has occurred
     * @param {Event} event triggering event
     */
    const handleOutsideFocus = (event) => {
      if (children?.length && isOpen && !isMobile) {
        if (
          !event.relatedTarget ||
          !thisEl?.current?.contains(event.relatedTarget)
        ) {
          closeMenu(false);
        }
      }
    };

    const renderIcon = () => {
      if (isOpen) {
        return (
          <svg
            className="icon"
            width="12"
            height="7"
            viewBox="0 0 12 7"
            aria-hidden={!!title}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 6L6 0.999999L1 6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      } else {
        return (
          <svg
            className="icon"
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            aria-hidden={!!title}
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            {link && <title>{link.value?.text} submenu</title>}
            <path
              d="M1 1L6 6L11 1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
    };

    /**
     * Renders the toggle button for the nested submenu
     * @returns {HTMLElement} submenu toggle button
     */
    const renderButton = () => {
      return (
        <button
          className={`btn primary-nav__submenu-toggle ${cssOpen} ${cssButton}`}
          onClick={toggleSubmenu}
          aria-expanded={isOpen}
          ref={submenuToggleBtn}
        >
          {title && <Text field={title} />}
          {renderIcon()}
        </button>
      );
    };

    /**
     * Renders the children as submenus
     * @returns {HTMLElement} array of Submenus
     */
    const renderChildren = () => {
      return children.map((linkProps) => {
        const { link, children } = linkProps;
        const generatedKey = Math.floor(Math.random() * 10000);
        return (
          <Submenu
            key={`pNavSubmenu-${generatedKey}`}
            link={link}
            children={children}
          />
        );
      });
    };

    return (
      <li
        className="primary-nav__menu-item"
        ref={thisEl}
        onBlur={handleOutsideFocus}
      >
        {link && (
          <Link className={`btn primary-nav__link ${cssLink}`} field={link} />
        )}
        {!!children?.length && (
          <React.Fragment>
            {renderButton()}
            {isOpen && (
              <div role="presentation" onKeyUp={handleKeypress}>
                <ul className={`primary-nav__submenu ${cssOpen}`}>
                  {renderChildren()}
                </ul>
              </div>
            )}
          </React.Fragment>
        )}
      </li>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

Submenu.propTypes = propTypes;
export default Submenu;
