/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import classNames from "classnames";
import { shape, string, object } from "prop-types";

const propTypes = {
  fields: shape({
    title: object,
    report1Title: object,
    report2Title: object,
    report3Title: object,
    report4Title: object,
    report5Title: object,

    report1Link: shape({
      href: string,
      text: string,
    }),
    report2Link: shape({
      href: string,
      text: string,
    }),
    report3Link: shape({
      href: string,
      text: string,
    }),
    report4Link: shape({
      href: string,
      text: string,
    }),
    report5Link: shape({
      href: string,
      text: string,
    }),
  }),
};

const PastReports = (props) => {
  const {
    title,
    report1Title,
    report1Link,
    report2Title,
    report2Link,
    report3Title,
    report3Link,
    report4Title,
    report4Link,
    report5Title,
    report5Link,
  } = props.fields;

  const pastReportsClass = classNames("past-reports grid grid--100", {
    "margin-xlarge": report3Link,
  });

  return (
    <section className={pastReportsClass}>
      <div className="grid--50">
        <Text className="past-reports__title" tag="div" field={title} />
      </div>
      <div className="past-reports__links grid--50">
        <div className="past-reports__link">
          <Text tag="div" field={report1Title} />
          <Link className="btn btn--tertiary" field={report1Link} />
        </div>
        <div className="past-reports__link">
          <Text tag="div" field={report2Title} />
          <Link className="btn btn--tertiary" field={report2Link} />
        </div>
        {report3Title && report3Link && (
          <div className="past-reports__link">
            <Text tag="div" field={report3Title} />
            <Link className="btn btn--tertiary" field={report3Link} />
          </div>
        )}
        {report4Title && report4Link && (
          <div className="past-reports__link">
            <Text tag="div" field={report4Title} />
            <Link className="btn btn--tertiary" field={report4Link} />
          </div>
        )}
        {report5Title && report5Link && (
          <div className="past-reports__link">
            <Text tag="div" field={report5Title} />
            <Link className="btn btn--tertiary" field={report5Link} />
          </div>
        )}
      </div>
    </section>
  );
};

PastReports.propTypes = propTypes;

export default PastReports;
