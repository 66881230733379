/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, {useEffect} from "react";
/* eslint-enable */
import { shape, string } from "prop-types";

const propTypes = {
  fields: shape({
    heading: string,
  }),
};

const ImpactReport = (props) => {
  return <div></div>;
};

ImpactReport.propTypes = propTypes;

export default ImpactReport;
