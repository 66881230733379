/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { string } from "prop-types";
import classNames from "classnames";

const propTypes = {
  id: string,
  customClass: string,
  text: string,
  targetId: string,
  btnType: string,
};

const SkipLink = (props) => {
  const { id, customClass, text, targetId, btnType } = props;
  const cssClasses = classNames(
    "btn",
    { "btn--tertiary": !btnType },
    "tab-only",
    "skip-link",
    customClass,
    btnType,
  );

  return (
    <a href={`#${targetId}`} id={id} className={cssClasses}>
      {text}
    </a>
  );
};

SkipLink.propTypes = propTypes;
export default SkipLink;
