/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import Select from "react-select";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const propTypes = {
  fields: shape({
    heading: string,
  }),
  sitecoreContext: object,
};

const CountrySelector = (props) => {
  const { donate = {} } = props.sitecoreContext?.globalConfiguration || {};
  const donateUKLink = donate.donateLinkUK?.value?.href;
  const donateUSLink = donate.donateLinkUS?.value?.href;

  const customStyles = {
    // empty object reset the inline styles so we can control from CSS
    option: (provided, state) => ({}),
    valueContainer: (provided, state) => ({}),
    dropdownIndicator: (provided) => ({ ...provided, color: "#000" }),
    indicatorSeparator: () => ({}),
    indicatorContainer: () => ({
      "&:hover": {
        color: "inherit",
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
    }),
    menuList: () => ({}),
    placeholder: () => ({}),
    control: () => ({
      display: "flex",
    }),
    singleValue: () => ({}),
  };

  // default to hidden to avoid cluttering up page if error occurs
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [placeholderText, setPlaceholderText] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (
      window.location.href === donateUKLink ||
      window.location.pathname === donateUKLink
    ) {
      Cookies.set("GPP_DONATE_LOCALE", "UK", { expires: 365 });
      setPlaceholderText("UK");
    } else if (Cookies.get("GPP_DONATE_LOCALE") === "UK") {
      setPlaceholderText("UK");
    } else {
      if (!Cookies.get("GPP_DONATE_VIEW")) {
        setShouldShowToast(true);
        Cookies.set("GPP_DONATE_VIEW", "1", { expires: 365 });
      }
      setPlaceholderText("USA");
    }
  }, [donateUKLink]);

  const handleValueChange = (newValue) => {
    if (typeof window === "undefined") return;
    if (newValue.value === "UK") {
      Cookies.set("GPP_DONATE_LOCALE", "UK", { expires: 365 });
      if (donateUKLink && window.location.href !== donateUKLink) {
        window.location.href = donateUKLink;
      }
    } else {
      // no need to store a cookie for US
      Cookies.remove("GPP_DONATE_LOCALE");
      if (donateUSLink && window.location.href !== donateUSLink) {
        window.location.href = donateUSLink;
      }
    }
  };

  return (
    <section className="country-selector">
      <Select
        classNamePrefix="country-selector"
        styles={customStyles}
        isSearchable={false}
        placeholder={placeholderText}
        onMenuOpen={() => {
          setShouldShowToast(false);
        }}
        onChange={handleValueChange}
        options={[
          {
            label: "USA",
            value: "US",
          },
          {
            label: "UK",
            value: "UK",
          },
        ]}
      />
      {shouldShowToast && (
        <div className="country-selector__toast-message">
          {t("CountrySelectorToastMessage")}
        </div>
      )}
    </section>
  );
};

CountrySelector.propTypes = propTypes;

export default withSitecoreContext()(CountrySelector);
