/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Image,
  Link,
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { bool, object, shape, string } from "prop-types";
import classNames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    image: shape({
      value: shape({
        src: string,
        alt: string,
      }),
    }),
    stat: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    link: shape({
      value: shape({
        href: string,
        text: string,
      }),
    }),
  }),
  isDark: bool,
};

/**
 * A single Data Card/Element used to callout certain stats.
 * This component should most likely be placed within another
 * component, and is not truly meant to be stand alone on a page
 * @param {Object} props
 * @returns {HTMLElement} Data Card/Element
 */
const DataCard = (props) => {
  try {
    const {
      fields: { image, stat, description, link },
      isDark,
    } = props;
    const cssMain = classNames({ "is-dark": isDark });
    const cssButton = classNames({ "btn--dark": isDark });
    const altText = getFieldValue(props.fields, "image")?.alt || "",
      isEditing = props?.sitecoreContext?.pageEditing;

    return (
      <section className={`data-card ${cssMain}`}>
        <div className="data-card__image-section">
          <picture className="data-card__image">
            <Image className="img" field={image} alt={altText} />
          </picture>
        </div>
        <div className="data-card__copy-section">
          <Text className="data-card__stat" field={stat} tag="p" />
          <Text
            className="data-card__description"
            field={description}
            tag="p"
          />
          {(!!link?.value?.href || isEditing) && (
            <div className="data-card__cta">
              <Link className={`btn btn--tertiary ${cssButton}`} field={link} />
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DataCard.propTypes = propTypes;

export default withSitecoreContext()(DataCard);
