/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { func, object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";

const propTypes = {
  formValues: object,
  setFormValues: func,
  formErrors: object,
  setFormErrors: func,
};

const DonateFormFrequency = (props) => {
  try {
    const { formValues, setFormValues, formErrors, setFormErrors } = props;

    const updateFrequency = (e) => {
      setFormErrors({ ...formErrors, frequency: undefined });
      setFormValues({ ...formValues, frequency: e.target.value });
    };
    const classInput = classNames("gpp-hidden-radio", {
      "js-form-error": formErrors.frequency,
    });

    return (
      <fieldset className={formErrors.frequency && "gpp-input-label--error"}>
        <legend className="donate-form__section-title">
          How frequently would you like to give?
        </legend>
        <div role="radiogroup" className="grid">
          <input
            type="radio"
            name="form--donate-frequency"
            id="form--donate-frequency-once"
            className={classInput}
            value="once"
            onChange={(e) => updateFrequency(e)}
          />
          <label
            className="gpp-radio-label"
            htmlFor="form--donate-frequency-once"
          >
            Once
          </label>

          <input
            type="radio"
            name="form--donate-frequency"
            id="form--donate-frequency-monthly"
            className={classInput}
            value="monthly"
            onChange={(e) => updateFrequency(e)}
          />
          <label
            className="gpp-radio-label"
            htmlFor="form--donate-frequency-monthly"
          >
            Monthly
          </label>
        </div>
        {formErrors.frequency && (
          <div className="gpp-form-error">
            Please select a donation frequency
          </div>
        )}
      </fieldset>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateFormFrequency.propTypes = propTypes;
export default DonateFormFrequency;
