/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  getFieldValue,
  Text,
  withPlaceholder,
} from "@sitecore-jss/sitecore-jss-react";
import { array, object, shape, string } from "prop-types";
import classNames from "classnames";
import Carousel from "../SharedComponents/Carousel/Carousel.js";

const propTypes = {
  carouselItems: array,
  params: shape({
    isDark: string,
  }),
  rendering: object,
  fields: shape({
    title: shape({
      value: string,
    }),
  }),
};

/**
 * The Image Carousel is wrapper of a Carousel component
 * that allows for a customizable title, dark/light theme,
 * and more targeted css styling
 * @param {Object} props
 * @returns {HTMLElement} Image Carousel
 */
const ImageCarousel = (props) => {
  const {
    fields: { title },
    carouselItems,
    params,
  } = props;
  const isDarkBoolean = params?.isDark === "true" || params?.isDark === "1";
  const cssClasses = classNames({ "is-dark": isDarkBoolean });

  return (
    <section className={`image-carousel ${cssClasses}`}>
      <div className="grid--100">
        <Text className="image-carousel__title" field={title} tag="h2" />
      </div>
      <Carousel
        items={carouselItems}
        title={getFieldValue(props.fields, "title")}
      />
    </section>
  );
};

ImageCarousel.propTypes = propTypes;

export default withPlaceholder({
  placeholder: "jss-image-carousel-content",
  prop: "carouselItems",
})(ImageCarousel);
