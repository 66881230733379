/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect, useId } from "react";
/* eslint-enable */
import {
  Image,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, object, string } from "prop-types";
import { prefersReducedMotion } from "../../utils/helpers.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import { useTranslation } from "react-i18next";

const propTypes = {
  sitecoreContext: object,
  title: shape({
    value: string,
  }),
  description: shape({
    value: string,
  }),
  media: shape({
    value: object,
  }),
  image: shape({
    value: object,
  }),
};

const MediaItem = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const id = useId();
  const { t } = useTranslation(),
    [playLabel] = useState(t("Play")),
    [pauseLabel] = useState(t("Pause")),
    [videoLabel] = useState(t("Video")),
    [decorativeLabel] = useState(t("decorative"));

  useEffect(() => {
    if (!prefersReducedMotion()) setIsPlaying(true);
  }, [isPlaying]);
  try {
    const { title, description, media, image, sitecoreContext } = props;
    const srcUrl = media?.value?.src;
    const isEditing = sitecoreContext?.pageEditing;

    /**
     * Plays or pauses the video element based on
     * its current state
     * @param {HTMLMediaElement} videoEl video element
     */
    const controlVideo = () => {
      isPlaying ? handlePauseVideo() : handlePlayVideo();
    };

    /**
     * Sets the state to Play
     */
    const handlePlayVideo = () => {
      setIsPlaying(true);
    };

    /**
     * Sets the state to pause
     */
    const handlePauseVideo = () => {
      setIsPlaying(false);
    };

    /**
     * Renders the play/pause button
     * @returns {HTMLElement} play/pause button
     */
    const renderButton = () => {
      return (
        <button
          aria-live="assertive"
          id={`${id}_VIDEO_CONTROL_BTN`}
          aria-controls={`${id}_VIDEO`}
          className="media-three-up__control-btn tab-only"
          onClick={controlVideo}
        >
          {isPlaying ? (
            <svg
              className="icon"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>
                {pauseLabel} {title?.value} {decorativeLabel} {videoLabel}
              </title>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm0 2c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
                fill="#fff"
              />
              <rect x="12" y="9" width="2" height="14" rx="1" fill="#fff" />
              <rect x="18" y="9" width="2" height="14" rx="1" fill="#fff" />
            </svg>
          ) : (
            <svg
              viewBox="0 0 32 32"
              fill="none"
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>
                {playLabel} {title?.value} {decorativeLabel} {videoLabel}
              </title>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm0 2c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
                fill="#fff"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.085 9.222a2 2 0 012.077.15l7 5a2 2 0 010 3.256l-7 5A2 2 0 0112 21V11a2 2 0 011.085-1.778zM14 21l7-5-7-5v10z"
                fill="#fff"
              />
            </svg>
          )}
        </button>
      );
    };

    return (
      <div className="media-three-up__item">
        <div className="media-three-up__media-section">
          <div className="media-three-up__media">
            {(isPlaying || isEditing) && (
              <Image
                className="img"
                field={media}
                alt={media?.value?.alt || ""}
              />
            )}
            {(!isPlaying || !srcUrl || isEditing) && (
              <picture>
                <Image
                  className="img"
                  field={image}
                  alt={image?.value?.alt || ""}
                />
              </picture>
            )}
            {!isEditing && renderButton()}
          </div>
        </div>
        <div className="media-three-up__copy-section">
          <div className="media-three-up__copy">
            <Text className="media-three-up__title" field={title} tag="p" />
            <Text
              className="media-three-up__description"
              field={description}
              tag="p"
            />
          </div>
        </div>
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

MediaItem.propTypes = propTypes;
export default withSitecoreContext()(MediaItem);
