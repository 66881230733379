var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

import React from 'react';
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
export const FieldEditFrameWrapper = ({ itemId, fields, databaseName, language, children, showChildCommands }) => {
    // ensure fields and itemId are configured
    if (!itemId || !fields) {
        return null;
    }
    // item uri for edit frame target
    const contextItemUri = `sitecore://${databaseName}/${itemId}?lang=${language}`;
    // click action for edit frame command
    const clickCommandAction = `webedit:fieldeditor(command="{70C4EED5-D4CD-4D7D-9763-80C42504F5E7}",fields=${fields.join("|")},id=${itemId})`;
    // click for command for the edit frame
    const clickCommand = {
        click: `javascript:Sitecore.PageModes.PageEditor.postRequest('${clickCommandAction}',null,false)`,
        header: 'Edit Fields',
        icon: '/temp/iconcache/people/16x16/cubes_blue.png',
        isDivider: false,
        tooltip: `Edit the following fields: ${fields.join(", ")}`,
        type: null
    };
	const deleteCommand = {
        click: `javascript:Sitecore.PageModes.PageEditor.postRequest('webedit:delete(id=${itemId})',null,false)`,
        header: "Delete Link",
        icon: "/temp/iconcache/Office/16x16/delete.png",
        isDivider: false,
        tooltip:"Delete the item",
        type: null
    };
	const moveUpCommand = {
        click: `javascript:Sitecore.PageModes.PageEditor.postRequest('item:moveup(id=${itemId})',null,false)`,
        header: "Move Up",
        icon: "/temp/iconcache/Office/16x16/navigate_up.png",
        isDivider: false,
        tooltip: "Move the item up",
        type: null
    };
	const moveDownCommand = {
        click: `javascript:Sitecore.PageModes.PageEditor.postRequest('item:movedown(id=${itemId})',null,false)`,
        header: "Move Down",
        icon: "/temp/iconcache/Office/16x16/navigate_down.png",
        isDivider: false,
        tooltip: "Move the item down",
        type: null
    };
	
	const commands = [
  ... fields && fields.length ? [clickCommand] : [],
  ... showChildCommands ? [deleteCommand, moveUpCommand, moveDownCommand] : [],
]

    // command data that is serialized for the edit frame
    const commandData = {
        commands: commands,
        contextItemUri: contextItemUri,
        custom: {},
        displayName: 'Edit Properties',
        expandedDisplayName: ""
    };
	console.log(showChildCommands)
    // edit frame attributes
    const divAttrs = {
        'sc_item': contextItemUri,
        'sc-part-of': 'editframe'
    };
    return React.createElement("div", Object.assign({ className: "scLooseFrameZone scEnabledChrome" }, divAttrs),
        React.createElement("span", { className: "scChromeData" }, JSON.stringify(commandData)),
        children);
};
export const FieldEditFrameComponent = (props) => {
    var { sitecoreContext, children, itemId, fields, hideIfNotPageEditing, databaseName, language, showChildCommands } = props, otherProps = __rest(props, ["sitecoreContext", "children", "itemId", "fields", "hideIfNotPageEditing", "databaseName", "language"]);
    // check if we're in experience editor and configured properly
    const shouldRender = sitecoreContext.pageEditing && itemId && ((fields && fields.length) || showChildCommands);
    // hide if not in page editing mode and prop is passed telling us to hide
    if (!shouldRender && hideIfNotPageEditing) {
        return null;
    }

    // if we're configured properly, wrap in edit frame.
    const WrapperComponent = shouldRender ? FieldEditFrameWrapper : React.Fragment;
    // build the props for our edit frame
    const WrapperProps = Object.assign({ itemId,
        fields, showChildCommands, databaseName: databaseName || sitecoreContext.route.databaseName, language: language || sitecoreContext.language, sitecoreContext }, otherProps);
    return React.createElement(WrapperComponent, Object.assign({}, WrapperProps), children);
};
FieldEditFrameComponent.defaultProps = {
    itemId: '',
    fields: [],
	showChildCommands: false,
};
export const FieldEditFrame = withSitecoreContext()(FieldEditFrameComponent);
