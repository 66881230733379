import { useState, useEffect } from "react";

/**
 * Check if React is rendering server-side, or if we are in a browser
 * environment and can use `window` or `document`.
 * @returns {boolean} True if rendering server-side, false if in a browser
 */
export default function useIsSSR() {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSSR(false);
  }, []);

  return isSSR;
}
