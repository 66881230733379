/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useRef, useState, useEffect } from "react";
/* eslint-enable */
import { bool, object, string } from "prop-types";
import classNames from "classnames";
import { debounce } from "../../../utils/helpers.js";
import Error from "../ErrorHandling/Error.js";

const propTypes = {
  isDark: bool,
  customClass: string,
  scrollContainerTitle: string,
  scrollContainer: object,
};

debounce();

/**
 * This Custom Scrollbar allows for control over a given
 * component's scrolling functionality.  To get it working, simply
 * pass a ref to the scrollContainer prop, and for accessibility,
 * the referenced container being passed should have a
 * unique id.
 * @param {Object} props
 * @returns {HTMLElement} Custom Scrollbar
 */
const CustomScrollbar = (props) => {
  const [isHoveringThumb, setIsHoveringThumb] = useState(false);
  const [scrollContainerWidth, setScrollContainerWidth] = useState(0);
  const [scrollContainerWindow, setScrollContainerWindow] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const scrollThumbEl = useRef(null);

  // Component mounted
  useEffect(() => {
    const scrollContainer = props?.scrollContainer?.current;

    /**
     * Sets the needed states of the controlled container
     * @param {Number} width scroll width of the controlled container
     * @param {Number} position scroll position of the controlled container
     * @param {Number} window client width of the controlled container
     */
    const setScrollProperties = (width = 0, position = 0, window = 0) => {
      setScrollContainerWidth(width);
      setScrollContainerWindow(window);
    };

    /**
     * Handles when the controlled container is scrolled
     * @param {Event} event scroll event
     */
    const handleScroll = (event) => {
      const target = event?.target;
      setScrollProperties(
        target?.scrollWidth,
        target?.scrollLeft,
        target?.clientWidth,
      );
      setScrollOffset((target?.scrollLeft / target?.scrollWidth) * 100);
    };

    // Defaults
    setScrollProperties(
      scrollContainer?.scrollWidth,
      scrollContainer?.scrollLeft,
      scrollContainer?.clientWidth,
    );

    scrollContainer?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  });

  try {
    const { isDark, customClass, scrollContainerTitle, scrollContainer } =
      props;
    const cssClasses = classNames("custom-scrollbar", customClass, {
      "is-dark": isDark,
    });
    const containerId = scrollContainer?.current?.id,
      scrollThumbWidth = scrollContainerWindow / scrollContainerWidth;

    /**
     * Scrolls the container that this component controls
     * @param {Number} scrollPosition the x position to scroll to
     * @param {Boolean} withScroll if scrolling should be smooth
     */
    const updateScrollContainer = (scrollPosition, withScroll = false) => {
      scrollContainer?.current?.scrollTo({
        top: 0,
        left: scrollPosition,
        behavior: withScroll ? "smooth" : "auto",
      });
    };

    /**
     * Handles when the scrollbar thumb is being dragged
     * by the user mouse
     * @param {Event} event mousemove event
     */
    const handleBarDrag = (event) => {
      if (event.movementX !== 0) {
        const scrollbarWidth =
          scrollThumbEl?.current?.offsetParent?.clientWidth;
        const newScrollbarOffset =
          scrollThumbEl?.current?.offsetLeft + event?.movementX;
        const maxWidth =
          newScrollbarOffset + scrollThumbEl?.current?.clientWidth;
        const percent = newScrollbarOffset / scrollbarWidth;
        if (percent * 100 >= 0 && maxWidth <= scrollbarWidth) {
          setScrollOffset(percent * 100);
          updateScrollContainer(percent * scrollContainerWidth);
        }
      }
    };

    /**
     * Activates the bar drag event.  This involves attaching
     * listeners to the window to determine if this a drag or
     * a click event
     * @param {Event} event mousedown
     */
    const activateBarDrag = (event) => {
      window.addEventListener("mousemove", handleBarDrag);
      window.addEventListener("mouseup", deactivateBarDrag);
    };

    /**
     * Deactivates the bar drag event.  This involves
     * removing the event listeners associated with it.
     * @param {Event} event mouseup event
     */
    const deactivateBarDrag = (event) => {
      window.removeEventListener("mousemove", handleBarDrag);
      window.removeEventListener("mouseup", deactivateBarDrag);
    };

    /**
     * Handles when the scrollbar is clicked, and when it is,
     * the scrollbar thumb is moved to the clicked position
     * @param {Event} event click event
     */
    const handleClick = (event) => {
      if (event && !isHoveringThumb) {
        const clickedPosition = event.clientX - event.target?.offsetLeft;
        const scrollbarWidth = event.target?.clientWidth;
        const scrollbarThumbWidth = scrollThumbEl?.current?.clientWidth;
        const scrollThumbCenter = scrollbarThumbWidth / 2;
        const percent = (clickedPosition - scrollThumbCenter) / scrollbarWidth;
        updateScrollContainer(percent * scrollContainerWidth, true);
      }
    };

    /**
     * Handles when keys are pressed on the scrollbar/thumb.
     * This involves moving the thumb left and right when the
     * arrow keys are pressed.
     * @param {Event} event keydown event
     */
    const handleKeyDown = (event) => {
      if (event) {
        let percent = null;
        switch (event.key) {
          case "ArrowLeft":
            percent = scrollOffset - 1;
            break;
          case "ArrowRight":
            percent = scrollOffset + 1;
            break;
        }
        if (percent) {
          updateScrollContainer((percent / 100) * scrollContainerWidth);
        }
      }
    };

    // hide the scrollbar if the container doesn't scroll
    if (scrollThumbWidth === 1) {
      return null;
    }

    return (
      <div className={cssClasses}>
        <div
          className="custom-scrollbar__bar"
          role="scrollbar"
          aria-controls={containerId}
          aria-orientation="horizontal"
          aria-valuemax="100"
          aria-valuemin={Math.round(scrollThumbWidth * 100)}
          aria-valuenow={Math.round(scrollOffset + scrollThumbWidth * 100)}
          tabIndex="-1"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <button
            style={{
              left: `${scrollOffset}%`,
              width: `calc(${scrollThumbWidth} * 100%)`,
            }}
            aria-roledescription="scrollbar handle"
            aria-label={`${scrollContainerTitle} section`}
            tabIndex="-1"
            ref={scrollThumbEl}
            className="custom-scrollbar__thumb"
            onMouseDown={activateBarDrag}
            onMouseOver={() => setIsHoveringThumb(true)}
            onMouseOut={() => setIsHoveringThumb(false)}
            onKeyDown={handleKeyDown}
            onFocus={() => {}} // jsx-a11y yells without it
            onBlur={() => {}} // jsx-a11y yells without it
          ></button>
        </div>
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

CustomScrollbar.propTypes = propTypes;
export default CustomScrollbar;
