/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useEffect } from "react";
/* eslint-enable */
import { func, object, array } from "prop-types";
import Select from "react-select";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";

const propTypes = {
  formValues: object,
  setFormValues: func,
  formErrors: object,
  setFormErrors: func,
  countryChanged: func,
  countries: array,
};

const DonateFormPersonal = (props) => {
  try {
    const {
      formValues,
      setFormValues,
      formErrors,
      setFormErrors,
      countries,
      countryChanged,
    } = props;

    const updatePersonalDetails = (field, value) => {
      // Reset error state when field changes, if one exists
      setFormErrors({ ...formErrors, [field]: undefined });
      // Update form object with new value
      setFormValues({ ...formValues, [field]: value });
    };

    const validateEmail = (e) => {
      // This is a relatively simplistic regex test for email addresses
      // It's not foolproof; the email spec is a monster
      // But this will prevent most obviously invalid strings from passing
      let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(e.target.value))
        setFormErrors({ ...formErrors, payer_email: true });
    };

    const onCountryChange = (evt) => {
      countryChanged(evt);
      updatePersonalDetails("payer_country", evt.key);
    };

    const setDefaultCountry = () => {
      if (countries?.length) {
        const defaultCountry = countries[0];
        return defaultCountry;
      }
      return null;
    };
    const classInput = (field) =>
      classNames("gpp-input", {
        "js-form-error": formErrors[field],
      });

    return (
      <fieldset>
        <legend className="donate-form__section-title" id="anchor--personal">
          Your personal details
        </legend>
        <div className="grid grid--50">
          <label
            className={
              "gpp-input-label grid--50" +
              (formErrors.payer_fname ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-personal-first-name"
          >
            First name
            <input
              type="text"
              name="form--donate-personal-first-name"
              id="form--donate-personal-first-name"
              className={classInput("payer_fname")}
              placeholder="Jane"
              autoComplete="given-name"
              onChange={(e) =>
                updatePersonalDetails("payer_fname", e.target.value)
              }
            />
            {formErrors.payer_fname && (
              <div className="gpp-form-error">Please enter your first name</div>
            )}
          </label>

          <label
            className={
              "gpp-input-label grid--50" +
              (formErrors.payer_lname ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-personal-last-name"
          >
            Last name
            <input
              type="text"
              name="form--donate-personal-last-name"
              id="form--donate-personal-last-name"
              className={classInput("payer_lname")}
              placeholder="Smith"
              autoComplete="family-name"
              onChange={(e) =>
                updatePersonalDetails("payer_lname", e.target.value)
              }
            />
            {formErrors.payer_lname && (
              <div className="gpp-form-error">Please enter your last name</div>
            )}
          </label>

          <label
            className={
              "gpp-input-label grid--100" +
              (formErrors.payer_email ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-personal-email"
          >
            Email address
            <input
              type="email"
              name="form--donate-personal-email"
              id="form--donate-personal-email"
              className={classInput("payer_email")}
              placeholder="janesmith@email.com"
              autoComplete="email"
              onChange={(e) =>
                updatePersonalDetails("payer_email", e.target.value)
              }
              onBlur={(e) => validateEmail(e)}
            />
            {formErrors.payer_email && (
              <div className="gpp-form-error">
                Please enter a valid email address
              </div>
            )}
          </label>
          <label
            className={
              "gpp-input-label grid--100" +
              (formErrors.payer_country ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-personal-country"
          >
            Country of residence
            <Select
              unstyled
              options={countries}
              defaultValue={setDefaultCountry()}
              onChange={(e) => onCountryChange(e)}
              autoComplete="country-name"
              classNamePrefix="gpp-react-select"
              className={`grid--100 ${
                formErrors.payer_country ? "js-form-error" : ""
              }`}
            />
            {formErrors.payer_country && (
              <div className="gpp-form-error">Please enter your country</div>
            )}
          </label>
        </div>
      </fieldset>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateFormPersonal.propTypes = propTypes;
export default DonateFormPersonal;
