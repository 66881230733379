/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useId } from "react";
/* eslint-enable */
import { Link, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { shape, string } from "prop-types";
import SkipLink from "../SharedComponents/SkipLink/SkipLink";
import { useTranslation } from "react-i18next";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    src: shape({
      value: string,
    }),
  }),
};

const IssuuViewerEmbed = (props) => {
  const randomId = useId(),
    { t } = useTranslation(),
    [skipLabel] = useState(t("Skip")),
    [endLabel] = useState(t("End")),
    [startLabel] = useState(t("Start")),
    [ofLabel] = useState(t("Of")),
    [sectionLabel] = useState(t("Section"));

  const titleValue = props?.fields?.title?.value;
  const src = props?.fields?.src?.value;
  const { downloadLink, icon } = props.fields;
  const altText = getFieldValue(props.fields, icon)?.alt || "";

  return (
    <section className="issuu-viewer-embed grid--100 margin-xlarge">
      <SkipLink
        id={`${randomId}_SKIPTOEND`}
        targetId={`${randomId}_SKIPTOSTART`}
        text={`${skipLabel} ${endLabel} ${ofLabel} ${titleValue} ${sectionLabel}`}
      />
      <div className="issuu-viewer-embed__wrapper">
        <iframe
          className="issuu-viewer-embed__iframe"
          title="placeholder"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
          allowFullScreen={true}
          src={src}
        ></iframe>
      </div>
      <SkipLink
        id={`${randomId}_SKIPTOSTART`}
        targetId={`${randomId}_SKIPTOEND`}
        text={`${skipLabel} ${startLabel} ${ofLabel} ${titleValue} ${sectionLabel}`}
      />
      <div className="issuu-viewer-embed__link-container">
        <Link
          className="btn btn--tertiary issuu-viewer-embed__link"
          field={downloadLink}
        />
        <picture className="issuu-viewer-embed__link-image">
          <Image
            className="issuu-viewer-embed__img"
            field={icon}
            alt={altText}
          />
        </picture>
      </div>
    </section>
  );
};

IssuuViewerEmbed.propTypes = propTypes;

export default IssuuViewerEmbed;
