/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import SharedDivider from "../SharedComponents/SharedDivider/SharedDivider.js";
import { object } from "prop-types";

const propTypes = {
  sitecoreContext: object,
};

const DividerLine = (props) => {
  const { sitecoreContext } = props;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  return (
    <React.Fragment>
      {isEditing && <p>General Divider Line</p>}
      <SharedDivider {...props} isArticlePage={false} />
    </React.Fragment>
  );
};

DividerLine.propTypes = propTypes;
export default withSitecoreContext()(DividerLine);
