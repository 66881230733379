/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useId } from "react";
/* eslint-enable */
import {
  getFieldValue,
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { array, object, shape, string } from "prop-types";
import classNames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import SkipLink from "../SharedComponents/SkipLink/SkipLink.js";
import CustomScrollbar from "../SharedComponents/CustomScrollbar/CustomScrollbar.js";
import { useTranslation } from "react-i18next";

const propTypes = {
  rendering: object,
  params: shape({
    isDark: string,
  }),
  fields: shape({
    title: shape({
      value: string,
    }),
  }),
  cards: array,
  sitecoreContext: object,
};

const CardCarousel = (props) => {
  const randomId = useId();
  const { t } = useTranslation();
  const [skipLabel] = useState(t("Skip"));
  const [endLabel] = useState(t("End"));
  const [startLabel] = useState(t("Start"));
  const [ofLabel] = useState(t("Of"));
  const [sectionLabel] = useState(t("Section"));
  const scrollContainer = useRef(null);

  try {
    const { params, rendering, cards, sitecoreContext, placeholders } = props;
    const isDarkBoolean = params?.isDark === "true" || params?.isDark === "1";
    const cssClass = classNames("card-carousel", { "is-dark": isDarkBoolean });
    const cssSkip = classNames("grid--100", { "btn--dark": isDarkBoolean });
    const titleValue = getFieldValue(props.fields, "title");
    const isEditing = sitecoreContext?.pageEditing;
    const contentPlaceholder =
      rendering?.placeholders?.["jss-card-carousel-content"] ||
      placeholders?.["jss-card-carousel-content"];
    const childItemsCount = contentPlaceholder?.length;
    const hasError = childItemsCount < 3 || childItemsCount > 9;

    if (!isEditing && hasError) {
      return null;
    }

    return (
      <div className={cssClass}>
        {isEditing && childItemsCount < 3 && (
          <Error {...props} errorMsg="Carousel must contain at least 3 items" />
        )}
        {isEditing && childItemsCount > 9 && (
          <Error
            {...props}
            errorMsg="Carousel must contain no more than 9 items"
          />
        )}
        <SkipLink
          id={`${randomId}_SKIPTOEND`}
          targetId={`${randomId}_SKIPTOSTART`}
          customClass={cssSkip}
          text={`${skipLabel} ${endLabel} ${ofLabel} ${titleValue} ${sectionLabel}`}
        />
        <div
          className="card-carousel__inner grid--100"
          role="group"
          aria-label={titleValue || null}
          id={`${randomId}_INNER`}
          ref={scrollContainer}
          // eslint-disable-next-line
          tabIndex="0" // allow keyboard users to focus and scroll through elements
        >
          {cards ? (
            cards
          ) : (
            <Placeholder
              name="jss-card-carousel-content"
              rendering={rendering}
            />
          )}
        </div>
        <CustomScrollbar
          customClass="grid--100"
          isDark={false}
          scrollContainer={scrollContainer}
          scrollContainerTitle={titleValue}
        />
        <SkipLink
          id={`${randomId}_SKIPTOSTART`}
          targetId={`${randomId}_SKIPTOEND`}
          customClass={cssSkip}
          text={`${skipLabel} ${startLabel} ${ofLabel} ${titleValue} ${sectionLabel}`}
        />
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

CardCarousel.propTypes = propTypes;

export default withSitecoreContext()(CardCarousel);
