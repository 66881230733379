/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, bool } from "prop-types";

const propTypes = {
  fields: object,
  isDark: bool,
};

const HeaderLogo = (props) => {
  const {
    isDark,
    fields: {
      headerLogoLight,
      headerLogoDark,
      /* eslint-disable no-unused-vars */
      headerLogoLightMobile: { value: headerLogoLightMobile } = {},
      headerLogoDarkMobile: { value: headerLogoDarkMobile } = {},
      /* eslint-enable no-unused-vars */
    },
  } = props;

  // premature optimization, in case we ever need more
  // than one mobile img source
  let sources = [];

  let logoDesktop = isDark ? headerLogoLight : headerLogoDark;
  let logoMobile = isDark
    ? getFieldValue(props.fields, "headerLogoLightMobile")
    : getFieldValue(props.fields, "headerLogoDarkMobile");

  if (logoMobile) {
    sources.push({
      src: logoMobile?.src,
      media: "(max-width: 849px)",
    });
  }

  return (
    <a className="header-logo" href="/">
      <picture className="header-logo__image">
        {sources.map((source) => {
          <source srcSet={source.src} media={source.media} />;
        })}
        <Image field={logoDesktop} alt={logoDesktop?.value?.alt || ""} />
      </picture>
    </a>
  );
};

HeaderLogo.propTypes = propTypes;
export default HeaderLogo;
