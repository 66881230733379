/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect } from "react";
/* eslint-enable */
import Vimeo from "@u-wave/react-vimeo";
import {
  getFieldValue,
  Text,
  withSitecoreContext,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import {
  setFocus,
  prefersReducedMotion,
  isInViewport,
} from "../../utils/helpers.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    video: shape({
      value: object,
    }),
    caption: shape({
      value: string,
    }),
  }),
};

/**
 * The Video component renders a Vimeo player with some caption
 * text.  It expects any kind of Vimeo url to be provided.  It
 * will take up the size of the container it is placed in.
 * @param {Object} props
 * @returns {HTMLElement} Video
 */
const Video = (props) => {
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const { t } = useTranslation();
  const videoWrapperEl = useRef(null);
  const videoEl = useRef(null);
  useEffect(() => {
    const fadeIn = () => {
      if (videoEl && isInViewport(videoEl?.current, "center") && !isInView) {
        setIsInView(true);
      }
    };
    if (typeof window !== "undefined" && !prefersReducedMotion()) {
      window.addEventListener("scroll", fadeIn);
    }
    if (isInView) {
      window.removeEventListener("scroll", fadeIn);
    }
    return () => {
      if (typeof window !== "undefined" && !prefersReducedMotion()) {
        window.removeEventListener("scroll", fadeIn);
      }
    };
  });
  try {
    const { caption, video } = props.fields,
      isEditing = props?.sitecoreContext?.pageEditing,
      videoValue = getFieldValue(props?.fields, "video"),
      cssClasses = classNames("video", {
        "is-playing": isPlaying,
        "is-poster": !isPlaying,
        "is-visible": isInView || isEditing,
      });

    /**
     * Retrieve video data such as thumnails, title, etc
     */
    const getVideoData = () => {
      return fetch(
        `https://vimeo.com/api/oembed.json?url=${videoValue?.href}`,
      ).then((response) => response.json());
    };

    /**
     * Sets the player object and gets video data
     * @param {Player} videoObject Vimeo Player Object
     */
    const setVideo = (videoObject) => {
      setVideoPlayer(videoObject);
      getVideoData().then((response) => {
        if (response) {
          setVideoData(response);
        }
      });
    };

    /**
     * Renders the thumbnail image of the video
     * @returns {HTMLElement} Thumbnail Image
     */
    const renderThumbnail = () => {
      if (!videoData) {
        if (isEditing) {
          getVideoData().then((response) => {
            if (response) {
              setVideoData(response);
            }
          });
        }
        return;
      }

      // gets image that is the size of the current container
      const containerSize = `_${videoWrapperEl?.current?.clientWidth}` || "";
      const thumbnail = videoData?.thumbnail_url?.replace(
        /_\w+/g,
        containerSize,
      );

      if (thumbnail) {
        return <img className="video__poster" src={thumbnail} alt="" />;
      } else {
        return;
      }
    };

    /** Plays the video */
    const playVideo = () => {
      if (videoPlayer && !isEditing) {
        videoPlayer?.play();
        setFocus(videoWrapperEl?.current);
        setIsPlaying(true);
      }
    };

    /** Handles when the video stops playing */
    const stopVideo = () => {
      if (videoPlayer) {
        setIsPlaying(false);
      }
    };

    /**
     * Renders the play icon
     * @param {String} altText alternative text
     * @returns {HTMLElement} Play Icon SVG
     */
    const renderPlayIcon = (altText) => {
      return (
        <svg
          className="icon"
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{altText}</title>
          <circle cx="48" cy="48" r="48" />
          <path d="M39 64V32L63 48L39 64Z" fill="white" />
        </svg>
      );
    };

    return (
      <section className={cssClasses} ref={videoEl}>
        <figure className="video__inner">
          {isEditing && (
            <Link field={video}>
              {!videoValue?.href && <p>No video source selected</p>}
              <div className="video__placeholder">
                {renderPlayIcon("Selected video will render here")}
              </div>
            </Link>
          )}
          {!!videoValue?.href && !isEditing && (
            <div className="video__wrapper">
              {!isPlaying && (
                <button className="video__play-btn" onClick={playVideo}>
                  {renderThumbnail()}
                  {renderPlayIcon(
                    `${t("Play")} ${videoData?.title} ${t("Video")}`,
                  )}
                </button>
              )}
              <div tabIndex="-1" ref={videoWrapperEl}>
                {!isEditing && (
                  <Vimeo
                    className="video__video"
                    video={videoValue?.href}
                    responsive={true}
                    showByline={false}
                    onReady={setVideo}
                    onEnd={stopVideo}
                  />
                )}
              </div>
            </div>
          )}
          {(!!caption?.value || isEditing) && (
            <figcaption className="video__caption">
              <Text className="video__copy" field={caption} tag="p" />
            </figcaption>
          )}
        </figure>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

Video.propTypes = propTypes;

export default withSitecoreContext()(Video);
