/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import classNames from "classnames";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    image: shape({
      value: object,
    }),
    opacity: shape({
      value: object,
    }),
    textColor: shape({
      value: string,
    }),
  }),
};

const SimplePageBanner = (props) => {
  const { title, image, opacity, textColor } = props.fields;
  const altText = getFieldValue(props.fields, image)?.alt || "";
  /**
   * Determines if the given value is a valid value for the css opacity property.
   * Returns an empty string if invalid.
   * @param {String} opacityValue
   * @returns {String} the opacity value if valid
   */
  const getOpacity = (opacityValue) => {
    opacityValue =
      typeof opacityValue === "object" &&
      getFieldValue(opacityValue?.fields, "opacity")
        ? getFieldValue(opacityValue?.fields, "opacity")
        : opacityValue;
    const isValidOpacity =
      !isNaN(opacityValue) && opacityValue <= 1 && opacityValue >= 0;
    return isValidOpacity ? opacityValue : "";
  };

  const cssClasses = classNames("simple-banner", {
    "has-opacity": getOpacity(opacity),
  });

  /* eslint-disable */
  console.log(textColor?.value || "inherit");
  /* eslint-enable */
  return (
    <section className={cssClasses}>
      <div className="simple-banner__image-section">
        <picture className="simple-banner__image">
          <Image className="img" field={image} alt={altText} />
        </picture>
        <div
          className="simple-banner__screen"
          style={{ opacity: getOpacity(opacity) }}
        ></div>
      </div>
      <div className="simple-banner__copy-section">
        <div className="simple-banner__copy grid--100">
          <Text
            style={{ color: textColor?.value || "inherit" }}
            className="simple-banner__title"
            tag="h1"
            field={title}
          />
        </div>
      </div>
    </section>
  );
};

SimplePageBanner.propTypes = propTypes;

export default SimplePageBanner;
