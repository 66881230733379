/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useEffect, useState, useRef } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import FocusLock from "react-focus-lock";
import { shape, object } from "prop-types";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    source: object,
  }),
};

const Iframe = (props) => {
  const { source } = props.fields;
  const isEditing = props.sitecoreContext?.pageEditing;

  /**
   * Handles iframe events
   * @param {Event} e
   * @returns {VoidFunction}
   */
  const handleFrameTasks = (e) => {
    const data = typeof e.data === "object" ? e.data : JSON.parse(e.data);

    if (data.scrollEvt) {
      if (window.scrollY > 0)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
  };
  useEffect(() => {
    try {
      window.addEventListener("message", handleFrameTasks, false);
      return () => {
        window.removeEventListener("message", handleFrameTasks);
      };
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  }, []);

  return (
    <>
      {source?.value && (
        <FocusLock disabled={isEditing} className="donate-modal__inner">
          <iframe
            className="gpp-iframe"
            style={{ height: "100vh", width: "100%" }}
            title={source.value.text}
            src={source.value.href}
          ></iframe>
        </FocusLock>
      )}
    </>
  );
};

Iframe.propTypes = propTypes;

export default withSitecoreContext()(Iframe);
