/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { bool, object } from "prop-types";

import FooterDonationBar from "../FooterDonationBar/FooterDonationBar";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";
import FooterLogo from "../FooterLogo/FooterLogo";
import FooterCopyright from "../FooterCopyright/FooterCopyright";
import FooterNavigationLinks from "../FooterNavigationLinks/FooterNavigationLinks";

const propTypes = {
  rendering: object,
  sitecoreContext: object,
  isSimple: bool,
};

const GlobalFooter = (props) => {
  const {
    donationBar = {},
    footerLogo = {},
    footerCopyright = {},
    newsletterSignup = {},
    donate = {},
  } = props.sitecoreContext?.globalConfiguration || {};
  const hideDonate =
    props.sitecoreContext?.route.fields.hideDonateBanner?.value;
  const donateText = props.sitecoreContext?.route.fields.donateText;
  const donateFields = { ...donationBar, ...donate, donateText };
  return (
    <>
      {!hideDonate && <FooterDonationBar fields={donateFields} />}
      <footer className="global-footer grid grid--100">
        <FooterLogo fields={footerLogo} />
        {!props?.isSimple && <NewsletterSignup fields={newsletterSignup} />}
        <FooterCopyright fields={footerCopyright} />
        {!props?.isSimple && <FooterNavigationLinks />}
      </footer>
    </>
  );
};

GlobalFooter.propTypes = propTypes;

export default withSitecoreContext()(GlobalFooter);
