/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, {useState} from "react";
/* eslint-enable */
import { func, object } from "prop-types";
import classNames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  formValues: object,
  setFormValues: func,
  formErrors: object,
  setFormErrors: func,
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const DonateFormAmount = (props) => {
  const [customDonation, setCustomDonation] = useState("");

  try {
    const { formValues, setFormValues, formErrors, setFormErrors } = props;

    const handleCustomAmountChange = (event) => {
      // decimal number only
      let value = event.target.value.replace(/[^0-9.]/g, "");

      // Clear any selected radio values
      Array.from(
        document?.querySelectorAll("input[name=form--donate-amount]") || [],
      ).map((button) => (button.checked = false));

      // Clear error messages
      setFormErrors({
        ...formErrors,
        amount_selected: undefined,
        custom_amount_min: undefined,
        custom_amount_max: undefined,
      });

      // **** Validate the Value **** //
      // record empty field and skip validation steps
      if (value === "") {
        setCustomDonation(value);
        setFormValues({ ...formValues, amount_selected: value });
        return;
      }

      // warn if value is too small
      if (value > 0 && value < 10) {
        setFormErrors({
          ...formErrors,
          custom_amount_min: true,
        });
      }

      // warn and ignore entry if the number is too large
      if (value > 100000) {
        setFormErrors({
          ...formErrors,
          custom_amount_max: true,
        });
        return;
      }

      const decimalLocation = value.indexOf(".");
      // ignore extra decimal places
      if (decimalLocation !== -1 && value.length - decimalLocation > 3) {
        return;
      }

      // **** Validation has passed **** //

      // **** Record the Value and update the input **** //
      // convert value back to a currency.
      const formattedValue = formatter.format(value);

      // save value minus the formatting characters
      // and keeping the extra decimal places added `.00`
      setFormValues({
        ...formValues,
        amount_selected: formattedValue.replace(/[^0-9.]/g, ""),
      });

      // format value for display in the input
      const sliceValue =
        decimalLocation === -1
          ? -3
          : (3 + decimalLocation - value.length) * -1 + 0; // '+ 0' converts -0 to 0

      const displayValue =
        sliceValue < 0 ? formattedValue.slice(0, sliceValue) : formattedValue;
      setCustomDonation(value > 0 ? displayValue : "");
    };

    const handleBlur = (event) => {
      let value = event.target.value.replace(/[^0-9.]/g, "");
      // record empty field and skip validation steps
      if (value === "") {
        return;
      }

      const currency = formatter.format(value);
      setCustomDonation(value > 0 ? currency : "");
    };

    const updateAmount = (e) => {
      setFormErrors({
        ...formErrors,
        amount_selected: undefined,
        custom_amount_min: undefined,
        custom_amount_max: undefined,
      });
      setCustomDonation("");
      setFormValues({ ...formValues, amount_selected: e.target?.value });
    };

    const classInput = classNames("gpp-hidden-radio", {
      "js-form-error": formErrors.amount_selected,
    });

    return (
      <fieldset
        className={formErrors.amount_selected && "gpp-input-label--error"}
        aria-describedby={`${
          formErrors.amount_selected
            ? "GPP-FORM_AMOUNT_ERROR_NONE-SELECTED"
            : ""
        }`}
      >
        <legend className="donate-form__section-title">
          How much would you like to donate?
        </legend>
        <div role="radiogroup" className="grid">
          <input
            type="radio"
            name="form--donate-amount"
            id="form--donate-amount-10"
            className={classInput}
            value="10"
            onChange={(e) => updateAmount(e)}
          />
          <label
            className={
              "gpp-radio-label" +
              (formErrors.amount_selected ? " gpp-radio-label--error" : "")
            }
            htmlFor="form--donate-amount-10"
          >
            $10
          </label>

          <input
            type="radio"
            name="form--donate-amount"
            id="form--donate-amount-25"
            className={classInput}
            value="25"
            onChange={(e) => updateAmount(e)}
          />
          <label
            className={
              "gpp-radio-label" +
              (formErrors.amount_selected ? " gpp-radio-label--error" : "")
            }
            htmlFor="form--donate-amount-25"
          >
            $25
          </label>

          <input
            type="radio"
            name="form--donate-amount"
            id="form--donate-amount-50"
            className={classInput}
            value="50"
            onChange={(e) => updateAmount(e)}
          />
          <label
            className={
              "gpp-radio-label" +
              (formErrors.amount_selected ? " gpp-radio-label--error" : "")
            }
            htmlFor="form--donate-amount-50"
          >
            $50
          </label>

          <input
            type="radio"
            name="form--donate-amount"
            id="form--donate-amount-100"
            className={classInput}
            value="100"
            onChange={(e) => updateAmount(e)}
          />
          <label
            className={
              "gpp-radio-label" +
              (formErrors.amount_selected ? " gpp-radio-label--error" : "")
            }
            htmlFor="form--donate-amount-100"
          >
            $100
          </label>

          <input
            type="radio"
            name="form--donate-amount"
            id="form--donate-amount-500"
            className={classInput}
            value="500"
            onChange={(e) => updateAmount(e)}
          />
          <label
            className={
              "gpp-radio-label" +
              (formErrors.amount_selected ? " gpp-radio-label--error" : "")
            }
            htmlFor="form--donate-amount-500"
          >
            $500
          </label>

          <input
            type="text"
            className={
              "gpp-labelless-input" +
              (formErrors.amount_selected ??
              formErrors.custom_amount_min ??
              formErrors.custom_amount_max
                ? " gpp-labelless-input--error js-form-error"
                : "")
            }
            aria-describedby={`${
              formErrors.custom_amount_max ? "GPP-FORM_AMOUNT_ERROR_LESS " : ""
            }${
              formErrors.custom_amount_min
                ? "GPP-FORM_AMOUNT_ERROR_GREATER "
                : ""
            }`}
            id="form--donate-amount-custom"
            placeholder="Enter amount"
            value={customDonation}
            onChange={handleCustomAmountChange}
            onBlur={handleBlur}
          />
        </div>
        {formErrors.amount_selected && (
          <div
            className="gpp-form-error"
            id="GPP-FORM_AMOUNT_ERROR_NONE-SELECTED"
          >
            Please select a donation amount
          </div>
        )}
        {formErrors.custom_amount_min && (
          <div className="gpp-form-error" id="GPP-FORM_AMOUNT_ERROR_GREATER">
            Please enter an amount greater than $10
          </div>
        )}
        {formErrors.custom_amount_max && (
          <div className="gpp-form-error" id="GPP-FORM_AMOUNT_ERROR_LESS">
            Please enter an amount less than $100,000
          </div>
        )}
      </fieldset>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateFormAmount.propTypes = propTypes;
export default DonateFormAmount;
