/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useId } from "react";
/* eslint-enable */
import {
  Text,
  Image,
  getFieldValue,
  DateField,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../ErrorHandling/Error.js";
import classNames from "classnames/bind";
import { formatDate, getDateField } from "../../../utils/helpers.js";

const propTypes = {
  fields: object,
  layout: object,
};

/**
 * An article card is a preview of an article page
 * that is used to promote the article on different pages.
 * It is not meant to be used as a stand-alone and can
 * commonly be found in an Article Cards component
 * @param {Object} props
 * @returns {HTMLElement} Article Card
 */
const ArticleCard = (props) => {
  const id = useId();
  try {
    const {
      originalDate,
      displayDate,
      headerTitle,
      summary,
      colorTheme,
      bannerType,
      listingFeatureImage,
    } = props.fields;

    const url = props?.url || "/";

    /**
     * Gets the page banner data if any
     * @returns {Object} page banner datasource object
     */
    const getPageBannerType = () => {
      return getFieldValue(bannerType?.fields, "bannerType");
    };

    const { dateField, dateString } = getDateField(displayDate, originalDate),
      primaryColor =
        getPageBannerType() === "circle"
          ? getFieldValue(colorTheme?.fields?.primary?.fields, "color")
          : null,
      cssClasses = classNames("article-card", {
        "is-circle": getPageBannerType() === "circle",
        "is-fullbleed": getPageBannerType() === "full-bleed",
        "is-5050": getPageBannerType() === "50-50",
      });

    return (
      <a
        href={url}
        className={cssClasses}
        aria-labelledby={`${id}_TITLE`}
        aria-describedby={`${id}_DATE ${id}_SUMMARY`}
      >
        <div
          className="article-card__image-section"
          style={{ backgroundColor: primaryColor }}
        >
          <picture className="article-card__image">
            <Image className="img" field={listingFeatureImage} alt="" />
          </picture>
        </div>
        <div className="article-card__copy-section">
          <div className="article-card__copy">
            <DateField
              className="article-card__date"
              field={dateField}
              id={`${id}_DATE`}
              tag="p"
              render={() => formatDate(dateString)}
            />
            <Text
              className="article-card__title"
              field={headerTitle}
              tag="h3"
              id={`${id}_TITLE`}
            />
            {getFieldValue(props.fields, "summary")?.value}
            <Text
              className="article-card__description"
              field={summary}
              tag="p"
              id={`${id}_SUMMARY`}
            />
          </div>
        </div>
      </a>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticleCard.propTypes = propTypes;

export default withSitecoreContext()(ArticleCard);
