/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect, useId } from "react";
/* eslint-enable */
import { Text, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import { prefersReducedMotion, isInViewport } from "../../utils/helpers.js";
import Error from "../SharedComponents/ErrorHandling/Error";
import { useTranslation } from "react-i18next";

const propTypes = {
  fields: shape({
    image: shape({
      value: object,
    }),
    video: shape({
      value: object,
    }),
    text: shape({
      value: string,
    }),
    caption: shape({
      value: string,
    }),
  }),
};

const FeatureCircleMedia = (props) => {
  const [isPlaying, setIsPlaying] = useState(!prefersReducedMotion()),
    { t } = useTranslation(),
    [playLabel] = useState(t("Play")),
    [pauseLabel] = useState(t("Pause")),
    [videoLabel] = useState(t("Video")),
    [featuredLabel] = useState(t("Featured")),
    [canUseMotion] = useState(!prefersReducedMotion()),
    id = useId(),
    videoEl = useRef(null),
    mediaContainer = useRef(null);

  // sitecore throwing an error, so adding a check
  const windowObj = typeof window === "undefined" ? {} : window;

  useEffect(() => {
    /**
     * Scales the media based on scroll percentage
     */
    const growOnScroll = () => {
      const elContainer = mediaContainer?.current;
      const shouldScale =
        elContainer &&
        typeof window !== "undefined" &&
        isInViewport(elContainer, "height");
      if (shouldScale) {
        const percent =
          (windowObj?.innerHeight - elContainer.getBoundingClientRect().top) /
          windowObj?.innerHeight;
        const scale = 1 + percent * 0.125;
        if (videoEl?.current) {
          videoEl?.current?.setAttribute(
            "style",
            `transform: scale(${scale}) translateY(${percent * 10}%)`,
          );
        } else if (elContainer?.querySelector(".img")) {
          elContainer
            ?.querySelector(".img")
            ?.setAttribute(
              "style",
              `transform: scale(${scale}) translateY(${percent * 5 - 5}%)`,
            );
        }
      }
    };

    if (typeof window !== "undefined" && canUseMotion) {
      window.addEventListener("scroll", growOnScroll);
    }
    return () => {
      if (typeof window !== "undefined" && canUseMotion) {
        window.removeEventListener("scroll", growOnScroll);
      }
    };
  });

  try {
    const { text, image, caption } = props.fields;
    const videoField = getFieldValue(props.fields, "video");
    const srcUrl = videoField?.src;
    const hasMedia = srcUrl || image?.value?.src;
    const altText =
      getFieldValue(props.fields, "image")?.alt ||
      getFieldValue(props.fields, "caption") ||
      "";
    const isUsingCaption =
      getFieldValue(props.fields, "caption") &&
      altText === getFieldValue(props.fields, "caption");

    /**
     * Pauses or plays the video element based on
     * its current state
     * @param {HTMLMediaElement} videoEl video element
     */
    const controlVideo = (videoEl) => {
      if (videoEl) {
        videoEl.paused ? videoEl.play() : videoEl.pause();
      }
    };

    return (
      <section className="feature-circle">
        <figure className="feature-circle__inner grid--100">
          {hasMedia && (
            <div className="feature-circle__media-section" ref={mediaContainer}>
              {srcUrl ? (
                <div className="feature-circle__media">
                  <video
                    className="vid"
                    muted
                    id={`${id}_VIDEO`}
                    aria-hidden="true"
                    aria-label={videoField?.title || featuredLabel}
                    ref={videoEl}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    controlsList="nodownload nofullscreen noremoteplayback"
                    disablePictureInPicture
                    autoPlay={canUseMotion ? true : null}
                    loop
                  >
                    <source src={srcUrl} type="video/mp4" />
                    <Image className="img" field={image} alt={altText} />
                  </video>
                  <button
                    aria-live="assertive"
                    id={`${id}_VIDEO_CONTROL_BTN`}
                    aria-controls={`${id}_VIDEO`}
                    className="feature-circle__control-btn tab-only"
                    onClick={() => controlVideo(videoEl?.current)}
                  >
                    {isPlaying ? (
                      <svg className="icon" viewBox="0 0 32 32">
                        <title>
                          {pauseLabel} {videoField?.title || featuredLabel}{" "}
                          {videoLabel}
                        </title>
                        <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM10 10h4v12h-4zM18 10h4v12h-4z"></path>
                      </svg>
                    ) : (
                      <svg className="icon" viewBox="0 0 32 32">
                        <title>
                          {playLabel} {videoField?.title || featuredLabel}{" "}
                          {videoLabel}
                        </title>
                        <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z"></path>
                      </svg>
                    )}
                  </button>
                </div>
              ) : (
                <picture className="feature-circle__media">
                  <Image className="img" field={image} alt={altText} />
                </picture>
              )}
            </div>
          )}
          <div className="feature-circle__copy-section">
            <div className="feature-circle__copy">
              <Text className="feature-circle__text" field={text} tag="p" />
              <Text
                className="feature-circle__caption"
                field={caption}
                aria-hidden={isUsingCaption}
                tag="figcaption"
              />
            </div>
          </div>
        </figure>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FeatureCircleMedia.propTypes = propTypes;

export default FeatureCircleMedia;
