/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import PrimaryNav from "../PrimaryNav/PrimaryNav";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import GlobalHeaderCTA from "../GlobalHeaderCTA/GlobalHeaderCTA";
import CountrySelector from "../CountrySelector/CountrySelector";
import { shape, object, bool } from "prop-types";
import classnames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  rendering: object,
  fields: shape({
    isDark: shape({
      value: bool,
    }),
    isOverlay: shape({
      value: bool,
    }),
  }),
};

/**
 * The main header component that should exist at the
 * top of the page houses the primary nav
 * @param {Object} props
 * @returns {HTMLElement} Global Header
 */
const GlobalHeader = (props) => {
  /*
    The Global Header has 3 interactivity states, depending on the user's
    scroll behavior:
      NORMAL
        The header displays in its normal position. When the user scrolls, the
        Global Header maintains its visual position at the top of the page and
        scrolls away.
      REVEAL - SOLID OVERLAY
        When the user has scrolled down the page and then scrolls back up, the
        Global Header should "reveal" itself and become sticky at the top of the
        viewport. It should be styled as "light mode" with dark text on a solid
        white background, overlaid on top of content as the user scrolls. When
        the user scrolls back down the page, the Global Header should hide
        itself again. (This is similar to how Safari for iOS handles its UI
        elements; appearing on scroll-up, disappearing on scroll-down.)
      REVEAL - TRANSPARENT OVERLAY
        When the user scrolls up but the Homepage Hero is visible, the styling of
        the banner should change to "dark mode" with light text on a transparent
        background to match the default styling. Once the user scrolls to the
        very top of the page, the Global Header should "reattach" itself and
        revert to Normal behavior.
  */

  if (props?.sitecoreContext?.pageEditing) {
    return <GlobalHeaderRender {...props} />;
  } else {
    return <GlobalHeaderHooked {...props} />;
  }
};

const GlobalHeaderHooked = (props) => {
  // sitecore throwing an error, so adding a check
  const windowObj = typeof window === "undefined" ? {} : window;

  // Is the scroll position such that the Global Header be affixed if applicable?
  const [shouldAffix, setShouldAffix] = useState(false);
  // Stores the previous scroll depth, to compare against and determine direction
  const [lastScrollPos, setLastScrollPos] = useState(0);
  // Stashing the calculated scroll direction for reference outside event trigger
  const [scrollDirectionUp, setScrollDirectionUp] = useState(false);

  useEffect(() => {
    const $globalHeader = document?.querySelector(".global-header");
    const handleScrollEvent = (newScrollPos) => {
      if (!$globalHeader) return;
      let isScrollingUp = newScrollPos < lastScrollPos;
      let scrolledPastHeader = $globalHeader.offsetHeight < newScrollPos;

      // The Header should be affixed if:
      //  (a) the Header is scrolled past / off-screen, and;
      //  (b) the user is scrolling down the page
      if (scrolledPastHeader && !isScrollingUp) {
        setShouldAffix(scrolledPastHeader);
      } else if (newScrollPos <= 0) {
        setShouldAffix(false);
      }

      // stashing calculated values from scroll event
      setScrollDirectionUp(isScrollingUp);
      setLastScrollPos(newScrollPos);
    };

    window.addEventListener("scroll", () => {
      handleScrollEvent(window.scrollY);
    });

    // for the Iframe component—which assumes a full-screen "takeover"—we also
    // need to listen for scroll position messages, since the outer parent won't
    // fire any `scroll` events when scrolling inside the iframe.
    window.addEventListener(
      "message",
      (e) => {
        if (e.data === "recaptcha-setup") return;

        const data = typeof e.data === "object" ? e.data : JSON.parse(e.data);
        if (data.scrollEvt) {
          handleScrollEvent(data.scrollEvt);
        }
      },
      false,
    );
  });

  return (
    <GlobalHeaderRender
      {...props}
      shouldAffix={shouldAffix}
      setShouldAffix={setShouldAffix}
      lastScrollPos={lastScrollPos}
      setLastScrollPos={setLastScrollPos}
      scrollDirectionUp={scrollDirectionUp}
      setScrollDirectionUp={setScrollDirectionUp}
      windowObj={windowObj}
    />
  );
};

const GlobalHeaderRender = (props) => {
  try {
    const {
      fields: {
        isDark: { value: isDark } = {},
        isOverlay: { value: isOverlay } = {},
      },
      shouldAffix,
      lastScrollPos,
      scrollDirectionUp,
      windowObj,
      sitecoreContext,
    } = props;
    const { headerLogo, headerCTA, donate } =
      sitecoreContext?.globalConfiguration;
    const isDonate = sitecoreContext?.isDonate;

    const hasClearedHeroCTA = () =>
      lastScrollPos < windowObj?.innerHeight - 230;

    const parentClasses = classnames("global-header", {
      // applies styles when header is on top of page hero/banner
      "global-header--overlay": isOverlay,
      // prepares animations and styling for affixed (sticky) banner
      "global-header--should-affix": shouldAffix,
      // displays affixed (sticky) banner when applicable
      "global-header--affixed": shouldAffix && scrollDirectionUp,
      "is-dark": isDark && (!shouldAffix || hasClearedHeroCTA()),
    });

    const innerClasses = classnames("global-header__inner", "grid--100", {});
    const headerFields = { ...headerCTA, ...donate };

    return (
      <header className={parentClasses}>
        <div className={innerClasses}>
          {!!headerLogo && (
            <HeaderLogo
              isDark={isDark && (!shouldAffix || hasClearedHeroCTA())}
              fields={headerLogo}
            />
          )}
          <PrimaryNav
            isMobile={false}
            isDark={isDark && (!shouldAffix || hasClearedHeroCTA())}
          />
          {!!headerCTA && !isDonate && (
            <GlobalHeaderCTA fields={headerFields} />
          )}
          {!!isDonate && <CountrySelector />}
          <PrimaryNav
            key="mobilePrimaryNav"
            isMobile={true}
            isDark={isDark && (!shouldAffix || hasClearedHeroCTA())}
          />
        </div>
      </header>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

GlobalHeader.propTypes = propTypes;
export default withSitecoreContext()(GlobalHeader);
