/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { shape, object } from "prop-types";

const propTypes = {
  fields: shape({
    heading: object,
    cta: object,
  }),
};

const DonatePageBanner = (props) => {
  const { heading, cta } = props.fields;
  return (
    <section className="donate-page-banner">
      <div className="grid">
        <Text
          tag="h1"
          className="donate-page-banner__heading"
          field={heading}
        />
        <RichText className="donate-page-banner__cta" field={cta} />
      </div>
    </section>
  );
};

DonatePageBanner.propTypes = propTypes;

export default DonatePageBanner;
