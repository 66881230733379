/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { func, object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";

const propTypes = {
  formValues: object,
  setFormValues: func,
  formErrors: object,
  setFormErrors: func,
};

const DonateFormTowards = (props) => {
  try {
    const { formValues, setFormValues, formErrors, setFormErrors } = props;

    const updateTowards = (e) => {
      setFormErrors({ ...formErrors, gpp_fund: undefined });
      setFormValues({ ...formValues, gpp_fund: e.target.value });
    };
    const classInput = classNames("gpp-hidden-radio", {
      "js-form-error": formErrors.gpp_fund,
    });

    return (
      <fieldset className={formErrors.gpp_fund && "gpp-input-label--error"}>
        <legend className="donate-form__section-title">
          What fund would you like your donation to go towards?
        </legend>
        <div role="radiogroup" className="grid">
          <input
            type="radio"
            name="form--donate-towards"
            id="form--donate-towards-problems"
            className={classInput}
            value="impact"
            onChange={(e) => updateTowards(e)}
          />
          <label
            className="gpp-decorative-radio-label"
            htmlFor="form--donate-towards-problems"
          >
            <svg
              className="gpp-decorative-radio-label__image"
              viewBox="0 0 181 97"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity=".4"
                d="M0 6a6 6 0 016-6h169a6 6 0 016 6v90.467H0V6z"
                fill="#FFEC40"
              />
              <mask
                id="a"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="181"
                height="97"
              >
                <path d="M0 0h181v96.467H0V0z" fill="#FFEC40" />
              </mask>
              <g mask="url(#a)" fill="#FFEC40">
                <ellipse cx="18.1" cy="24.118" rx="24.133" ry="24.117" />
                <ellipse cx="18.1" cy="72.35" rx="24.133" ry="24.117" />
                <ellipse cx="66.368" cy="24.117" rx="24.133" ry="24.117" />
                <ellipse cx="66.368" cy="72.35" rx="24.133" ry="24.117" />
                <ellipse cx="114.633" cy="72.35" rx="24.133" ry="24.117" />
                <ellipse cx="114.633" cy="24.117" rx="24.133" ry="24.117" />
                <ellipse cx="162.901" cy="24.117" rx="24.133" ry="24.117" />
                <ellipse cx="162.901" cy="72.35" rx="24.133" ry="24.117" />
              </g>
            </svg>

            <span className="gpp-decorative-radio-label__text">
              Must-Solve Problems
            </span>
          </label>

          <input
            type="radio"
            name="form--donate-towards"
            id="form--donate-towards-frontiers"
            className={classInput}
            value="frontiers"
            onChange={(e) => updateTowards(e)}
          />
          <label
            className="gpp-decorative-radio-label"
            htmlFor="form--donate-towards-frontiers"
          >
            <svg
              viewBox="0 0 181 96"
              fill="none"
              className="gpp-decorative-radio-label__image"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="a"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="181"
                height="96"
              >
                <path
                  d="M0 6a6 6 0 016-6h169a6 6 0 016 6v90H0V6z"
                  fill="#C4C4C4"
                />
              </mask>
              <g mask="url(#a)">
                <path opacity=".4" fill="#FFEC40" d="M0-1h181v97H0z" />
                <mask
                  id="b"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="-1"
                  width="182"
                  height="97"
                >
                  <path fill="#FFF7B3" d="M.1-1H181v96.8H.1z" />
                </mask>
                <g mask="url(#b)">
                  <path
                    d="M18.9 10.5H10l-7.2 5-2.7 8 2.7 8.1 7.2 5H19l7.1-5 2.8-8-2.8-8-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M18.9 10.5H10l-7.2 5-2.7 8 2.7 8.1 7.2 5H19l7.1-5 2.8-8-2.8-8-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M18.9 38.7H10l-7.2 5-2.7 8 2.7 8.1 7.2 5H19l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M18.9 38.7H10l-7.2 5-2.7 8 2.7 8.1 7.2 5H19l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M18.9 66.9H10l-7.2 5-2.7 8L2.8 88l7.2 5H19l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M18.9 66.9H10l-7.2 5-2.7 8L2.8 88l7.2 5H19l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M18.9-17.7H10l-7.2 5L.1-4.6l2.7 8 7.2 5H19l7.1-5 2.8-8-2.8-8-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M18.9-17.7H10l-7.2 5L.1-4.6l2.7 8 7.2 5H19l7.1-5 2.8-8-2.8-8-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M49.6 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M49.6 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M49.6 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M49.6 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M49.6 66.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M49.6 66.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.1-5 2.8-8-2.8-8.1-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M49.6-17.8h-8.9l-7.1 5-2.8 8 2.8 8.2 7.1 5h8.9l7.1-5 2.8-8.1-2.8-8-7.1-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M49.6-17.8h-8.9l-7.1 5-2.8 8 2.8 8.2 7.1 5h8.9l7.1-5 2.8-8.1-2.8-8-7.1-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M80.3 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M80.3 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M80.3 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M80.3 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M80.3 66.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M80.3 66.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M80.3-17.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M80.3-17.8h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M111 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M111 10.4h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M111 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M111 38.6h-8.9l-7.1 5-2.8 8 2.8 8.1 7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M111 66.8h-8.9l-7.1 5-2.8 8L95 88l7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M111 66.8h-8.9l-7.1 5-2.8 8L95 88l7.1 5h8.9l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M111-17.8h-8.9l-7.1 5-2.8 8L95 3.4l7.1 5h8.9l7.2-5 2.7-8-2.7-8-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M111-17.8h-8.9l-7.1 5-2.8 8L95 3.4l7.1 5h8.9l7.2-5 2.7-8-2.7-8-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M141.7 10.4H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M141.7 10.4H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M141.7 38.6H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M141.7 38.6H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M141.7 66.8H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M141.7 66.8H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M141.7-17.8H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M141.7-17.8H133l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M172.4 10.4h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M172.4 10.4h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M172.4 38.6h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M172.4 38.6h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M172.4 66.8h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M172.4 66.8h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8.1-7.2-5z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M172.4-17.8h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8-7.2-5z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M172.4-17.8h-8.8l-7.2 5-2.7 8 2.7 8.1 7.2 5h8.8l7.2-5 2.7-8-2.7-8-7.2-5z"
                    stroke="#FFF7B3"
                  />
                </g>
              </g>
            </svg>

            <span className="gpp-decorative-radio-label__text">
              Frontiers: HIV & Sickle Cell Disease
            </span>
          </label>

          <input
            type="radio"
            name="form--donate-towards"
            id="form--donate-towards-empower"
            className={classInput}
            value="empowerment"
            onChange={(e) => updateTowards(e)}
          />
          <label
            className="gpp-decorative-radio-label"
            htmlFor="form--donate-towards-empower"
          >
            <svg
              viewBox="0 0 181 96"
              fill="none"
              className="gpp-decorative-radio-label__image"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="a"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="181"
                height="96"
              >
                <path
                  d="M0 6a6 6 0 0 1 6-6h169a6 6 0 0 1 6 6v90H0V6z"
                  fill="#C4C4C4"
                />
              </mask>
              <g mask="url(#a)">
                <path fill="#FFF7B3" d="M-4-.3h188.1V100H-4z" />
                <mask
                  id="b"
                  maskUnits="userSpaceOnUse"
                  x="-4"
                  y="-1"
                  width="189"
                  height="101"
                >
                  <path fill="#FFF7B3" d="M-4-.3h188.1V100H-4z" />
                </mask>
                <g mask="url(#b)">
                  <path
                    d="M152.8-12.7c-17.3 0-31.4 14-31.4 31.2h62.7c0-17.3-14-31.2-31.3-31.2z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M152.8-12.7c-17.3 0-31.4 14-31.4 31.2h62.7c0-17.3-14-31.2-31.3-31.2z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M27.4-12.7C10-12.7-4 1.3-4 18.5h62.7c0-17.3-14-31.2-31.3-31.2z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M27.4-12.7C10-12.7-4 1.3-4 18.5h62.7c0-17.3-14-31.2-31.3-31.2z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M152.8 18.5c-17.3 0-31.4 14-31.4 31.3h62.7c0-17.3-14-31.3-31.3-31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M152.8 18.5c-17.3 0-31.4 14-31.4 31.3h62.7c0-17.3-14-31.3-31.3-31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M27.4 18.5C10 18.5-4 32.5-4 49.8h62.7c0-17.3-14-31.3-31.3-31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M27.4 18.5C10 18.5-4 32.5-4 49.8h62.7c0-17.3-14-31.3-31.3-31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M90 81.2c17.4 0 31.4-14 31.4-31.4H58.7c0 17.4 14 31.4 31.4 31.4z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M90 81.2c17.4 0 31.4-14 31.4-31.4H58.7c0 17.4 14 31.4 31.4 31.4z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M90 112.5c17.4 0 31.4-14 31.4-31.3H58.7c0 17.3 14 31.3 31.4 31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M90 112.5c17.4 0 31.4-14 31.4-31.3H58.7c0 17.3 14 31.3 31.4 31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M152.8 49.8c-17.3 0-31.4 14-31.4 31.4h62.7c0-17.3-14-31.4-31.3-31.4z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M152.8 49.8c-17.3 0-31.4 14-31.4 31.4h62.7c0-17.3-14-31.4-31.3-31.4z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M152.8 81.2c-17.3 0-31.4 14-31.4 31.3h62.7c0-17.3-14-31.3-31.3-31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M152.8 81.2c-17.3 0-31.4 14-31.4 31.3h62.7c0-17.3-14-31.3-31.3-31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M27.4 49.8C10 49.8-4 63.8-4 81.2h62.7c0-17.3-14-31.4-31.3-31.4z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M27.4 49.8C10 49.8-4 63.8-4 81.2h62.7c0-17.3-14-31.4-31.3-31.4z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M27.4 81.2C10 81.2-4 95.2-4 112.5h62.7c0-17.3-14-31.3-31.3-31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M27.4 81.2C10 81.2-4 95.2-4 112.5h62.7c0-17.3-14-31.3-31.3-31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M90 49.8c17.4 0 31.4-14 31.4-31.3H58.7c0 17.3 14 31.3 31.4 31.3z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M90 49.8c17.4 0 31.4-14 31.4-31.3H58.7c0 17.3 14 31.3 31.4 31.3z"
                    stroke="#FFF7B3"
                  />
                  <path
                    d="M90 18.5c17.4 0 31.4-14 31.4-31.4H58.7c0 17.3 14 31.4 31.4 31.4z"
                    fill="#FFEC40"
                  />
                  <path
                    d="M90 18.5c17.4 0 31.4-14 31.4-31.4H58.7c0 17.3 14 31.4 31.4 31.4z"
                    stroke="#FFF7B3"
                  />
                </g>
              </g>
            </svg>
            <span className="gpp-decorative-radio-label__text">
              Empowerment & Opportunity
            </span>
          </label>
        </div>
        {formErrors.gpp_fund && (
          <div className="gpp-form-error">Please select a fund</div>
        )}
      </fieldset>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateFormTowards.propTypes = propTypes;
export default DonateFormTowards;
