/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = { sitecoreContext: object };

/**
 * The Invisible H1 pulls from the page level information
 * and renders the page title of the page in a hidden h1.
 * @param {Object} props
 * @returns {HTMLElement} InvisibleH1
 */
const InvisibleH1 = (props) => {
  try {
    const { sitecoreContext } = props;
    const isEditing = !!sitecoreContext && sitecoreContext.pageEditing;

    const title =
      getFieldValue(sitecoreContext?.route?.fields, "pageTitle") ||
      getFieldValue(sitecoreContext?.route?.fields, "headerTitle") ||
      sitecoreContext?.route?.displayName ||
      sitecoreContext?.route?.name;

    return (
      <React.Fragment>
        {isEditing && <p>Invisible H1:</p>}
        {title && (
          <h1 className={`${isEditing ? "" : "u-visuallyhidden"}`}>{title}</h1>
        )}
      </React.Fragment>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

InvisibleH1.propTypes = propTypes;

export default withSitecoreContext()(InvisibleH1);
