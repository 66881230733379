/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, string, object } from "prop-types";
import HomepageHero from "../HomepageHero/HomepageHero";

const propTypes = {
  fields: shape({
    heading: string,
  }),
  sitecoreContext: object,
  rendering: object,
};

const HomepageHeroRandomizer = (props) => {
  const { sitecoreContext } = props;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const [heroes, setHeroes] = useState([]);
  const [randomHeroIndex, setRandomHeroIndex] = useState(0);

  useEffect(() => {
    let heroes = props?.rendering?.placeholders["jss-homepage-heroes"];
    setHeroes(heroes);
    // Store in state to prevent re-rolling the dice if component re-renders
    setRandomHeroIndex(Math.floor(Math.random() * heroes.length));
  }, [props]);

  if (isEditing) {
    return (
      <div>
        <Placeholder name="jss-homepage-heroes" rendering={props.rendering} />
      </div>
    );
  } else {
    return heroes.length > 0 ? (
      <HomepageHero {...heroes[randomHeroIndex]} />
    ) : (
      <div className="homepage-hero__randomizer"></div>
    );
  }
};

HomepageHeroRandomizer.propTypes = propTypes;

export default withSitecoreContext()(HomepageHeroRandomizer);
