/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    caption: shape({
      value: string,
    }),
    image: shape({
      value: object,
    }),
  }),
};

/**
 * The Carousel Image Component displays an image with given desired
 * copy.  It is meant to be an insert item for the Carousel and should
 * not be rendered on a page as a stand-alone component.
 * @param {Object} props
 * @returns {HTMLElement} Carousel Image Component
 */
const CarouselImageComponent = (props) => {
  const { title, description, caption, image } = props.fields;
  const hasAltText = getFieldValue(props.fields, "image")?.alt;
  const altText =
    getFieldValue(props.fields, "image")?.alt ||
    getFieldValue(props.fields, "description");
  return (
    <figure className="carousel-image">
      <div className="carousel-image__image-section">
        <picture className="carousel-image__image">
          <Image className="img" field={image} alt={altText} />
        </picture>
      </div>
      <figcaption className="carousel-image__copy-section grid--100">
        <div className="carousel-image__copy">
          <Text className="carousel-image__title" field={title} tag="h3" />
          <Text
            className="carousel-image__description"
            field={description}
            tag="p"
            aria-hidden={!hasAltText}
          />
          <Text className="carousel-image__caption" field={caption} tag="p" />
        </div>
      </figcaption>
    </figure>
  );
};

CarouselImageComponent.propTypes = propTypes;

export default CarouselImageComponent;
