/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  rendering: object,
};

const Center70Container = (props) => {
  try {
    return (
      <div className="grid grid--25-50-25">
        <div className="grid--50">
          <Placeholder
            name="jss-split-60-container"
            rendering={props.rendering}
          />
        </div>
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

Center70Container.propTypes = propTypes;

export default Center70Container;
