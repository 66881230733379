/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, {useState, useEffect} from "react";
/* eslint-enable */
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Cookies from "js-cookie";

const propTypes = {
  fields: object,
};
const GlobalHeaderCTA = (props) => {
  const {
    fields: { ctaLinkText, donateLinkUS, donateLinkUK },
  } = props;

  const [link, setLink] = useState(donateLinkUS);

  useEffect(() => {
    if (Cookies.get("GPP_DONATE_LOCALE") === "UK") {
      setLink(donateLinkUK);
    }
  }, [donateLinkUK]);

  return (
    <Link className="btn btn--primary global-header-cta" field={link}>
      <Text field={ctaLinkText} />
    </Link>
  );
};

GlobalHeaderCTA.propTypes = propTypes;
export default GlobalHeaderCTA;
