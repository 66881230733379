/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useLayoutEffect, useRef, useId } from "react";
/* eslint-enable */
import { array, shape } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import FAQCard from "../FAQCard/FAQCard";
import DividerLine from "../DividerLine/DividerLine";
import { scrollToElement } from "../../utils/helpers.js";
import useIsSSR from "../../hooks/isSSR.js";

const propTypes = {
  fields: shape({
    faqs: array,
  }),
};

/**
 * The FAQs Component is a list of all FAQs
 * that are passed via the props
 * @param {Object} props
 * @returns {HTMLElement} FAQs Component
 */
const FAQsComponent = (props) => {
  const id = useId();
  const [loaded, setLoaded] = useState(false);
  const faqEl = useRef([]);
  const isSSR = useIsSSR();
  useLayoutEffect(() => {
    try {
      if (!loaded && faqEl?.current?.length && !isSSR) {
        const selectedEl = window.location.hash
          ? document.querySelector(`${window.location.hash}`)
          : null;
        if (selectedEl) {
          scrollToElement(selectedEl);
        }
        setLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [faqEl?.current?.length, isSSR, loaded]);
  try {
    const faqs = props?.fields?.faqs || [];

    /**
     * Renders an array of FAQ items based on the
     * faqs passed from props
     * @returns {Array} FAQ Item array
     */
    const renderFAQItems = () => {
      return faqs?.map((faq, index) => {
        return (
          <div className="faqs__item grid--100" key={`${id}_FAQ-ITEM_${index}`}>
            <DividerLine
              noPadding={true}
              isAriaHidden={true}
              fields={{ isDark: { value: false } }}
            />
            <FAQCard
              ref={(el) => (faqEl.current[index] = el)}
              {...faq}
              hasDivider={false}
              useH2={true}
            />
          </div>
        );
      });
    };

    return <section className="faqs">{renderFAQItems()}</section>;
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FAQsComponent.propTypes = propTypes;

export default FAQsComponent;
