/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import {
  Text,
  DateField,
  Image,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, object } from "prop-types";
import classNames from "classnames/bind";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import { useTranslation } from "react-i18next";
import { formatDate, getDateField } from "../../utils/helpers.js";

const propTypes = {
  fields: shape({
    promotedPage: object,
  }),
};

/**
 * The Article Preview Banner is a larger version of an
 * article card that is meant to sit near the top of a
 * page and call attention to a featured articles.
 * @param {Object} props
 * @returns {HTMLElement} Article Preview Banner
 */
const ArticlePreviewBanner = (props) => {
  const { t } = useTranslation(),
    [updateLabel] = useState(t("Updated"));
  try {
    const promotedPage = props?.fields?.promotedPage,
      {
        listingFeatureImage,
        originalDate,
        displayDate,
        headerTitle,
        subtitle,
        colorTheme,
        bannerType,
      } = promotedPage?.fields,
      url = promotedPage?.url || "/";

    /**
     * Gets the page banner data if any
     * @returns {Object} page banner datasource object
     */
    const getPageBannerType = () => {
      return getFieldValue(bannerType?.fields, "bannerType");
    };

    const { dateField, dateString, isUpdated } = getDateField(
        displayDate,
        originalDate,
      ),
      colorPrimaryFields = colorTheme?.fields?.primary?.fields,
      primaryColor = getFieldValue(colorPrimaryFields, "color"),
      primary5050Color = getPageBannerType() === "50-50" ? primaryColor : null,
      primaryCircleColor =
        getPageBannerType() === "circle" ? primaryColor : null,
      isDarkBackground = getFieldValue(colorPrimaryFields, "lightText"),
      cssCopySection = classNames("article-preview__copy-section", {
        "is-dark": isDarkBackground && getPageBannerType() === "50-50",
      }),
      cssClasses = classNames("article-preview__card", {
        "is-circle": getPageBannerType() === "circle",
        "is-fullbleed": getPageBannerType() === "full-bleed",
        "is-5050": getPageBannerType() === "50-50",
      });

    return (
      <section className="article-preview">
        <div className="article-preview__inner grid--100">
          <a href={url} className={cssClasses}>
            {!!listingFeatureImage?.value?.src && (
              <div
                className="article-preview__image-section"
                style={{ backgroundColor: primaryCircleColor }}
              >
                <picture className="article-preview__image">
                  <Image
                    className="img"
                    field={listingFeatureImage}
                    alt={listingFeatureImage?.value?.alt || ""}
                  />
                </picture>
              </div>
            )}
            <div
              className={cssCopySection}
              style={{ backgroundColor: primary5050Color }}
            >
              <div className="article-preview__copy">
                {!!dateField?.value && (
                  <p className="article-preview__date">
                    {isUpdated && `${updateLabel}: `}
                    <DateField
                      field={dateField}
                      render={() => formatDate(dateString)}
                    />
                  </p>
                )}
                <Text
                  className="article-preview__title"
                  field={headerTitle}
                  tag="h2"
                />
                <Text
                  className="article-preview__summary"
                  field={subtitle}
                  tag="p"
                />
              </div>
            </div>
          </a>
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticlePreviewBanner.propTypes = propTypes;

export default ArticlePreviewBanner;
