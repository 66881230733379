/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useRef, useEffect } from "react";
/* eslint-enable */
import { bool } from "prop-types";
import classnames from "classnames";

const propTypes = {
  isDark: bool,
};

/**
 * Icon buttons deviate from our standard button approach buy breaking the
 * button into two distinct aspects, a circular ‘button’ and a left aligned UI
 * label. These icon buttons should be used sparingly and only in areas where
 * traditional buttons may be overpowering or where an icon is absolutely
 * necessary.
 * @param {Object} props
 * @returns {HTMLElement} Icon Button component
 */
const IconButton = (props) => {
  const { isDark } = props;

  const classes = classnames("icon-button", {
    "icon-button--dark": isDark,
  });
  return (
    <span className={classes}>
      <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 12h19M13 5l8 7-8 7" strokeWidth="2" />
      </svg>
    </span>
  );
};

IconButton.propTypes = propTypes;
export default IconButton;
