/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, {useState, useEffect} from "react";
/* eslint-enable */
import {
  Text,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { shape, object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import Cookies from "js-cookie";

const propTypes = {
  fields: shape({
    donateLink: object,
    donateText: object,
    donateImageDesktop: object,
    donateImageMobile: object,
  }),
};

const FooterDonationBar = (props) => {
  const {
    donateText,
    donateLinkText,
    donateLinkUS,
    donateLinkUK,
    donateImageDesktop,
    donateImageMobile,
  } = props.fields;

  const link =
    Cookies.get("GPP_DONATE_LOCALE") === "UK" ? donateLinkUK : donateLinkUS;

  try {
    return (
      <section className="footer-donation-bar__bleed">
        <div className="footer-donation-bar grid--100">
          <div className="footer-donation-bar__cta">
            <div>
              <Text field={donateText} />
            </div>
            <Link
              field={link}
              className="footer-donation-bar__donate-button btn btn--primary"
            >
              <Text field={donateLinkText} />
            </Link>
          </div>
          <div className="footer-donation-bar__image-wrapper">
            <picture className="footer-donation-bar__image">
              <source
                srcSet={donateImageMobile?.value?.src}
                media="(max-width: 609px)"
              />
              <img
                src={donateImageDesktop?.value?.src}
                alt={donateImageDesktop?.value.alt}
              />
            </picture>
          </div>
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FooterDonationBar.propTypes = propTypes;

export default withSitecoreContext()(FooterDonationBar);
