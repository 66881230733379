/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Image,
  RichText,
  getFieldValue,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";

const propTypes = {
  fields: shape(
    {
      nameLink: shape({
        value: object,
      }),
    },
    {
      image: shape({
        value: object,
      }),
    },
    {
      description: shape({
        value: string,
      }),
    },
  ),
};

const GranteeSpotlightItem = (props) => {
  const { granteeName, image, description, learnMoreLink } = props.fields;
  const altText = getFieldValue(props.fields, image)?.alt || "";

  return (
    <section className="grantee-spotlight-item grid--100 margin-xlarge">
      <div className="grantee-spotlight-item__container">
        <div className="grantee-spotlight-item__image">
          <Image className="img" field={image} alt={altText} />
        </div>
        <div className="grantee-spotlight-item__text-container">
          <div className="grantee-spotlight-item__name-container">
            <Text
              className="grantee-spotlight-item__name"
              field={granteeName}
              tag="h2"
            />
          </div>

          <RichText
            className="grantee-spotlight-item__description"
            field={description}
            tag="p"
          />
          <div className="grantee-spotlight-item__learnMoreLink">
            <Link
              className="btn btn--tertiary grantee-spotlight-item__name"
              field={learnMoreLink}
            />
          </div>
        </div>
      </div>

      <hr className="grantee-spotlight-item__divider" />
    </section>
  );
};

GranteeSpotlightItem.propTypes = propTypes;

export default GranteeSpotlightItem;
