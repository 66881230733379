/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { shape, string } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    Statement: shape({
      value: string,
    }),
  }),
};

const currentYear = new Date().getFullYear();

const FooterCopyright = (props) => {
  try {
    const statementRaw = getFieldValue(props.fields, "Statement");
    const statement = statementRaw.replace("##CURRENTYEAR##", currentYear);

    return <div className="footer-copyright grid--50">{statement}</div>;
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FooterCopyright.propTypes = propTypes;

export default FooterCopyright;
