/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState } from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import DividerLine from "../DividerLine/DividerLine.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = { sitecoreContext: object };

/**
 * The Article Metadata pulls from the page level information
 * and displays the author of the page.  If there are multiple
 * authors selected, it will concatenate the names together with
 * a comma.  This component is intended for Article pages, and should
 * be placed within the main, full-bleed, or 60-container.
 * @param {Object} props
 * @returns {HTMLElement} Article Metadata
 */
const ArticleMetadata = (props) => {
  const { t } = useTranslation(),
    [byLabel] = useState(t("By"));
  try {
    const { sitecoreContext } = props;
    const isEditing = !!sitecoreContext && sitecoreContext.pageEditing;

    /**
     * Combines the name fields in the given person object into one string
     * @param {Object} person person object
     * @returns {String} concatenated string
     */
    const concatName = (person) => {
      const {
        fields: {
          firstName: { value: firstName },
          lastName: { value: lastName },
          salutation: salutation,
          middleName: middleName,
          suffix: suffix,
        },
      } = person;
      const optionalMiddleName = middleName?.value ? middleName.value : "";
      const optionalSalutation = salutation?.fields.salutation?.value
        ? salutation.fields.salutation.value
        : "";
      const optionalSuffix = suffix?.fields.suffix?.value
        ? suffix.fields.suffix.value
        : "";

      return `${optionalSalutation} ${firstName} ${optionalMiddleName} ${lastName} ${optionalSuffix}`.trim();
    };

    /**
     * Renders the list of people in a single, comma separated line
     * @param {Object[]} peopleArr an array of people objects
     * @returns {HTMLElement} html partial to render
     */
    const renderAuthors = (peopleArr) => {
      if (peopleArr.length <= 1) {
        return peopleArr.map((person) => concatName(person));
      }

      return peopleArr.map((person, index) => {
        const comma = index === peopleArr.length - 1 ? "" : ", ";
        return (
          <React.Fragment key={`meta-author-${index}`}>
            <strong>{concatName(person)}</strong>
            {comma}
          </React.Fragment>
        );
      });
    };

    return (
      <div className="grid--100 article-metadata">
        {isEditing && <p>Article Metadata Component</p>}
        <p className="grid--small article-metadata__byline">
          {byLabel} {renderAuthors(sitecoreContext.route.fields.people)}
        </p>
        <DividerLine
          noPadding={true}
          isAriaHidden={true}
          fields={{ isDark: { value: false } }}
        />
      </div>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticleMetadata.propTypes = propTypes;

export default withSitecoreContext()(ArticleMetadata);
