/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { shape, string, object } from "prop-types";
import MediaItem from "./MediaItem.js";
import DividerLine from "../DividerLine/DividerLine";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    title1: shape({
      value: string,
    }),
    description1: shape({
      value: string,
    }),
    media1: shape({
      value: object,
    }),
    image1: shape({
      value: object,
    }),
    title2: shape({
      value: string,
    }),
    description2: shape({
      value: string,
    }),
    media2: shape({
      value: object,
    }),
    image2: shape({
      value: object,
    }),
    title3: shape({
      value: string,
    }),
    description3: shape({
      value: string,
    }),
    media3: shape({
      value: object,
    }),
    image3: shape({
      value: object,
    }),
  }),
};

const MediaThreeUp = (props) => {
  try {
    const {
      title1,
      description1,
      media1,
      image1,
      title2,
      description2,
      media2,
      image2,
      title3,
      description3,
      media3,
      image3,
    } = props.fields;

    return (
      <section className="media-three-up">
        <div className="media-three-up__inner grid--100">
          <MediaItem
            title={title1}
            description={description1}
            media={media1}
            image={image1}
          />
          <DividerLine
            noPadding={true}
            isAriaHidden={true}
            fields={{ isDark: { value: true } }}
          />
          <MediaItem
            title={title2}
            description={description2}
            media={media2}
            image={image2}
          />
          <DividerLine
            noPadding={true}
            isAriaHidden={true}
            fields={{ isDark: { value: true } }}
          />
          <MediaItem
            title={title3}
            description={description3}
            media={media3}
            image={image3}
          />
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

MediaThreeUp.propTypes = propTypes;

export default MediaThreeUp;
