/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: object,
};

const FooterLogo = (props) => {
  try {
    const logoDesktop = getFieldValue(props.fields, "footerLogoDesktop");
    const logoMobile = getFieldValue(props.fields, "footerLogoMobile");
    return (
      <a
        href="https://www.gatesfoundation.org/"
        className="footer-logo grid--50"
      >
        <picture>
          {logoMobile && (
            <source srcSet={logoMobile.src} media="(max-width: 849px)" />
          )}
          <img src={logoDesktop.src} alt={logoDesktop.alt} />
        </picture>
      </a>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

FooterLogo.propTypes = propTypes;
export default FooterLogo;
