/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useEffect, useState, useId } from "react";
/* eslint-enable */
import {
  withSitecoreContext,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import classNames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import FocusLock from "react-focus-lock";
import { useTranslation } from "react-i18next";

const propTypes = {
  sitecoreContext: object,
};

const BrowserModal = (props) => {
  const id = useId();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!window?.MSInputMethodContext && !!document?.documentMode);
  }, []);

  try {
    const { browserWarning } = props.sitecoreContext?.globalConfiguration,
      isEditing = props?.sitecoreContext?.pageEditing,
      cssClasses = classNames("browser-modal grid--100", {
        "is-cms": isEditing,
        "is-open": isOpen || isEditing,
      });

    /**
     * Closes the banner and sets a cookie
     * @param {Event} event click event
     */
    const closeModal = (event) => {
      if (!isEditing) {
        setIsOpen(false);
      }
    };
    return (
      <dialog
        id={id}
        aria-modal="true"
        className={cssClasses}
        aria-label="Unsupported Browser"
        aria-describedby={`${id}_DESCRIPTION`}
      >
        <FocusLock disabled={isEditing} className="browser-modal__content">
          <div className="browser-modal__inner">
            <div className="browser-modal__header">
              <button
                onClick={closeModal}
                className="browser-modal__close btn btn--primary btn--icon"
              >
                <svg
                  className="icon"
                  width="18"
                  height="19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{t("Close")}</title>
                  <rect
                    width="2"
                    height="23.4"
                    rx="1"
                    transform="rotate(-45 2 1)"
                  />
                  <rect
                    width="2"
                    height="23.5"
                    rx="1"
                    transform="rotate(45 8 20)"
                  />
                </svg>
              </button>
            </div>
            <div className="browser-modal__copy">
              <RichText
                field={browserWarning?.warning}
                className="browser-modal__description rich-text"
                id={`${id}_DESCRIPTION`}
                tag="div"
              />
            </div>
          </div>
        </FocusLock>
      </dialog>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

BrowserModal.propTypes = propTypes;
export default withSitecoreContext()(BrowserModal);
