/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Image,
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import classNames from "classnames/bind";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    text: shape({
      value: string,
    }),
    image: shape({
      src: string,
      alt: string,
    }),
  }),
};

const StatisticBanner = (props) => {
  const { sitecoreContext: { route: route } = {}, fields } = props;
  const isEditing = route && route.pageEditing,
    secondaryColorFields = route.fields.colorTheme?.fields.secondary?.fields,
    isDarkBackground = getFieldValue(secondaryColorFields, "lightText"),
    hasAltText = fields.image && getFieldValue(fields, "image")?.alt,
    altText = hasAltText
      ? getFieldValue(fields, "image").alt
      : getFieldValue(fields, "text"),
    cssDarkBackground = classNames({ "is-dark": isDarkBackground }),
    cssBackground = getFieldValue(secondaryColorFields, "color");

  return (
    <section className={`stat-banner ${cssDarkBackground}`}>
      {isEditing && <p>Statistic Banner Component</p>}
      <div
        className="stat-banner__inner"
        style={{ backgroundColor: cssBackground }}
      >
        <div className="grid grid--25-50-25 stat-banner__copy-section">
          <figure className="grid--50 stat-banner__copy">
            <Text
              className="stat-banner__caption"
              field={fields.text}
              tag="figcaption"
              aria-hidden={!hasAltText}
            />
            <picture className="stat-banner__image">
              <Image className="img" media={fields.image} alt={altText} />
            </picture>
          </figure>
        </div>
      </div>
    </section>
  );
};

StatisticBanner.propTypes = propTypes;

export default withSitecoreContext()(StatisticBanner);
