/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import { string, func, object } from "prop-types";
import Select from "react-select";
import states from "../../assets/data/states.json";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import classNames from "classnames";

const propTypes = {
  activeState: string,
  setFormValues: func,
  formErrors: object,
  setFormErrors: func,
  formValues: object,
};

const DonateFormBilling = (props) => {
  const { activeState, formValues, setFormValues, formErrors, setFormErrors } =
    props;
  const [stateValue, setStateValue] = useState("");

  const updateBillingDetails = (field, value) => {
    setFormErrors({ ...formErrors, [field]: undefined });
    setFormValues({ ...formValues, [field]: value });
  };
  useEffect(() => {
    setStateValue("");
    updateBillingDetails("payer_state", null);
    // eslint-disable-next-line
  }, [activeState]);

  try {
    // Creates a new object with country/state data by country key for easy access
    // Example: `statesByISO.USA` for US states
    const statesByISO = states.reduce((acc, state) => {
      return {
        ...acc,
        [state.key]: state,
      };
    }, {});

    const handleChange = (value) => {
      setStateValue(value);
      updateBillingDetails("payer_state", value.key);
    };

    const classInput = (field) =>
      classNames("gpp-input", {
        "js-form-error": formErrors[field],
      });

    return (
      <fieldset>
        <legend className="donate-form__section-title" id="anchor--billing">
          Your billing address
        </legend>
        <div className="grid grid--50">
          <label
            className={
              "gpp-input-label grid--100" +
              (formErrors.payer_address ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-billing-address"
          >
            Street address
            <input
              type="text"
              name="form--donate-billing-address"
              id="form--donate-billing-address"
              className={classInput("payer_address")}
              placeholder="21 Station Road"
              autoComplete="street-address"
              onChange={(e) =>
                updateBillingDetails("payer_address", e.target.value)
              }
            />
            {formErrors.payer_address && (
              <div className="gpp-form-error">
                Please enter your billing address
              </div>
            )}
          </label>

          <label
            className={
              "gpp-input-label grid--100" +
              (formErrors.payer_city ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-billing-city"
          >
            City / Town
            <input
              type="text"
              name="form--donate-billing-city"
              id="form--donate-billing-city"
              className={classInput("payer_city")}
              placeholder="Springfield"
              autoComplete="address-level2"
              onChange={(e) =>
                updateBillingDetails("payer_city", e.target.value)
              }
            />
            {formErrors.payer_city && (
              <div className="gpp-form-error">
                Please enter your billing city
              </div>
            )}
          </label>

          <label
            className={
              "gpp-input-label grid--100" +
              (formErrors.payer_zip ? " gpp-input-label--error" : "")
            }
            htmlFor="form--donate-billing-zipcode"
          >
            Postal / ZIP Code
            <input
              type="text"
              name="form--donate-billing-zipcode"
              id="form--donate-billing-zipcode"
              className={classInput("payer_zip")}
              placeholder="90210"
              autoComplete="postal-code"
              onChange={(e) =>
                updateBillingDetails("payer_zip", e.target.value)
              }
            />
            {formErrors.payer_zip && (
              <div className="gpp-form-error">
                Please enter a valid billing postal / ZIP code
              </div>
            )}
          </label>
          {activeState && statesByISO[activeState].states?.length > 0 && (
            <label
              className={
                "gpp-input-label grid--100" +
                (formErrors.payer_state ? " gpp-input-label--error" : "")
              }
              htmlFor="form--donate-billing-state"
            >
              State
              <Select
                unstyled
                value={stateValue}
                placeholder="State"
                onChange={handleChange}
                options={statesByISO[activeState].states}
                autoComplete="address-level1"
                classNamePrefix="gpp-react-select"
                className={`grid--100 ${
                  formErrors.payer_state ? "js-form-error" : ""
                }`}
              />
              {formErrors.payer_state && (
                <div className="gpp-form-error">
                  Please enter your billing state
                </div>
              )}
            </label>
          )}
        </div>
      </fieldset>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

DonateFormBilling.propTypes = propTypes;
export default DonateFormBilling;
