/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect } from "react";
/* eslint-enable */
import { object, shape, string } from "prop-types";

import DonateFormFrequency from "./DonateFormFrequency.js";
import DonateFormAmount from "./DonateFormAmount.js";
import DonateFormTowards from "./DonateFormTowards.js";
import DonateFormPersonal from "./DonateFormPersonal.js";
import DonateFormBilling from "./DonateFormBilling.js";
import DonateFormRecap from "./DonateFormRecap.js";

import countriesRoot from "../../assets/data/countries.json";

const { countries } = countriesRoot;

const propTypes = {
  fields: shape({
    heading: string,
  }),
  rendering: object,
};

const DonateFormUS = (props) => {
  const [activeState, setActiveState] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const [formValues, setFormValues] = useState({
    frequency: null,
    recur_length: null,
    amount_selected: null,
    gpp_fund: null,
    include_processing_fee: false,
    payer_state_required: false,
    payer_state: null,
    payer_fname: null,
    payer_lname: null,
    payer_email: null,
    payer_address: null,
    payer_zip: null,
    payer_country: null,
    payer_city: null,
    gpp_updates_email: null,
    gpp_updates_mail: null,
  });

  // Adds a `value` key so React Select will process it correctly
  countries.map((country) => ({ ...country, value: country.iso }));

  const updateStates = (evt) => {
    setActiveState(evt.key);
  };

  useEffect(() => {
    // Set first country as default
    if (countries.length) {
      updateStates(countries[0]);
    }
  }, []);

  const formProps = { formValues, setFormValues, formErrors, setFormErrors };

  return (
    <form
      noValidate
      className="donate-form"
      action="#"
      method="GET"
      autoComplete="off"
    >
      <div className="donate-form__inner grid">
        <div
          className="donate-form__section no-outline"
          id="anchor--frequency"
          tabIndex="-1"
        >
          <div className="donate-form__section-counter">01 / 05</div>
          <DonateFormFrequency {...formProps} />
        </div>

        <div
          className="donate-form__section no-outline"
          id="anchor--amount"
          tabIndex="-1"
        >
          <div className="donate-form__section-counter">02 / 05</div>
          <DonateFormAmount {...formProps} />
        </div>

        <div
          className="donate-form__section no-outline"
          id="anchor--towards"
          tabIndex="-1"
        >
          <div className="donate-form__section-counter">03 / 05</div>
          <DonateFormTowards {...formProps} />
        </div>

        <div className="donate-form__section">
          <div className="donate-form__section-counter">04 / 05</div>
          <DonateFormPersonal
            countryChanged={updateStates}
            {...formProps}
            countries={countries}
          />
        </div>

        <div className="donate-form__section">
          <div className="donate-form__section-counter">05 / 05</div>
          <DonateFormBilling activeState={activeState} {...formProps} />
        </div>
      </div>

      <DonateFormRecap
        {...formProps}
        rendering={props?.rendering}
        countries={countries}
      />
    </form>
  );
};

DonateFormUS.propTypes = propTypes;

export default DonateFormUS;
