/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Link } from "@sitecore-jss/sitecore-jss-react";

import { shape, array } from "prop-types";

const propTypes = {
  sitecoreContext: shape({
    footerNav: array,
  }),
};

const FooterNavigationLinks = (props) => {
  const links = props.sitecoreContext?.footerNav;

  return (
    <div className="footer-navigation-links grid--50">
      {links &&
        links.map((linkProps, index) => {
          const { link } = linkProps;
          return (
            link && (
              <div
                key={`footer-link-${index}`}
                className="footer-navigation-links__link"
              >
                <Link field={link} />
              </div>
            )
          );
        })}
    </div>
  );
};

FooterNavigationLinks.propTypes = propTypes;
export default withSitecoreContext()(FooterNavigationLinks);
