/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Link,
  Image,
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    eyebrow: shape({
      value: string,
    }),
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    link: object,
    image: object,
  }),
};

const Promo5050 = (props) => {
  const { eyebrow, title, description, link, image } = props.fields;
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;
  const altText = getFieldValue(props.fields, "image")?.alt || "";

  return (
    <section className="promo-5050">
      <div className="promo-5050__image-section">
        <picture className="promo-5050__image">
          <Image className="img" field={image} alt={altText} />
        </picture>
      </div>
      <div className="promo-5050__copy-section">
        <div className="promo-5050__copy grid--100">
          <Text className="promo-5050__eyebrow" field={eyebrow} tag="p" />
          <Text className="promo-5050__title" field={title} tag="h2" />
          <Text
            className="promo-5050__description"
            field={description}
            tag="p"
          />
          {(!!link?.value?.href || isEditing) && (
            <div className="promo-5050__cta">
              <Link className="btn btn--tertiary" field={link} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Promo5050.propTypes = propTypes;

export default withSitecoreContext()(Promo5050);
