/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React, { useState, useEffect, useRef, useId } from "react";
/* eslint-enable */
import {
  Placeholder,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object } from "prop-types";
import FocusLock from "react-focus-lock";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  rendering: object,
};

const DonateFormUK = (props) => {
  const id = useId();
  const { t } = useTranslation();
  const [addedNoScroll, setAddedNoScroll] = useState(false);
  const [displayThanks, setDisplayThanks] = useState(false);

  useEffect(() => {
    try {
      /**
       * Handles iframe events
       * @param {Event} e
       * @returns {VoidFunction}
       */
      const handleFrameTasks = (e) => {
        if (!process.env.REACT_APP_GI_DOMAIN.includes(e.origin)) {
          return;
        }

        const data = typeof e.data === "object" ? e.data : JSON.parse(e.data);

        if (data.GITransactionSuccess) {
          setDisplayThanks(true);
        }
      };

      if (displayThanks) {
        document.querySelector("body").classList.add("no-scroll");
        setAddedNoScroll(true);
      } else if (addedNoScroll) {
        document.querySelector("body").classList.remove("no-scroll");
        setAddedNoScroll(false);
      }

      window.addEventListener("message", handleFrameTasks, false);
      return () => {
        window.removeEventListener("message", handleFrameTasks);
      };
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  }, [displayThanks, addedNoScroll]);

  try {
    const { sitecoreContext, rendering } = props,
      isEditing = sitecoreContext?.pageEditing,
      { headerLogo } = sitecoreContext?.globalConfiguration,
      cssClasses = classNames("donate-modal", {
        "is-open": displayThanks,
        "grid--100": isEditing,
        "is-thanks": displayThanks,
      });

    //I don't know why this is needed but the process.env variables won't load without it
    const test = `${window?.location.origin || ""}/`; // eslint-disable-line

    const iframeSrc = process.env.REACT_APP_GI_DOMAIN;

    const renderDialog = () => {
      return (
        <dialog
          id={id}
          aria-modal="true"
          className={cssClasses}
          aria-labelledby={`${id}_TITLE`}
        >
          <FocusLock disabled={isEditing} className="donate-modal__inner">
            <div className="donate-modal__header">
              <div className="donate-modal__header-inner grid--100">
                <HeaderLogo fields={headerLogo} isDark={false} />
                <a
                  href="/"
                  className="donate-modal__close btn btn--primary btn--icon"
                >
                  <svg
                    className="icon"
                    width="18"
                    height="19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>{t("Close")}</title>
                    <rect
                      width="2"
                      height="23.4"
                      rx="1"
                      transform="rotate(-45 2 1)"
                    />
                    <rect
                      width="2"
                      height="23.5"
                      rx="1"
                      transform="rotate(45 8 20)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="donate-modal__copy">
              <Placeholder name="jss-donate-thank-you" rendering={rendering} />
            </div>
          </FocusLock>
        </dialog>
      );
    };
    return (
      <section className="donate-form-uk">
        <iframe
          style={{ height: "100vh", width: "100%" }}
          title="global-impact"
          id="global-impact-iframe"
          src={iframeSrc}
        />
        {(displayThanks || isEditing) && renderDialog()}
      </section>
    );
  } catch (error) {
    return <Error error={error} {...props} />;
  }
};

DonateFormUK.propTypes = propTypes;

export default withSitecoreContext()(DonateFormUK);
