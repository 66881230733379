/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    link: object,
  }),
};

const IntroContent = (props) => {
  const { title, description, link } = props.fields,
    isEditing = props?.sitecoreContext?.pageEditing;
  return (
    <section className="grid grid--100 intro-content">
      <div className="intro-content__section intro-content__section--left">
        <Text className="intro-content__title" tag="h2" field={title} />
      </div>
      <div className="intro-content__section intro-content__section--right">
        <Text
          className="intro-content__description"
          tag="p"
          field={description}
        />
        {(!!link?.value?.href || isEditing) && (
          <div className="intro-content__link">
            <Link className="btn btn--tertiary" field={link} />
          </div>
        )}
      </div>
    </section>
  );
};

IntroContent.propTypes = propTypes;

export default withSitecoreContext()(IntroContent);
