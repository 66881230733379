/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  getFieldValue,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";
import DividerLine from "../DividerLine/DividerLine.js";
import classNames from "classnames";

const propTypes = {
  sitecoreContext: object,
  params: shape({
    isDark: string,
  }),
  fields: shape({
    title: shape({
      value: string,
    }),
    stat1: shape({
      value: string,
    }),
    description1: shape({
      value: string,
    }),
    stat2: shape({
      value: string,
    }),
    description2: shape({
      value: string,
    }),
    stat3: shape({
      value: string,
    }),
    description3: shape({
      value: string,
    }),
    stat4: shape({
      value: string,
    }),
    description4: shape({
      value: string,
    }),
    stat5: shape({
      value: string,
    }),
    description5: shape({
      value: string,
    }),
    stat6: shape({
      value: string,
    }),
    description6: shape({
      value: string,
    }),
    stat7: shape({
      value: string,
    }),
    description7: shape({
      value: string,
    }),
    stat8: shape({
      value: string,
    }),
    description8: shape({
      value: string,
    }),
    stat9: shape({
      value: string,
    }),
    description9: shape({
      value: string,
    }),
    stat10: shape({
      value: string,
    }),
    description10: shape({
      value: string,
    }),
  }),
};

/**
 * The Text Stat Cards component is a two column list
 * of stats.  Up to 10 stats can be accomodated, and if
 * an uneven number of stats are provided, an extra placeholder
 * will be generated for a balanced look on desktop.
 * @param {Object} props
 * @returns {HTMLElement} Text Stat Cards
 */
const TextStatCards = (props) => {
  try {
    const { title } = props.fields;
    const isEditing =
      props?.sitecoreContext && props?.sitecoreContext?.pageEditing;
    const isDarkBoolean =
      props?.params?.isDark === "true" || props?.params?.isDark === "1";
    const cssClasses = classNames("stat-cards", {
      "is-dark": isDarkBoolean,
    });

    /**
     * Renders a stat item based on the provided fields.
     * If no fields are provided, will generate an empty
     * placeholder item.
     * @param {Object} stat stat field
     * @param {Object} description description field
     * @returns {HTMLElement} Stat Item
     */
    const renderStatItem = (stat, description, key = "") => {
      const isPlaceholder = !stat && !description;
      const isPartial = !stat || !description;
      const cssStat = classNames("stat", {
        "is-empty": isPlaceholder,
      });
      const statKey = key
        ? key
        : `statItem_${Math.floor(Math.random() * 100000)}`;
      if (isPartial && !isEditing && !isPlaceholder) {
        return;
      }
      return (
        <div className={cssStat} key={statKey}>
          <DividerLine
            noPadding={true}
            isAriaHidden={true}
            fields={{ isDark: { value: isDarkBoolean } }}
          />
          {isPartial && isEditing && (
            <Error
              {...props}
              errorMsg="This item does not contain both a stat and description field.  This item will be hidden in production."
            />
          )}
          {!isPlaceholder && (
            <div className="stat__inner">
              <Text field={stat} tag="h3" className="stat__title" />
              <Text field={description} tag="p" className="stat__description" />
            </div>
          )}
        </div>
      );
    };

    /**
     * Gets a list of item numbers that have been filled out in sitecore.
     * e.g. if stat1 is filled out, the array will include "1"
     * @returns {Array.<Number} array of numbers
     */
    const getItemNumbers = () => {
      const propsList = Object.keys(props?.fields);
      const numList = propsList?.map((field) => {
        const number = field?.match(/\d+/)?.[0];
        return number ? number : null;
      });
      return [...new Set(numList)];
    };

    /**
     * Gets a list of Stat Items to render based on the provided fields.
     * If an uneven number of items have been filled out, one more
     * stat item will be generated for an even look on non-mobile
     * @returns {Array.<HTMLElement>} Stat Items Array
     */
    const renderItems = () => {
      const numberList = getItemNumbers()?.filter((x) => !!x);
      const renderItemsArray = numberList?.map((num) => {
        if (
          num &&
          (getFieldValue(props?.fields, `stat${num}`) ||
            getFieldValue(props?.fields, `description${num}`))
        ) {
          return renderStatItem(
            props?.fields?.[`stat${num}`],
            props?.fields?.[`description${num}`],
          );
        }
      });
      const isEven = !(renderItemsArray?.filter((x) => !!x)?.length % 2);
      if (!isEven) {
        renderItemsArray.push(renderStatItem());
      }
      return renderItemsArray;
    };

    return (
      <section className={cssClasses}>
        <div className="stat-cards__inner grid--100">
          <Text className="stat-cards__title" tag="h2" field={title} />
          <div className="stat-cards__stats">{renderItems()}</div>
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

TextStatCards.propTypes = propTypes;

export default withSitecoreContext()(TextStatCards);
