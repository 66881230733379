/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import {
  Text,
  RichText,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  sitecoreContext: object,
  fields: shape({
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    link: shape({
      value: object,
    }),
  }),
};

/**
 * The Error Message component should be used to display a
 * user friendly error message, such as 404, to the user.  It
 * is meant to be the first object on a page, as it uses an
 * H1 tag
 * @param {Object} props
 * @returns {HTMLElement} Error Message
 */
const ErrorMessage = (props) => {
  try {
    const { title, description, link } = props.fields,
      isEditing = props?.sitecoreContext?.pageEditing;
    return (
      <section className="error-message">
        <div className="error-message__inner grid--100">
          <Text className="error-message__title" field={title} tag="h1" />
          <RichText className="error-message__copy" field={description} />
          {(!!link?.value?.href || isEditing) && (
            <div className="error-message__cta">
              <Link className="btn btn--primary" field={link} />
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ErrorMessage.propTypes = propTypes;

export default withSitecoreContext()(ErrorMessage);
