/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { array, shape, string } from "prop-types";
import classNames from "classnames";
import DataCard from "../DataCard/DataCard";
import DividerLine from "../DividerLine/DividerLine";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    dataCards: array,
  }),
  params: shape({
    isDark: string,
  }),
};

const DataCardThreeUp = (props) => {
  const {
    fields: { title, dataCards },
    params,
  } = props;
  const isDarkBoolean = params?.isDark === "true" || params?.isDark === "1";
  const cssMain = classNames({ "is-dark": isDarkBoolean });

  return (
    <section className={`data-three-up ${cssMain}`}>
      <div className="data-three-up__inner grid--100">
        <Text className="data-three-up__title" field={title} tag="h2" />
        <div className="data-three-up__data-section">
          {dataCards.map((card, index) => {
            return (
              <div
                className="data-three-up__data-item"
                key={`dataThreeUpItem-${index}`}
              >
                <DividerLine
                  noPadding={true}
                  isAriaHidden={true}
                  fields={{ isDark: { value: isDarkBoolean } }}
                />
                <DataCard
                  key={`data-card-${index}`}
                  {...card}
                  isDark={isDarkBoolean}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

DataCardThreeUp.propTypes = propTypes;

export default DataCardThreeUp;
