/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { object, string } from "prop-types";

const propTypes = {
  sitecoreContext: object,
  error: object,
  errorMsg: string,
};

const Error = (props) => {
  const isEditing = props.sitecoreContext?.route?.pageEditing;
  console.error(props.errorMsg || props.error);

  if (isEditing) {
    return (
      <section className="error">
        <p className="error__title">
          {props.errorMsg || props.error?.toString()}
        </p>
        <p className="error__description">{props.error?.stack}</p>
      </section>
    );
  }
  return null;
};

Error.propTypes = propTypes;
export default withSitecoreContext()(Error);
