/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { array, shape, string, object } from "prop-types";
import ArticleCard from "../SharedComponents/ArticleCardShared/ArticleCardShared.js";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    title: shape({
      value: string,
    }),
    link: shape({
      value: object,
    }),
    pages: array,
  }),
};

/**
 * The Article Cards component is a grouping of article cards
 * @param {Object} props
 * @returns {HTMLElement} Article Cards
 */
const ArticleCards = (props) => {
  try {
    const { title, link, pages } = props.fields;

    /**
     * Renders an array of article cards
     * @returns {HTMLElement} Article Cards
     */
    const renderCards = () => {
      return pages.map((page, index) => {
        return <ArticleCard {...page} key={index} />;
      });
    };

    return (
      <section className="article-cards">
        <div className="article-cards__inner grid--100">
          <div className="article-cards__header">
            <Text className="article-cards__title" field={title} tag="h2" />
            {link && (
              <div className="article-cards__cta">
                <Link className="btn btn--tertiary" field={link} />
              </div>
            )}
          </div>
          <div className="article-cards__cards">{renderCards()}</div>
          {link && (
            <div className="article-cards__cta is-mobile">
              <Link className="btn btn--tertiary" field={link} />
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

ArticleCards.propTypes = propTypes;

export default ArticleCards;
