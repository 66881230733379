/* eslint-disable */
// Don't delete this import! React is required for Sitecore,
// even if this specific file doesn't appear use it.
import React from "react";
/* eslint-enable */
import { Text, Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { object, shape, string } from "prop-types";
import classNames from "classnames";
import Error from "../SharedComponents/ErrorHandling/Error.js";

const propTypes = {
  fields: shape({
    image: shape({
      value: object,
    }),
    title: shape({
      value: string,
    }),
    description: shape({
      value: string,
    }),
    link: shape({
      value: object,
    }),
  }),
};

/**
 * The Card Carousel Item is an image with a description, and can
 * optionally have a title and be made into a link.  This component
 * is not meant to be stand-alone, and instead should be inserted
 * into a Carousel component.  It will inherit dark/light themes
 * @param {Object} props
 * @returns {HTMLElement} Card Carousel Item
 */
const CardCarouselItem = (props) => {
  try {
    const { image, title, description, link } = props.fields;

    /**
     * Scrolls the whole item in view when it has focus
     * @param {Event} event triggering event
     */
    const handleItemFocus = (event) => {
      if (event && event.target) {
        event.target.scrollIntoView({ block: "nearest", inline: "center" });
      }
    };

    /** Renders the Image section */
    const renderImage = () => {
      return (
        <div className="card-carousel-item__image-section">
          <picture className="card-carousel-item__image">
            <Image
              className="img"
              field={image}
              alt={getFieldValue(props.fields, "image")?.alt || ""}
            />
          </picture>
        </div>
      );
    };

    /** Renders the text section */
    const renderCopy = () => {
      return (
        <div className="card-carousel-item__copy-section">
          <div className="card-carousel-item__copy">
            <Text className="card-carousel-item__title" field={title} tag="p" />
            <Text
              className="card-carousel-item__description"
              field={description}
              tag="p"
            />
          </div>
        </div>
      );
    };

    /** Conditionally renders the item as a link or non-interactive element */
    const renderItem = () => {
      const linkValue = getFieldValue(props.fields, "link");
      const isLink = link && linkValue?.href;
      const isWithTitle = getFieldValue(props.fields, "title");
      const cssClass = classNames("card-carousel-item", {
        "is-link": isLink,
        "has-title": isWithTitle,
      });
      if (isLink && isWithTitle) {
        return (
          <a
            href={linkValue?.href}
            target={linkValue?.target || null}
            className={cssClass}
            onFocus={handleItemFocus}
          >
            {renderImage()}
            {renderCopy()}
          </a>
        );
      } else {
        return (
          <div className={cssClass}>
            {renderImage()}
            {renderCopy()}
          </div>
        );
      }
    };
    return renderItem();
  } catch (error) {
    return <Error {...props} error={error} />;
  }
};

CardCarouselItem.propTypes = propTypes;

export default CardCarouselItem;
