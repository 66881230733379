import React from "react";
import {
  Placeholder,
  VisitorIdentification,
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";

import "./assets/app.css";
import "./assets/css/index-generated.css";
import BrowserModal from "./components/BrowserModal/BrowserModal";
import SkipLink from "./components/SharedComponents/SkipLink/SkipLink";
import useIsSSR from "./hooks/isSSR.js";

import { object } from "prop-types";
import classNames from "classnames";

const propTypes = {
  sitecoreContext: object,
  route: object,
  t: object,
  i18n: object,
};

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
const Layout = ({ route, sitecoreContext }) => {
  const isSSR = useIsSSR();

  const url = isSSR ? "" : window?.location?.href;
  const title =
    getFieldValue(route?.fields, "title") ||
    getFieldValue(route?.fields, "pageTitle");
  const summary = getFieldValue(route?.fields, "summary");
  const ogImage = route?.fields.ogImage;
  const favicon =
    sitecoreContext?.globalConfiguration?.Data?.Favicon?.value?.src;
  const cssCms = classNames({ "is-cms": sitecoreContext?.pageEditing });

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      {ogImage && ogImage.value.src && (
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@gatesfoundation" />
          <meta property="og:image" content={ogImage.value.src} />
          <meta property="og:image:url" content={ogImage.value.src} />
          <meta property="og:image:secure_url" content={ogImage.value.src} />
        </Helmet>
      )}
      <Helmet>
        {url && <link rel="canonical" href={url} />}
        {title && <meta name="title" content={title} />}
        {summary && <meta name="description" content={summary} />}
        {url && <meta property="og:url" content={url} />}
        {title && <meta property="og:title" content={title} />}
        {summary && <meta property="og:description" content={summary} />}
        {ogImage && ogImage.type && (
          <meta property="og:image:type" content={ogImage.type} />
        )}
        {ogImage && (ogImage.width || ogImage.origWidth) && (
          <meta
            property="og:image:width"
            content={ogImage.width || ogImage.origWidth}
          />
        )}
        {ogImage && (ogImage.height || ogImage.origHeight) && (
          <meta
            property="og:image:height"
            content={ogImage.height || ogImage.origHeight}
          />
        )}

        <title>{title || "Page"}</title>

        {"<!-- Google Tag Manager -->"}
        <script>
          {`window.dataLayer = window.dataLayer || [];

          function gtag() {
            window.dataLayer.push(arguments);
          }

          gtag("consent", "default", {
            ad_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            analytics_storage: "denied",
            security_storage: "granted",
          });`}
        </script>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N82QLHN');`}
        </script>
        {"<!-- End Google Tag Manager -->"}

        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>

      <noscript>
        <iframe
          title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-N82QLHN"
          height="0"
          width="0"
          style={({ display: "none" }, { visibility: "hidden" })}
        ></iframe>
      </noscript>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      <SkipLink
        id="SKIP_TO_MAIN"
        targetId="gpp-main-content"
        text="Skip to main content"
        customClass="gpp-skip-link"
        btnType="btn--primary"
      />
      <Placeholder name="jss-header" rendering={route} />
      {/* root placeholder for the app, which we add components to using route data */}
      <main
        className={`container no-outline ${cssCms}`}
        id="gpp-main-content"
        tabIndex="-1"
      >
        <Placeholder name="jss-main" rendering={route} />
      </main>

      <Placeholder name="jss-footer" rendering={route} />
      <BrowserModal />
    </React.Fragment>
  );
};

Layout.propTypes = propTypes;
export default withSitecoreContext()(Layout);
